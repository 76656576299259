import { V2 } from '@nomonosound/gravity'
import { getFileAsset } from '@sanity/asset-utils'
import type { FileAsset } from '@sanity/types'
import { startCase } from 'lodash'
import { useRouter } from 'next/router'
import type { FunctionComponent, MutableRefObject } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import WaveSurfer from 'wavesurfer.js'
import { Heading, Paragraph } from '~/components/FontStyles'
import { RadioButtonGroup } from '~/components/Form/RadioButton'
import { Soundwaves } from '~/components/gfx'
import { Container } from '~/components/layout/Containers'
import { SegmentEvent, type AudioSection as AudioSectionProps, type InputOption, type SanityReference } from '~/types'
import { SanityConfig, cn, sleep, track, useWindowSize } from '~/utils'
import { Section } from '../Section'
import { peak1, peak2, peak3 } from './peaks'

export const AudioPlayer: FunctionComponent<AudioSectionProps> = ({
	soundCapsule,
	enhanced,
	spatialized,
	heading,
	description,
	presetsDescription,
	theme,
	themeSettings
}) => {
	const updateTime = useCallback((waveSurfer: WaveSurfer) => {
		waveSurfer.on('timeupdate', (value) => {
			currentTime.current = value
		})
	}, [])

	// preset block
	const soundCapsuleFileAsset = useMemo(
		() => getFileAsset(soundCapsule?.asset as SanityReference<FileAsset>, SanityConfig),
		[soundCapsule]
	)
	const soundCapsuleContainerRef = useRef<HTMLDivElement>(null)
	const soundCapsuleWaveSurferRef = useRef<WaveSurfer | null>(null)
	const [soundCapsulePlayerIsReady, setSoundCapsulePlayerIsReady] = useState(false)

	// preset block
	const enhancedFileAsset = useMemo(
		() => getFileAsset(enhanced?.asset as SanityReference<FileAsset>, SanityConfig),
		[enhanced]
	)
	const enhancedContainerRef = useRef<HTMLDivElement>(null)
	const enhancedWaveSurferRef = useRef<WaveSurfer | null>(null)
	const [enhancedPlayerIsReady, setEnhancedPlayerIsReady] = useState(false)

	// preset block
	const spatializedFileAsset = useMemo(
		() => getFileAsset(spatialized?.asset as SanityReference<FileAsset>, SanityConfig),
		[spatialized]
	)
	const spatializedContainerRef = useRef<HTMLDivElement>(null)
	const spatializedWaveSurferRef = useRef<WaveSurfer | null>(null)
	const [spatializedPlayerIsReady, setSpatializedPlayerIsReady] = useState(false)

	// preset block
	useEffect(() => {
		if (!soundCapsuleContainerRef.current) {
			return
		}

		const waveSurfer = WaveSurfer.create({
			normalize: false,
			container: soundCapsuleContainerRef.current,
			barWidth: 2,
			dragToSeek: true,
			progressColor: '#FFE46C',
			// cursorColor: 'white',
			cursorWidth: 0
		})
		waveSurfer.load(soundCapsuleFileAsset.url, peak1)
		waveSurfer.on('ready', () => {
			soundCapsuleWaveSurferRef.current = waveSurfer
			setSoundCapsulePlayerIsReady(true)
		})

		updateTime(waveSurfer)

		return () => {
			waveSurfer.destroy()
		}
	}, [soundCapsuleFileAsset.url, updateTime])

	// preset block
	useEffect(() => {
		if (!enhancedContainerRef.current) {
			return
		}

		const waveSurfer = WaveSurfer.create({
			normalize: false,
			container: enhancedContainerRef.current,
			barWidth: 2,
			dragToSeek: true,
			progressColor: '#FFE46C',
			// cursorColor: 'white',
			cursorWidth: 0
		})
		waveSurfer.load(enhancedFileAsset.url, peak2)
		waveSurfer.on('ready', () => {
			enhancedWaveSurferRef.current = waveSurfer
			setEnhancedPlayerIsReady(true)
		})

		updateTime(waveSurfer)

		return () => {
			waveSurfer.destroy()
		}
	}, [enhancedFileAsset.url, updateTime])

	// preset block
	useEffect(() => {
		if (!spatializedContainerRef.current) {
			return
		}

		const waveSurfer = WaveSurfer.create({
			normalize: false,
			container: spatializedContainerRef.current,
			barWidth: 2,
			dragToSeek: true,
			progressColor: '#FFE46C',
			// cursorColor: 'white',
			cursorWidth: 0
		})
		waveSurfer.load(spatializedFileAsset.url, peak3)
		waveSurfer.on('ready', () => {
			spatializedWaveSurferRef.current = waveSurfer
			setSpatializedPlayerIsReady(true)
		})

		updateTime(waveSurfer)

		return () => {
			waveSurfer.destroy()
		}
	}, [spatializedFileAsset.url, updateTime])

	// -- end of anti-dry code --

	// common/shared code
	type Preset = 'soundCapsule' | 'enhanced' | 'spatialized'
	const OPTIONS: Array<Readonly<InputOption<Preset>>> = useMemo(
		() => [
			{ label: 'Sound Capsule', value: 'soundCapsule', icon: 'charging-case-filled' },
			{ label: 'Enhanced', value: 'enhanced', icon: 'enhance-filled' },
			{ label: 'Spatialized', value: 'spatialized', icon: 'spatial-filled' }
		],
		[]
	)

	const presetWaveSurferRefs: Record<Preset, MutableRefObject<WaveSurfer | null>> = useMemo(
		() => ({
			soundCapsule: soundCapsuleWaveSurferRef,
			enhanced: enhancedWaveSurferRef,
			spatialized: spatializedWaveSurferRef
		}),
		[]
	)

	const { isMobile } = useWindowSize()
	const [isPlaying, setIsPlaying] = useState(false)
	const [activePreset, setActivePreset] = useState<Preset>('soundCapsule')
	const currentTime = useRef<number | undefined>(0)
	const hasPlayed = useRef(false)
	const { asPath } = useRouter()

	useEffect(() => {
		soundCapsuleWaveSurferRef.current?.pause()
		enhancedWaveSurferRef.current?.pause()
		spatializedWaveSurferRef.current?.pause()

		soundCapsuleWaveSurferRef.current?.setTime(currentTime.current ?? 0)
		enhancedWaveSurferRef.current?.setTime(currentTime.current ?? 0)
		spatializedWaveSurferRef.current?.setTime(currentTime.current ?? 0)

		sleep(100).then(() => {
			if (isPlaying) {
				presetWaveSurferRefs[activePreset].current?.play()
			}
		})
	}, [activePreset, isPlaying, presetWaveSurferRefs])

	useEffect(() => {
		if (hasPlayed.current === false && isPlaying === true) {
			track(asPath.startsWith('/cloud') ? SegmentEvent.AudioPlayedCloud : SegmentEvent.AudioPlayedHomepage)
		}

		hasPlayed.current = hasPlayed.current || isPlaying
	}, [asPath, isPlaying])

	const sendTrackingEvent = useCallback(
		(preset: Preset) => {
			switch (preset) {
				case 'soundCapsule':
					track(
						asPath.startsWith('/cloud')
							? SegmentEvent.AudioTabSoundCapsuleSelectedCloud
							: SegmentEvent.AudioTabSoundCapsuleSelectedHomepage
					)
					break
				case 'enhanced':
					track(
						asPath.startsWith('/cloud')
							? SegmentEvent.AudioTabEnhancedSelectedCloud
							: SegmentEvent.AudioTabEnhancedSelectedHomepage
					)
					break
				case 'spatialized':
					track(
						asPath.startsWith('/cloud')
							? SegmentEvent.AudioTabSpatializedSelectedCloud
							: SegmentEvent.AudioTabSpatializedSelectedHomepage
					)
					break
			}
		},
		[asPath]
	)

	const isPlayerReady = soundCapsulePlayerIsReady && enhancedPlayerIsReady && spatializedPlayerIsReady

	return (
		<Section theme={theme} themeSettings={themeSettings} textAlign="center">
			<div className="py-2 md:py-6" />
			{heading && (
				// TODO: Align color with design system
				<Container pageWidth="small" className="text-[#F7F5F2]">
					<Heading type="displaySm">{heading}</Heading>
				</Container>
			)}
			{description && (
				// TODO: Align color with design system
				<Container pageWidth="small" className="text-[#F7F5F2CC]">
					<Paragraph type="bodyMd" className="mt-4">
						{description}
					</Paragraph>
				</Container>
			)}
			<div className="py-8" />

			<div className="space-y-12">
				<div className="md:flex md:items-center md:space-x-4">
					{/* Play button */}
					<div className="mb-2 md:mb-0">
						<button
							style={{ visibility: isPlayerReady ? 'visible' : 'hidden' }}
							className={cn(
								{ 'fill:fill-current': isPlaying },
								{ 'fill:fill-brand-200': !isPlaying },
								{ 'fill:fill-disabled': !isPlayerReady },
								'h-14 w-14'
							)}
							aria-label={`${isPlaying ? 'Pause' : 'Play'}`}
							onClick={() => {
								setIsPlaying(!isPlaying)
							}}
						>
							{isPlaying ? <V2.PauseCircleFilledIcon /> : <V2.PlayCircleFilledIcon />}
						</button>
					</div>

					{/* Waveforms */}
					<div
						ref={soundCapsuleContainerRef}
						className="flex-1"
						style={{ display: isPlayerReady && activePreset === 'soundCapsule' ? 'initial' : 'none' }}
					/>

					<div
						ref={enhancedContainerRef}
						className="flex-1"
						style={{ display: isPlayerReady && activePreset === 'enhanced' ? 'initial' : 'none' }}
					/>

					<div
						ref={spatializedContainerRef}
						className="flex-1"
						style={{ display: isPlayerReady && activePreset === 'spatialized' ? 'initial' : 'none' }}
					/>

					<div className="flex-1" style={{ display: isPlayerReady ? 'none' : 'initial' }}>
						<div className="flex h-[128px] flex-col items-center justify-center gap-2">
							<Soundwaves />
							<AnimatedDots>Loading player</AnimatedDots>
						</div>
					</div>
				</div>
				{/* Preset buttons */}
				<Container pageWidth="small">
					{isMobile ? (
						<MobileSelector
							onChange={(option: InputOption) => {
								const selectedPreset = (typeof option === 'string' ? option : option.value) as Preset
								setActivePreset(selectedPreset)
								sendTrackingEvent(selectedPreset)
							}}
							options={OPTIONS}
						/>
					) : (
						<StyledRadioButtonGroup
							className="select-none border-0"
							radioClassName="text-current hover:opacity-80 cursor-pointer" // reusing a component with hardcoded style :(
							radioSelectedClassName="bg-[--background-inverted] text-[--text-inverted]" // reusing a component with hardcoded style :(
							value={activePreset}
							onChange={(e) => {
								const selectedPreset = e.target.value as Preset
								setActivePreset(selectedPreset)
								sendTrackingEvent(selectedPreset)
							}}
							type="radio"
							name="version"
							autoComplete="off"
							options={OPTIONS}
						/>
					)}
					{presetsDescription && (
						<Paragraph type="bodyMd" className="mt-4">
							{presetsDescription}
						</Paragraph>
					)}
				</Container>
			</div>
			<div className="py-2 md:py-6" />
		</Section>
	)
}

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
	> div {
		border-color: #fffefd1f;
		border-width: 1px;
	}
`

const MobileSelector: FunctionComponent<{
	options: Readonly<InputOption[]>
	onChange: (option: InputOption) => void
}> = ({ options, onChange }) => {
	const [selectedIndex, setSelectedIndex] = useState(0)

	const changeHandler = (delta: number) => {
		if (delta > 0) {
			const newIndex = Math.min(selectedIndex + delta, options.length - 1)
			setSelectedIndex(newIndex)
			onChange(options[newIndex])
		}
		if (delta < 0) {
			const newIndex = Math.max(selectedIndex + delta, 0)
			setSelectedIndex(newIndex)
			onChange(options[newIndex])
		}
	}

	const selectedOption = options[selectedIndex]

	return (
		<div className={cn('flex items-center rounded-xl p-4', 'bg-[--background-inverted] text-[--text-inverted]')}>
			<V2.IconButton onClick={() => changeHandler(-1)} disabled={selectedIndex <= 0}>
				{/* Not able to set the stroke color of V2.Icon/IconButton by Tailwind for some reason */}
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M14 6L8 12L14 18"
						stroke={selectedIndex > 0 ? '#1F1F21' : '#808080'}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</V2.IconButton>
			{typeof selectedOption === 'string' ? (
				<div className="flex-1 select-none overflow-hidden text-ellipsis whitespace-nowrap">
					{startCase(selectedOption)}
				</div>
			) : (
				<div className="flex flex-1 flex-nowrap items-center justify-center overflow-hidden">
					{selectedOption.icon && (
						<V2.Icon style={{ height: '1.5em' }} icon={selectedOption.icon} className="pr-2" />
					)}
					<span className="select-none overflow-hidden text-ellipsis whitespace-nowrap">
						{selectedOption.label || selectedOption.value}
					</span>
				</div>
			)}
			<V2.IconButton onClick={() => changeHandler(1)} disabled={selectedIndex >= options.length - 1}>
				{/* Not able to set the stroke color of V2.Icon/IconButton by Tailwind for some reason */}
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10 6L16 12L10 18"
						stroke={selectedIndex < options.length - 1 ? '#1F1F21' : '#808080'}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</V2.IconButton>
		</div>
	)
}

const AnimatedDots = styled.div`
	:after {
		content: ' .';
		animation: dots 1s steps(5, end) infinite;
	}

	@keyframes dots {
		0%,
		20% {
			color: rgba(0, 0, 0, 0);
			text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		40% {
			color: white;
			text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		60% {
			text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
		}
		80%,
		100% {
			text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
		}
	}
`
