import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import type { ScrollyTell as ScrollyTellProps, SectionSettings } from '~/types'
import { ThemeProvider, getBackgroundColor, getColor, useIsomorphicEffect, useTheme, useWindowSize } from '~/utils'
import { Paragraph } from '../FontStyles'
import { Container } from '../layout/Containers'
import { useHeaderHeight } from '../layout/Header'
import { PortableText } from './PortableText'
import { Section } from './Section'

gsap.registerPlugin(ScrollTrigger)
const TWEEN_DURATION = 0.05
const lastItemDurationFactor = 7 // adjusts last item's tween speed
const lastItemExtraLengthFactor = 0.25 // adjusts last item's pin duration
const debug = false

export const ScrollyTell: FunctionComponent<ScrollyTellProps> = (props) => {
	const theme = useTheme(props.settings?.theme)
	const mounted = useRef(false)
	const triggerRef = useRef<HTMLDivElement>(null)
	const pinFullscreenRef = useRef<HTMLDivElement>(null)
	const itemsRef = useRef<Array<HTMLElement | null>>([])
	const [itemHeight, setItemHeight] = useState<number | undefined>()
	const { isMobile, height: windowHeight, bodyWidth } = useWindowSize()
	const headerHeight = useHeaderHeight()
	const [selectedItemIndex, setSelectedItemIndex] = useState(0)

	useIsomorphicEffect(() => {
		if (!isMobile || (isMobile && !itemHeight)) {
			setItemHeight(Math.max(...itemsRef.current.map((el) => el?.clientHeight ?? 0)))
		}
	}, [windowHeight, isMobile, itemHeight])

	const scrollSections = useMemo(() => {
		return props.list.map((section, i) => (
			<Section
				{...props.settings}
				key={`${section._key}-wrapper-${i}`}
				ref={(el) => (itemsRef.current[i] = el)}
				padding={{ _type: 'padding', top: '0px', bottom: '0px' }}
				margin={{ _type: 'margin', top: '0px', bottom: '0px' }}
				height={{
					_type: 'height',
					customDesktopHeight: `${itemHeight}px`,
					customMobileHeight: `${itemHeight}px`
				}}
			>
				<PortableText
					blocks={[
						{
							...section,
							settings: {
								...(section.settings as SectionSettings),
								padding: {
									...(section.settings?.padding ?? { _type: 'padding' }),
									top: '0',
									bottom: '0'
								}
							}
						}
					]}
				/>
			</Section>
		))
	}, [itemHeight, props.list, props.settings])

	useEffect(() => {
		const handler = () => ScrollTrigger.refresh()
		window.addEventListener('load', handler)

		return () => {
			window.removeEventListener('load', handler)
		}
	}, [])

	useEffect(() => {
		// TODO: consider using GSAP context instead and revert it when returning from useEffect
		// https://greensock.com/docs/v3/GSAP/gsap.context()
		if (mounted.current) {
			return
		}

		const transitionOverlapHeight = window.innerHeight / 2
		const containers = gsap.utils.toArray<HTMLElement>(itemsRef.current)

		// ----- Animation start

		ScrollTrigger.create({
			trigger: triggerRef.current,
			pin: pinFullscreenRef.current, // pinning pinFullscreenRef meaning it sticks to starting position while the ScrollTrigger is active
			start: 'top top', // when the top of the trigger hits the top of the viewport
			end: 'bottom bottom',
			markers: debug // devtool
		})

		// Don't need it as long as we have a "from" in our timeline.
		// gsap.set(containers, { yPercent: 200, opacity: 0 })

		containers.forEach((item, i) => {
			const isFirst = i === 0
			const isLast = i === containers.length - 1

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: triggerRef.current,
					start: () => `top+=${i * window.innerHeight - transitionOverlapHeight} top`, // when the top+offset of the trigger hits the top of the viewport
					end: () => `top+=${(i + 1) * window.innerHeight} top`,
					markers: false, // devtool
					scrub: true,
					pinSpacing: false,
					// As items are overlapping, we better use onLeave instead of onEnter.
					onLeave: () => setSelectedItemIndex(Math.min(i + 1, containers.length - 1)),
					onLeaveBack: () => setSelectedItemIndex(Math.max(i - 1, 0))
				}
			})

			// We are animating both to and from the pinning. And as animating the pinned element is considered a GSAP mistake, we just simulate pinning with an animation pause.
			tl.fromTo(item, !isFirst ? { yPercent: i * -100 + 100, opacity: 0 } : {}, {
				duration: !isLast ? TWEEN_DURATION : TWEEN_DURATION * lastItemDurationFactor, // tweaking speed of last item
				yPercent: i * -100,
				opacity: 1
			}) // we need to translate y inside the animation window (meaning we need to subtract the height of the items above)
				.to(
					item,
					// last item scrolling out normally instead of animating out
					!isLast ? { duration: TWEEN_DURATION, yPercent: i * -100 - 100, opacity: 0 } : {},
					`+=${TWEEN_DURATION / 2}` // adding a gap behind last tween creates a "pin" effect
				)
		})

		// ----- Animation end

		mounted.current = true
	}, [])

	return (
		<ThemeProvider theme={theme}>
			<ScrollingContainer
				{...props}
				$scrollSectionsLength={scrollSections.length}
				style={{
					width: bodyWidth ? `${bodyWidth}px` : '100vw',
					position: 'relative',
					left: '50%',
					right: '50%',
					marginLeft: `calc(-${bodyWidth / 2}px)`,
					marginRight: `calc(-${bodyWidth / 2}px)`
				}}
				ref={triggerRef}
			>
				{/* Fullscreen pinned while scrolling */}
				<div ref={pinFullscreenRef} style={{ height: '100svh' }} className={debug ? 'bg-[cyan]' : ''}>
					<div className="flex h-full flex-col justify-center">
						<div style={{ paddingTop: `${headerHeight}px` }} />
						{props.title && (
							<Container pageWidth="small" className="text-center">
								<Paragraph type="overlineMd">{props.title}</Paragraph>
							</Container>
						)}
						{props.subtitle && (
							<Container pageWidth="small" className="text-center">
								<Paragraph type="bodySm">{props.subtitle}</Paragraph>
							</Container>
						)}
						<div className="py-2" />

						{/* The scrolling window */}
						<div className="w-full overflow-hidden" style={{ height: itemHeight }}>
							{scrollSections}
						</div>

						<Container pageWidth="small" className="text-center">
							{[...new Array(scrollSections.length).keys()].map((i) => (
								<span key={i} className="px-1" style={{ opacity: i > selectedItemIndex ? 0.32 : 1 }}>
									&#x2022;
								</span>
							))}
						</Container>
					</div>
				</div>
			</ScrollingContainer>
		</ThemeProvider>
	)
}

const ScrollingContainer = styled.div<ScrollyTellProps & { $scrollSectionsLength: number }>`
	height: ${(p) => `${(p.$scrollSectionsLength + lastItemExtraLengthFactor) * 100}vh`};
	color: ${getColor};
	background-color: ${getBackgroundColor};
`
