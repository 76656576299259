import { V2 } from '@nomonosound/gravity'
import { FC } from 'react'
import { HeroSectionV2 as HeroSectionV2Props } from '~/types'
import { cn, useWindowSize } from '~/utils'
import { Heading, Paragraph } from '../FontStyles'
import { ResponsiveImage } from '../Image'
import { SanityLink } from '../SanityLink'

export const HeroSection: FC<HeroSectionV2Props & { isFirstBlock: boolean }> = (props) => {
	const { isMobile } = useWindowSize()
	const image = isMobile && props.mobileImage ? props.mobileImage : props.mainImage

	function doScroll() {
		// scroll to target if it exists
		if (props.scrollTargetId) {
			const target = document.querySelector(`#${props.scrollTargetId}`)
			if (target) {
				target.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}

	return (
		<div id={props.id} className={props.isFirstBlock ? 'mb-6 md:mb-12' : 'my-6 md:my-12'}>
			<div
				className={cn(
					'm-2 flex flex-row flex-wrap items-center md:m-0 ',
					props.layout === 'imageLeft' ? 'md:flex-row' : 'md:flex-row-reverse',
					props.hasWhiteBackground ? 'bg-[#FFFEFD]' : '',
					props.hasRoundedCorners ? 'overflow-hidden rounded-2xl' : ''
				)}
			>
				<div className="mb-8 w-full md:mb-0 md:w-3/6">{image && <ResponsiveImage image={image} />}</div>
				<div className="w-full text-center md:w-3/6">
					<div className={cn('xl:mx-12', props.hasWhiteBackground ? 'mx-4' : '')}>
						{props.label && (
							<span className="md:text-4 text-3 leading-default mb-4 inline-block rounded-lg bg-[#D0BFFF] py-2 px-[14px] font-sans font-normal tracking-[-0.01em]">
								{props.label}
							</span>
						)}
						<Heading type="displayMd" className="md:!text-8 !text-7 mb-4">
							{props.title}
						</Heading>
						<Paragraph type="textXl" className="mb-8">
							{props.description}
						</Paragraph>
						{props.callToAction && <SanityLink link={props.callToAction} className="mb-8 md:mb-0" />}
					</div>
				</div>
			</div>

			{props.scrollText && props.scrollTargetId && (
				<div className="flex cursor-pointer flex-col items-center" onClick={() => doScroll()}>
					{props.scrollText && (
						<Paragraph type="bodyMd" className="whitespace-nowrap">
							{props.scrollText}
						</Paragraph>
					)}

					<V2.IconButton {...props}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fillOpacity={0}
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M6 9L12 15L18 9"
								stroke="#1F1F21"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</V2.IconButton>
				</div>
			)}
		</div>
	)
}
