export const peak1 = [
	[
		0, 0.0017, -0.001, 0.0022, 0.0035, -0.0025, 0.0114, -0.0028, -0.0158, -0.0042, 0.0069, -0.0007, -0.0097,
		-0.0004, -0.001, -0.0035, -0.0351, -0.0254, -0.0353, 0.0146, 0.0511, 0.0563, -0.0038, 0.0114, 0.0257, -0.0315,
		0.0223, 0.0297, -0.0057, -0.0662, 0.078, 0.0239, -0.0453, 0.0593, -0.0589, 0.0101, -0.0019, 0.0009, 0.0584,
		0.0052, -0.0189, 0.028, -0.0071, 0.0121, 0.0009, -0.0416, -0.0116, -0.0509, -0.012, 0.0477, 0.0067, 0.0243,
		0.0033, -0.0348, -0.0225, 0.0114, 0.0139, 0.0163, -0.0343, -0.0002, -0.0165, -0.019, -0.0311, 0.0292, -0.0222,
		-0.0084, -0.0066, -0.033, 0.048, -0.0264, -0.006, -0.0029, 0.0137, 0.0097, -0.0165, 0.0071, 0.022, -0.0234,
		-0.0161, 0.0104, 0.0339, -0.0185, 0.0016, 0.0126, 0.0033, 0.0038, -0.0216, -0.0202, -0.015, 0.0027, 0.0311,
		-0.0008, 0.0059, 0.0361, 0.0018, 0.1257, -0.03, 0.048, -0.0746, 0.0437, -0.0014, 0.0047, 0.0823, -0.1698,
		0.1871, 0.0083, -0.0444, -0.0615, 0.0089, 0.0006, 0.0038, -0.0007, -0.0069, 0.0042, -0.0056, 0.0036, -0.0399,
		0.0005, 0.0092, 0.0052, 0.0045, 0.0009, -0.0294, 0.0249, 0.0007, 0.0587, -0.0036, -0.0004, 0.0037, -0.0109,
		0.0029, -0.0166, 0.0131, -0.0411, 0.0414, -0.0353, -0.003, 0.0112, -0.041, -0.0524, 0.011, 0.0217, 0.0487,
		-0.031, -0.066, -0.03, 0.1272, 0.0159, 0.0477, -0.078, 0.0534, -0.0078, 0.004, 0.0227, -0.0145, -0.007, -0.0002,
		0.0021, -0.0119, -0.0079, 0.0903, 0.0871, -0.0533, 0.0468, -0.0084, -0.0398, -0.0436, -0.0391, 0.0882, -0.0576,
		-0.0612, -0.0042, -0.0053, -0.0271, 0.0101, 0.0218, -0.003, -0.0037, 0.0191, 0.0066, -0.0027, 0.005, 0.0229,
		-0.0367, -0.005, 0.0276, -0.013, 0.0213, -0.0298, 0.0096, -0.0134, 0.0108, 0.0091, 0.0263, 0.0028, 0.0054,
		-0.017, -0.0061, 0.0022, 0.0134, 0.0015, 0.0064, -0.0009, 0.04, -0.0085, 0.0201, 0.0187, -0.008, -0.0122,
		-0.0389, -0.0056, -0.0187, -0.016, -0.0017, 0.0183, 0.0081, -0.0062, 0.0036, -0.0162, 0.0176, 0.006, -0.0074,
		-0.0093, -0.0101, -0.0097, 0.0099, -0.0019, 0.0065, 0.017, 0.0033, -0.0069, 0, -0.0111, -0.0261, -0.0009,
		0.0045, 0, 0.0394, -0.0014, -0.0253, 0.005, -0.0031, 0.033, 0.0544, -0.0161, 0.0082, 0.0016, -0.0086, 0.0322,
		-0.0152, -0.0426, 0.0664, -0.0158, 0.0308, 0.036, -0.0524, 0.03, 0.0829, -0.0207, -0.0108, -0.0137, -0.0044,
		-0.0204, 0.0329, -0.0783, -0.0018, -0.0915, 0.0219, 0.1196, -0.1117, 0.0117, -0.0424, -0.1414, -0.0614, -0.0163,
		-0.0144, 0.0081, 0.0112, 0.0145, -0.0109, -0.0308, -0.0281, -0.0594, -0.0043, 0.0298, -0.0421, -0.0215, 0.0697,
		0.0071, -0.0112, 0.0667, -0.0115, -0.0864, 0.1628, -0.0698, 0.0073, 0.0779, -0.1338, 0.0083, -0.0629, -0.0372,
		0.0331, -0.0066, -0.0186, 0.0003, -0.0044, 0.0034, 0.0289, 0.0447, -0.0521, -0.0557, 0.0708, 0.0576, -0.0402,
		-0.0822, 0.1218, -0.0322, -0.0367, 0.1437, -0.1157, 0.0343, -0.1071, -0.0096, -0.0022, 0.0057, -0.0137, -0.0083,
		-0.0124, 0.0086, 0.0154, 0.0659, 0.0064, 0.0056, 0.0056, 0.0674, 0.0465, -0.0184, -0.0083, 0.0008, -0.0002,
		-0.0128, -0.0029, -0.0343, 0.0107, -0.0775, 0.0293, 0.0178, -0.0301, 0.0057, -0.1016, 0.0381, 0.0549, 0.0716,
		-0.0519, 0.1582, -0.1083, 0.0076, -0.1158, 0.0485, -0.0298, 0.1704, -0.0329, -0.0838, -0.1197, 0.2203, 0.0691,
		-0.0484, -0.0426, -0.1066, 0.0043, 0.0064, 0.0136, -0.0051, 0.0325, -0.0243, -0.0193, 0.0443, 0.0145, 0.0402,
		0.0122, -0.0133, 0.0392, 0.0012, 0.0228, -0.0189, 0.0187, -0.0067, 0.0581, 0.0862, 0.0126, 0.0052, 0.004,
		0.0237, 0.0092, 0.0165, -0.0023, 0.0084, -0.0129, -0.018, -0.0018, -0.0197, 0.0062, -0.0173, -0.007, -0.0059,
		0.0042, -0.0107, -0.0471, 0.0286, -0.0615, 0.0394, 0.0001, 0.0119, -0.0252, 0.0687, 0.0797, -0.0179, -0.0379,
		0.0565, -0.0252, -0.0139, 0.0692, 0.0379, -0.0309, -0.0154, 0.0183, 0.0678, -0.0443, 0.0013, -0.0059, -0.0555,
		-0.0377, 0.0223, -0.0324, -0.0205, -0.0151, -0.0236, 0.0126, 0.0403, -0.0018, -0.0232, 0.0285, -0.0062, 0.0062,
		-0.0045, 0.0304, 0.0097, -0.0173, -0.0234, 0.0051, -0.0213, 0.0114, 0.0181, 0.0012, -0.0462, -0.02, -0.0213,
		0.0052, 0.0111, -0.0116, -0.0044, 0.0171, 0.0034, 0.0082, -0.0025, 0.0126, 0.0102, -0.0002, -0.0398, 0.0721,
		-0.0629, 0.0231, -0.0118, -0.0489, 0.0156, 0.0071, -0.0136, 0.0378, -0.0147, 0.009, 0.035, 0.0204, 0.0923,
		-0.0155, -0.0082, 0.1345, 0.0498, -0.1026, 0.0411, -0.129, 0.0529, 0.0479, 0.0201, -0.0215, 0.0256, -0.0139,
		-0.0258, 0.0242, 0.0091, 0.0137, -0.0029, -0.0095, 0.0114, 0.0175, -0.0183, 0.0166, 0.015, 0.0432, -0.0126,
		0.0115, 0.0236, 0.0975, 0.0774, 0.0379, 0.0108, 0.0246, -0.0573, 0.027, -0.0091, -0.0058, 0.0298, -0.0014,
		-0.0577, 0.0135, 0.0034, -0.0059, -0.0107, 0.0172, 0.0173, -0.0474, 0.0178, 0.1432, 0.0392, -0.0703, 0.1142,
		0.063, -0.1225, -0.0164, 0.0752, 0.2072, 0.1082, 0.1642, 0.1898, 0.0555, -0.0019, -0.0851, -0.0236, -0.0594,
		-0.1771, -0.0569, -0.0741, -0.0555, 0.0274, 0.0697, 0.0004, -0.0354, 0.003, 0.0694, 0.0111, 0.0066, -0.0112,
		-0.0307, -0.0069, 0.0145, -0.0425, 0.0262, 0.0133, 0.0122, 0.0226, -0.0162, -0.0091, 0.0251, 0.0038, -0.0156,
		0.0177, 0.0028, -0.0459, -0.027, -0.018, -0.0004, -0.0075, -0.0049, -0.0047, -0.021, 0.0064, -0.0025, 0.0179,
		-0.011, 0.0183, -0.0106, -0.0065, -0.0123, -0.0051, -0.0261, -0.0124, 0.0043, -0.0074, 0.0186, 0.0008, 0.0212,
		-0.002, 0.0046, -0.0187, 0.017, -0.0056, -0.0215, 0.013, 0.0114, 0.0002, 0.0009, -0.0184, 0, 0.0194, 0.0137,
		-0.0068, -0.0089, -0.0073, -0.0072, -0.0141, 0.0001, 0.0077, -0.0265, -0.0202, 0.0149, -0.0134, 0.0057, -0.0097,
		0.0033, 0.0036, 0.0155, 0.0036, 0.0147, -0.0148, 0.0272, -0.0095, -0.0016, 0.0053, 0.0107, 0.0322, -0.0078,
		-0.0085, 0.0156, 0.0031, 0.0137, -0.0593, 0.0787, 0.0426, 0.0155, -0.0812, -0.0258, 0.0853, -0.0049, -0.1318,
		0.0166, -0.0108, 0.0025, 0.0251, 0.0188, -0.0213, -0.0022, 0.0421, -0.0313, 0.0169, -0.0187, 0.0031, -0.0266,
		0.0076, -0.0362, -0.0184, 0.0455, -0.0324, 0.0174, -0.0038, 0.0174, -0.015, 0.0018, 0.0284, 0.0224, 0.0208,
		0.0275, -0.0117, 0.0299, -0.0343, -0.0168, -0.0299, 0.0447, -0.0424, 0.0177, -0.0028, 0.0074, 0.0147, -0.0173,
		-0.0334, 0.0428, 0.0367, -0.0455, 0.0028, 0.0063, 0.0247, 0.0279, 0.0229, -0.0123, 0.0453, -0.0241, -0.0098,
		-0.0225, 0.0128, 0.0168, 0.0042, 0.0006, -0.012, -0.0098, -0.0349, -0.0053, -0.0091, 0.0069, 0.0064, -0.0132,
		-0.0015, -0.0159, 0.0365, -0.0458, -0.0133, -0.0148, 0.0024, 0.0265, -0.008, -0.0279, -0.0585, 0.0397, -0.0089,
		0.0234, -0.0001, -0.0193, 0.0084, 0.0093, -0.0801, -0.1, 0.0317, -0.0066, -0.0391, 0.0059, 0.0124, 0.0419,
		-0.0089, -0.0067, -0.0054, -0.017, 0.009, -0.0113, 0.0074, 0.0092, 0.0199, -0.0093, -0.0129, -0.0042, -0.0028,
		0.0137, 0.0107, -0.0275, -0.0407, -0.0315, 0.0704, 0.0118, -0.0643, -0.1477, -0.0785, -0.0887, -0.0192, 0.0142,
		-0.0179, 0.0025, 0.0197, -0.0184, 0.0183, -0.0469, -0.0684, 0.0217, -0.046, 0.0372, -0.0407, 0.0368, -0.0212,
		0.0086, 0.0226, -0.0006, -0.0216, -0.0123, 0.018, 0.0032, 0.0156, -0.0006, -0.0014, 0.0233, -0.0324, 0.0486,
		0.006, -0.0279, 0.0139, -0.045, 0.0475, -0.0232, -0.0407, -0.0034, 0.0054, -0.0008, 0.0084, 0.0142, 0.0191,
		-0.0048, 0.044, -0.0393, -0.0224, -0.0539, -0.0359, -0.0366, -0.0052, -0.104, 0.0591, -0.0897, 0.1233, 0.0368,
		-0.1965, -0.1131, 0.1691, 0.1927, 0.0663, 0.0537, 0.0449, 0.011, 0.0082, -0.0387, 0.0151, 0.0104, -0.0191,
		-0.0015, -0.0119, -0.0066, -0.0401, -0.0181, 0.0941, 0.023, 0.1481, 0.0594, -0.005, 0.0134, -0.0449, -0.0467,
		0.013, -0.0224, 0.0307, 0.0335, -0.0035, -0.0241, -0.0081, -0.0529, 0.0258, 0.014, 0.1272, 0.0437, -0.0524,
		-0.0488, 0.0128, -0.0754, -0.0607, 0.0048, -0.0294, -0.0547, -0.058, -0.0451, 0.129, -0.0393, 0.0384, 0.0098,
		0.014, 0.0007, -0.0254, -0.0135, -0.0089, -0.0081, 0.0256, -0.0102, -0.0065, 0.0031, 0.0123, -0.0606, -0.07,
		0.0571, -0.051, 0.0614, -0.0949, 0.1272, 0.0596, 0.0148, -0.0416, -0.1195, -0.0874, 0.0727, 0.0006, 0.0801,
		-0.0892, -0.02, 0.0306, 0.0347, 0.0033, -0.0034, 0.005, 0.0099, 0.0153, -0.0172, -0.0151, -0.0326, -0.0045,
		0.0298, 0.0005, 0.0127, 0.0008, 0.0373, 0.0112, -0.0252, -0.024, 0.0021, -0.0365, 0.0313, -0.001, 0.0518,
		-0.0459, 0.0674, 0.0664, -0.0088, 0.0197, 0.0059, -0.0115, 0.0406, 0.0186, 0.0265, -0.0003, 0.0564, -0.0188,
		0.0147, 0.0127, 0.0019, 0.0021, 0.034, -0.0386, -0.0001, 0.0325, -0.0312, 0.0731, -0.083, -0.058, 0.0421,
		-0.0727, 0.0939, -0.0745, 0.0045, 0.038, -0.1061, 0.1192, 0.0181, -0.0893, -0.0592, -0.02, -0.0615, 0.0094,
		0.0015, 0.0105, 0.0141, -0.0044, -0.0186, -0.0084, -0.0289, 0.0341, 0.0387, -0.0006, -0.0139, -0.0073, 0.0071,
		-0.0045, 0.0121, 0.0299, 0.0235, -0.0124, 0.0011, 0.0051, -0.0081, -0.0169, 0.0046, 0.0221, 0.0042, -0.007,
		-0.0184, 0.021, -0.0078, -0.0141, -0.0171, 0.0286, 0.0019, -0.0033, -0.0016, 0.0167, 0.0501, 0.0164, 0.0003,
		-0.017, 0.0088, 0.0461, 0.0108, 0.0346, -0.0015, 0.0002, 0.0012, -0.0245, -0.0197, -0.0137, -0.0009, 0.0042,
		0.002, -0.0229, -0.0297, -0.0001, 0.007, 0.0122, -0.0215, 0.0105, 0.0064, 0.011, 0.0693, 0.0151, -0.0538,
		-0.0805, 0.0983, -0.0721, 0.0363, -0.033, 0.009, -0.0574, -0.0208, 0.0196, 0.0738, -0.0286, 0.0399, 0.0938,
		0.0149, -0.0786, 0.0155, -0.0526, -0.02, 0.0174, -0.0162, 0.0673, -0.0766, 0.0281, 0.0793, -0.0791, -0.0802,
		-0.0299, -0.0181, -0.0144, -0.0479, -0.1433, -0.1418, -0.1527, -0.1829, -0.1209, -0.0843, -0.0696, -0.1003,
		-0.0088, 0.0467, 0.0333, 0.0141, -0.0789, -0.0916, 0.0211, 0.0822, 0.0908, 0.1057, 0.1773, -0.0041, -0.115,
		-0.0801, -0.17, 0.0914, 0.1035, -0.1932, 0.1644, 0.0148, -0.1068, -0.0477, 0.0216, -0.0703, -0.0076, -0.041,
		0.045, -0.0131, -0.0113, -0.0095, 0.0053, -0.028, 0.0296, 0.0073, 0.022, 0.0382, -0.0001, 0.0391, -0.0214,
		0.1166, -0.187, 0.1632, -0.0786, -0.0042, 0.0203, -0.0913, 0.1298, -0.0077, 0.0429, -0.1486, -0.0962, 0.0649,
		0.1077, -0.2029, -0.0296, 0.0581, 0.0103, -0.033, -0.1, 0.0035, -0.0351, 0.0878, -0.0159, -0.0369, 0.0471,
		0.0054, 0.0199, -0.0195, 0.0156, 0.0105, 0.019, -0.0378, 0.0165, -0.0449, -0.0081, 0.0113, 0.0178, -0.0064,
		-0.0164, 0.0478, 0.0207, 0.0012, -0.0229, 0.0198, 0.0012, -0.0518, 0.0247, 0.0002, -0.0318, 0.1233, 0.063,
		-0.0517, 0.0335, 0.0349, -0.0446, 0.1089, 0.063, 0.0604, -0.0162, -0.0917, -0.0426, 0.071, 0.0257, -0.0021,
		0.0471, 0.005, -0.0727, 0.021, -0.0159, -0.0311, -0.0045, 0.0201, -0.0088, -0.0175, 0.0067, 0.0222, 0.0316,
		0.0018, -0.0079, -0.009, -0.0073, 0.0529, -0.08, 0.0882, -0.0029, -0.0107, -0.0024, 0.0089, 0.0099, -0.0105,
		-0.0166, 0.0041, 0.0125, 0.0185, -0.0136, -0.0183, -0.0016, 0.137, 0.1854, -0.0035, 0.0298, -0.0077, 0.0184,
		-0.0239, -0.0432, 0.0505, -0.06, -0.0244, -0.0136, 0.0197, 0.0135, 0.0005, 0.0008, 0.01, 0.0186, -0.0129,
		0.0088, -0.0383, -0.0101, 0.0356, -0.0371, -0.0157, 0.0471, -0.0623, 0.0063, -0.0953, -0.091, -0.1076, -0.0863,
		-0.0716, 0.1592, 0.0997, 0.0074, -0.1396, -0.0912, 0.0503, -0.0145, -0.018, 0.029, -0.022, 0.0011, 0.0097,
		0.0112, 0.0011, -0.006, -0.0259, -0.0326, 0.1086, 0.041, -0.0223, -0.0844, 0.0663, -0.1023, 0.0268, 0.0309,
		-0.0036, -0.029, -0.0768, 0.0052, 0.0479, 0.0533, 0.0483, 0.0064, -0.0035, 0.0158, -0.0084, -0.0145, 0.0057,
		0.0154, -0.0024, -0.0121, 0.002, -0.007, -0.0094, 0.0071, -0.0068, 0.0025, 0.0175, 0.0086, -0.0094, 0.021,
		-0.0082, 0.0058, -0.0064, -0.0028, 0.0206, -0.0117, 0.0069, -0.0402, 0.0134, 0.0346, 0.0442, -0.0268, 0.0554,
		0.036, -0.0612, 0.0398, 0.0103, -0.0843, 0.0331, 0.0024, -0.0118, 0.0063, -0.0971, -0.0981, 0.0402, -0.0086,
		-0.0954, 0.0037, 0.0897, 0.0154, -0.0412, -0.0331, 0.0007, 0.0365, 0.0225, 0.0337, 0.0436, 0.089, 0.0276, 0.071,
		0.0046, 0.0351, 0.0156, 0.0017, 0.001, 0.0057, 0.0034, 0.0087, 0.0058, -0.0181, -0.0118, -0.0185, 0.0022,
		-0.0124, -0.0042, -0.017, 0.0161, 0.0008, 0.0077, -0.0092, -0.0143, 0.0123, -0.0035, 0.0142, 0.0119, 0.0172,
		-0.0199, 0.007, 0.0103, 0.0259, -0.0094, 0.016, 0.012, 0.0042, 0.0135, 0.021, -0.0182, -0.0019, 0.0202, 0.002,
		-0.001, -0.0085, -0.0051, -0.0009, 0.0019, -0.0053, 0.0078, -0.0118, 0.0191, 0.009, -0.0044, -0.0162, 0.0074,
		-0.0012, 0.0018, 0.0093, 0.0174, 0.0082, 0.0098, 0.0158, 0.0011, 0.0011, 0.0054, -0.0203, -0.0007, -0.019,
		-0.0002, -0.0212, 0.0004, -0.0256, -0.0042, -0.0244, -0.0164, -0.0091, -0.0223, 0.0014, -0.0205, 0.0201, 0.0183,
		-0.0134, -0.0249, 0.0097, 0.0148, -0.0479, -0.0839, 0.0398, 0.0049, 0.115, 0.0415, -0.0059, 0.064, 0.056,
		0.0713, 0.0067, -0.0011, 0.0138, -0.0109, -0.0073, 0.0092, -0.0053, 0.0236, -0.0136, -0.0053, 0.0237, 0.0112,
		0.0087, -0.0059, -0.0043, -0.0163, 0.0293, -0.026, -0.0138, 0.0136, 0.0051, 0.0122, -0.0119, -0.0329, 0.0177,
		-0.0241, -0.0109, 0.0431, 0.159, -0.095, -0.051, 0.0413, -0.0549, 0.0791, 0.0526, -0.0169, -0.0103, 0.0034,
		0.0223, -0.0288, -0.0122, 0.0027, 0.0043, -0.0041, 0.0097, 0.0173, -0.0112, -0.0239, 0.105, -0.0229, 0.0432,
		-0.0503, -0.0135, 0.0177, -0.0647, 0.0148, 0.0157, -0.0273, -0.0094, 0.0273, 0.0116, 0.0476, 0.0033, 0.0332,
		0.0302, 0.0085, -0.0245, -0.0119, -0.0271, -0.0341, -0.0012, -0.006, -0.0217, -0.0404, -0.0155, -0.0248, 0.0089,
		0.0071, -0.0183, 0.0096, 0.0156, -0.0446, 0.0315, 0.108, 0.0819, 0.2243, -0.0608, -0.0527, 0.0534, -0.0137,
		-0.0645, 0.0823, 0.0899, 0.039, -0.0069, -0.0489, -0.0665, -0.0289, -0.0872, -0.0026, 0.0609, -0.0168, 0.0287,
		0.0557, 0.0126, -0.08, 0.0369, 0.0553, 0.0176, -0.0693, 0.0528, 0.0273, -0.045, 0.0302, 0.0076, -0.0148,
		-0.0414, 0.0158, -0.0048, 0.0044, -0.0085, 0.0263, -0.0054, 0.0087, 0.0399, 0.0093, -0.0333, 0.0302, -0.0009,
		0.0012, -0.0196, -0.0032, 0.0102, 0.0082, -0.0125, -0.0175, -0.0016, 0.1249, -0.0451, 0.104, 0.0895, -0.0087,
		0.0483, 0.1341, -0.0696, -0.0048, 0.0781, -0.0405, 0.0313, 0.0248, 0.0055, -0.005, -0.0127, 0.0153, -0.0268,
		0.0042, 0.0249, 0.1185, 0.0188, -0.0574, -0.1397, -0.0601, 0.0477, 0.0488, -0.0369, 0.0099, -0.058, -0.1742,
		0.1734, 0.0856, -0.0421, -0.078, -0.0432, 0.0535, 0.076, 0.1344, 0.0814, -0.0146, 0.0821, 0.0645, 0.0287,
		-0.0526, -0.0009, 0.0268, -0.0653, 0.0619, 0.0029, -0.0417, 0.06, -0.0226, 0.0158, -0.0167, -0.0224, -0.0088,
		0.0128, 0.0166, 0.0044, -0.0278, -0.0471, -0.0721, -0.033, 0.0138, 0.0675, 0.0715, 0.0481, 0.1134, 0.1453,
		0.0011, 0.0238, -0.023, -0.0307, 0.1982, -0.0552, -0.1246, 0.0716, -0.0428, -0.1755, -0.1236, 0.0462, -0.0115,
		0.0516, -0.048, -0.0403, -0.0378, 0.0036, -0.0769, -0.0915, 0.1873, 0.0634, 0.0975, 0.1082, -0.0293, -0.0059,
		-0.0025, 0.0346, 0.1058, -0.0627, -0.0121, 0.043, -0.0413, -0.0077, -0.0724, 0.0412, -0.0046, 0.0337, -0.0609,
		-0.0285, 0.0618, 0.0058, -0.0155, 0.0368, 0.0069, -0.0095, -0.0033, -0.0274, 0.0171, 0.0341, 0.0573, 0.0239,
		0.0021, 0.0117, 0.028, -0.0086, 0.0107, 0.0074, 0.0629, 0.0426, -0.0226, -0.0399, 0.0622, -0.028, 0.0328, 0.02,
		0.0051, 0.0229, 0.0474, -0.0365, 0.0127, -0.0215, -0.0408, 0.0923, -0.0546, 0.0315, -0.0216, -0.0163, -0.0157,
		0.003, -0.0006, -0.021, -0.0083, 0.0378, -0.0642, -0.0167, 0.0216, 0.0226, 0.0097, 0.0055, 0.0053, -0.0034,
		0.0024, -0.0126, 0.0109, 0.0219, 0.0116, -0.0132, -0.0165, 0.0126, -0.0798, 0.0633, 0.072, 0.0493, 0.0181,
		-0.0327, -0.0075, -0.0153, 0.0414, 0.1051, 0.0511, -0.0839, -0.0638, -0.0294, 0.0387, 0.0078, -0.0243, -0.0107,
		0.0391, 0.0083, -0.0553, 0.0913, -0.0831, 0.0635, -0.0366, 0.0585, 0.0136, 0.0244, 0.058, -0.0187, 0.0207,
		-0.0845, -0.0008, 0.0299, 0.0431, -0.0846, 0.0515, 0.0515, 0.0843, 0.0417, 0.0636, 0.0163, 0.0958, 0.15,
		-0.1419, -0.0217, 0.0368, 0.1142, -0.0616, 0.0255, -0.032, -0.0172, 0.0174, 0.0332, -0.0338, -0.0287, -0.0887,
		-0.0192, -0.0308, -0.0038, -0.0036, -0.033, -0.0111, 0.0207, -0.023, 0.0088, -0.0233, 0.012, -0.0051, -0.0621,
		0.0038, 0.0324, -0.0217, -0.1169, -0.1215, -0.1478, -0.0533, -0.0813, 0.1148, -0.0509, -0.0221, -0.0374,
		-0.0341, -0.0137, -0.0269, 0.0115, 0.009, 0.0268, 0.0043, 0.0291, -0.0198, 0.0009, 0.0178, 0.0274, -0.0006,
		0.0162, 0.0578, -0.0439, -0.0317, 0.0664, -0.0469, 0.0486, -0.0589, 0.0174, -0.0017, -0.0513, -0.0029, 0.0205,
		0.0152, 0.0398, -0.0323, -0.0394, 0.0676, 0.0392, 0.0162, 0.0302, 0.0175, -0.0716, -0.0428, 0.0238, -0.0059,
		-0.025, -0.0134, 0.0559, 0.0714, 0.0449, -0.0488, -0.0008, -0.0198, 0.0143, -0.0215, 0.0033, -0.0091, -0.0026,
		-0.0385, -0.0196, -0.0182, 0.0057, -0.0217, -0.0528, 0.0189, -0.0357, -0.1098, 0.0025, 0.0693, -0.0497, -0.1134,
		-0.0962, 0.148, -0.0253, 0.007, -0.0741, 0.0755, 0.0605, 0.0032, -0.0711, 0.0274, 0.0487, 0.0281, 0.0014,
		-0.0597, 0.0357, 0.0191, 0.0181, -0.0236, 0.0097, 0.027, -0.0428, -0.0939, 0.0205, 0.0857, 0.0685, -0.0187,
		-0.022, -0.0479, -0.0477, -0.0366, 0.0243, 0.0227, -0.0062, 0.0597, 0.0025, -0.0321, -0.0491, 0.0537, -0.0073,
		0.0008, -0.0658, 0.0491, 0.0478, -0.0247, 0.0148, 0.0683, 0.0692, -0.0099, -0.0434, 0.0286, -0.0181, 0.019,
		0.0379, -0.0269, -0.0586, 0.1067, 0.0543, -0.01, -0.029, -0.0023, 0.0194, -0.0041, 0.0249, 0.0111, 0.0212,
		-0.0085, -0.0245, -0.0082, 0.0289, -0.0003, 0.006, 0.0057, -0.0124, 0.0138, -0.004, -0.0187, -0.0482, 0.0069,
		0.0036, -0.0082, 0.0377, -0.0149, -0.0023, 0.0849, -0.0302, 0.0166, -0.0491, 0.0596, 0.1562, 0.0484, -0.0243,
		-0.1038, 0.083, 0.0631, 0.025, -0.0451, -0.0059, -0.0207, -0.0301, 0.0045, 0.0465, -0.0118, 0.0351, 0.0136,
		-0.0248, 0.0183, 0.08, -0.0272, 0.0228, -0.0229, 0.0185, 0.0219, 0.0177, 0.044, -0.0042, -0.0107, -0.0039,
		-0.0122, -0.011, 0.077, 0.0216, 0.0004, -0.0508, -0.0094, -0.0432, 0.0201, 0.0488, -0.0115, -0.0249, -0.0205,
		0.016, -0.008, 0.0003, 0.0118, 0.0067, 0.0064, -0.002, 0.0193, 0.0006, -0.0245, -0.0035, -0.0159, 0.015,
		-0.1096, 0.0139, 0.1119, 0.048, -0.0601, 0.0268, -0.0604, -0.0115, 0.0093, 0.0019, -0.0174, -0.0045, 0.0237,
		-0.0109, -0.018, -0.0081, -0.0093, 0.0275, -0.0154, -0.0022, -0.0055, -0.049, -0.0356, -0.0499, 0.0148, 0.0138,
		0.0555, 0.1214, 0.1029, -0.1299, -0.0218, -0.0442, 0.1435, 0.0256, 0.0876, -0.0017, -0.0718, -0.0635, -0.0848,
		-0.0666, -0.0008, 0.1183, 0.0033, -0.0109, 0.0295, -0.0052, -0.0063, -0.0061, 0.006, 0.0037, -0.0059, -0.0221,
		0.0128, 0.0175, -0.0016, 0.0008, 0.0012, 0.0141, -0.0142, -0.0262, 0.0105, 0.0095, -0.0143, 0.0205, 0.007,
		-0.0147, 0.0101, 0.0102, -0.0185, -0.0095, -0.0084, 0.0143, 0.0087, -0.018, 0.03, -0.0091, -0.0278, -0.0058,
		0.0644, 0.0009, 0.0006, 0.0141, 0.0169, -0.0095, -0.0314, 0.0072, 0.0467, -0.0293, -0.0613, -0.0224, 0.0081,
		0.0712, -0.0519, -0.0708, 0.0838, -0.0861, -0.0076, 0.0175, -0.0748, 0.0136, 0.0003, -0.0145, -0.0119, 0.0198,
		0.0159, -0.0167, -0.0011, 0.0251, -0.0402, -0.056, -0.0404, -0.0532, -0.0041, 0.0247, -0.0245, -0.0164, 0.0477,
		-0.0217, -0.0637, -0.003, 0.0285, -0.0153, -0.0456, 0.0261, 0.0046, 0.0097, 0.0018, -0.0219, 0.0353, 0.0778,
		0.0173, -0.0275, 0.0061, -0.0141, -0.0054, -0.0042, 0.0024, -0.0089, -0.0104, 0.0578, 0.0193, 0.0109, 0.0404,
		-0.0473, 0.0202, -0.0669, 0.0056, -0.0171, 0.0142, -0.0185, 0.0261, 0.0117, 0.0054, -0.0215, 0.0193, 0.0248,
		-0.0128, -0.0595, -0.0188, 0.1122, -0.064, -0.1415, 0.0296, -0.0185, 0.1596, 0.1602, 0.3136, 0.1159, 0.0731,
		-0.01, 0.0661, -0.2155, -0.1889, 0.1317, -0.0364, 0.0583, -0.0584, 0.0374, -0.1126, -0.1522, -0.0019, 0.0596,
		-0.009, 0.0139, 0.0022, 0.0222, -0.0061, -0.0006, 0.0236, 0.0001, -0.0113, -0.0056, 0.0303, 0.0147, 0.0034,
		-0.0073, -0.0272, -0.0033, 0.0044, 0.0047, 0.0039, 0.0333, 0.0143, 0.0218, 0.0295, 0.0769, -0.0207, 0.1132,
		0.0848, 0.1828, 0.1772, 0.1206, -0.0144, -0.1428, -0.1217, -0.0983, -0.0482, 0.0277, -0.0283, 0.0258, 0.0077,
		0.0258, -0.0513, 0.0302, -0.0864, -0.0401, 0.0182, -0.0223, -0.0273, -0.0089, -0.0652, -0.0171, 0.0406, -0.0707,
		-0.0859, 0.1226, 0.1078, -0.0196, -0.0084, -0.0181, -0.0181, -0.0421, 0.0359, 0.0045, -0.016, 0.0182, -0.026,
		0.0105, 0.0126, -0.0093, 0.0046, -0.0044, -0.0209, 0.0099, 0.008, 0.0271, 0.0158, 0.0211, -0.0213, -0.022,
		-0.0162, -0.0058, -0.0242, 0.0611, -0.0236, -0.0586, 0.0092, 0.0038, 0.0575, -0.0171, -0.0149, -0.0156, 0.0019,
		-0.0376, -0.0225, -0.0075, -0.0062, -0.0359, -0.0705, 0.0512, -0.0221, 0.0066, -0.0664, -0.0368, 0.058, -0.1403,
		0.0845, -0.0244, 0.0187, -0.0043, -0.006, 0.0641, -0.0159, 0.0167, -0.0243, 0.0678, -0.0152, 0.0222, -0.0173,
		0.011, -0.0173, -0.0192, -0.0423, 0.0018, -0.0054, 0.0727, -0.0643, -0.0471, 0.0661, 0.1061, -0.0363, 0.0326,
		-0.1169, -0.0294, -0.0016, 0.014, 0.0033, 0.0146, -0.0015, -0.006, -0.0411, -0.0374, -0.0028, -0.0374, 0.015,
		-0.0313, -0.0585, -0.0594, -0.0249, 0.0398, -0.0653, 0.013, -0.0225, -0.0044, 0.006, 0.0068, 0.002, -0.0104,
		0.0178, 0.0061, -0.0256, 0.0021, 0.0146, 0.0144, 0.0033, -0.1134, -0.0093, -0.1224, -0.0773, 0.0499, -0.0063,
		-0.03, 0.0097, 0.0319, 0.0041, -0.0173, 0.0029, 0.0042, -0.0266, -0.0057, 0.0028, 0.0325, -0.0578, -0.0892,
		0.1264, -0.1158, -0.2254, 0.0245, -0.0724, -0.2115, 0.0702, -0.2362, -0.1624, 0.0703, 0.0776, -0.0447, -0.1087,
		0.0129, -0.0057, -0.0176, 0.1605, -0.1315, -0.0254, 0.1372, 0.0458, 0.1218, 0.1717, 0.1685, 0.0251, 0.0291,
		0.1406, -0.0209, -0.0054, 0.0351, 0.0019, 0.0354, 0.0043, 0.0033, -0.0108, 0.0234, 0.0071, -0.0163, -0.0304,
		0.0128, 0.0058, 0.0241, 0.0126, 0.0744, -0.099, 0.1046, -0.0537, 0.0276, -0.0844, -0.0343, -0.0482, -0.0265,
		0.003, 0.0111, -0.0142, -0.0248, -0.0059, 0.0156, -0.0161, -0.0195, -0.0198, 0.0066, 0.0072, 0.0025, -0.0101,
		-0.008, -0.0017, 0.016, -0.0143, -0.0101, 0.0133, 0.0147, 0.0113, 0.016, -0.0169, -0.0052, -0.0104, -0.0068,
		-0.0052, -0.0086, 0.0235, 0.0026, 0.0113, -0.0159, -0.006, -0.0075, -0.015, 0.0147, 0.0217, -0.0387, 0.0037,
		-0.0147, 0.009, -0.0033, 0.0163, 0.0204, -0.013, 0.0158, -0.0271, 0.0083, -0.0291, -0.0074, -0.0031, 0.1129,
		-0.0666, -0.0589, 0.0675, 0.0683, -0.1031, -0.069, 0.1097, -0.11, 0.0721, 0.113, 0.0466, -0.1117, -0.0928,
		0.0569, 0.0397, -0.0202, -0.0271, 0.0281, 0.074, -0.0498, 0.0114, -0.0055, 0.0017, -0.0044, 0.0357, 0.0028,
		-0.0509, -0.0511, 0.0299, -0.043, 0.0381, -0.0123, -0.0132, -0.0134, 0.03, 0.0058, -0.0113, 0.0032, 0.0652,
		-0.0467, -0.0861, 0.0543, -0.0362, 0.0438, 0.0082, 0.0008, 0.0672, -0.0255, -0.0071, -0.0208, 0.0345, -0.025,
		-0.0345, -0.0071, -0.0055, -0.0055, -0.0041, -0.0132, -0.0142, -0.0207, -0.0141, 0.0153, -0.0128, 0.0163,
		-0.0102, -0.0223, 0.0006, -0.0205, -0.0073, -0.0076, 0.0203, 0.0021, 0.0076, -0.0326, -0.0456, 0.0032, -0.0274,
		-0.02, 0.0004, 0.0141, 0.0246, -0.0096, -0.0324, -0.0048, -0.011, 0.0081, -0.0118, -0.0373, 0.0015, 0.0083,
		-0.0123, 0.0125, -0.0016, 0.0396, 0.0434, -0.0585, -0.0133, -0.0246, -0.0572, -0.0201, 0.0936, -0.0344, 0.0644,
		-0.002, -0.0535, -0.1074, 0.1035, 0.0646, 0.0281, 0.0073, 0.0006, -0.0186, 0.0057, 0.0278, 0.0348, -0.0125,
		-0.0345, 0.0114, 0.0128, -0.0062, 0.0132, 0.0059, -0.0084, 0.0364, -0.099, 0.0304, 0.0658, 0.0164, -0.0085,
		-0.0209, -0.0024, 0.0101, 0.0026, -0.0076, 0.0045, 0.021, 0.01, -0.0084, 0.0049, -0.0046, 0.0153, -0.045,
		-0.0027, 0.0027, 0.0174, -0.0311, -0.0188, 0.0218, -0.0024, -0.0068, -0.0243, 0.0273, -0.0356, 0.0111, -0.0091,
		0.0128, 0.0224, -0.0135, -0.0014, 0.04, -0.0434, -0.0028, 0.0143, 0.0274, -0.0374, -0.015, 0.0408, -0.0145,
		0.0026, 0.0188, 0.0078, 0.0034, -0.002, 0.0182, 0.0069, -0.009, -0.0207, -0.0143, 0.0016, -0.002, 0.0316,
		0.0431, -0.025, -0.0853, -0.0002, 0.0153, 0.0016, -0.0077, 0.0202, -0.0135, -0.0174, 0.0202, 0.0072, -0.019,
		-0.0485, -0.0504, 0.0304, -0.081, -0.0076, 0.0866, -0.0206, -0.0484, 0.0286, 0.0034, 0.0067, 0.0097, -0.0019,
		0.0252, -0.0444, 0.0341, -0.0098, 0.0285, 0.0346, -0.0109, -0.0116, 0.0147, -0.0223, 0.0042, -0.0678, 0.0708,
		0.061, -0.0151, -0.12, 0, -0.0669, -0.0022, -0.0323, -0.0022, 0.0341, -0.0312, -0.0192, -0.0305, -0.0246,
		0.0939, 0.051, -0.003, -0.0037, -0.028, -0.0185, -0.1069, 0.0562, -0.073, -0.003, -0.0245, 0.0499, 0.0451,
		0.0887, -0.0504, -0.0182, 0.0289, 0.0232, 0.0001, -0.0207, 0.0514, 0.0345, 0.0849, 0.0389, -0.0247, 0.0021,
		0.0034, 0.0152, 0.0099, 0.0328, 0.0226, -0.015, -0.0079, -0.0514, -0.0068, 0.038, 0.0201, 0.0169, -0.0098,
		0.0051, -0.0133, -0.0299, 0.0222, 0.0103, 0.031, 0.0691, 0.1117, -0.0151, 0.0184, -0.0912, -0.026, 0.0662,
		-0.0466, 0.0283, -0.0496, 0.0068, 0.0146, -0.0016, -0.0051, 0.0066, -0.0041, 0.0072, -0.0296, 0.0075, 0.0119,
		0.0047, 0.0076, -0.0056, -0.0219, 0.0088, 0.0228, -0.0037, 0.0032, 0.0029, 0.0043, -0.0207, -0.0072, -0.0063,
		0.0031, 0.0084, 0.0133, 0.0008, 0.012, 0.0262, -0.0176, -0.0138, -0.0094, -0.0026, 0.0073, -0.0068, -0.01,
		-0.0049, 0.0173, 0.0016, -0.0056, 0.0012, 0.0118, 0.0207, -0.0023, -0.0152, 0.007, 0.021, -0.001, 0.0621, 0.047,
		-0.0141, 0.0746, 0.0123, 0.0222, -0.067, 0.0197, 0.0515, 0.0041, 0.0731, 0.0888, 0.0162, -0.0397, 0.017,
		-0.0643, -0.0391, 0.0299, 0.0337, 0.0683, -0.0361, -0.0221, -0.0379, -0.0051, -0.0357, -0.0196, -0.0874, -0.049,
		-0.0208, -0.0212, -0.0742, -0.0368, 0.0155, -0.0556, 0.1013, -0.0164, -0.0955, 0.0366, 0.0492, 0.0964, 0.0601,
		-0.01, 0.0141, 0.0382, 0.0033, 0.0068, -0.0005, 0.0042, -0.046, -0.0119, -0.0537, 0.0261, 0.012, 0.0847,
		-0.0057, -0.1417, 0.1014, -0.0195, 0.0004, 0.0067, -0.0218, 0.0015, -0.0011, -0.0037, 0.0145, -0.0055, 0.0152,
		-0.0179, 0.0295, -0.0107, 0.0161, -0.0088, 0.004, -0.0008, -0.0176, 0.0054, 0.0127, -0.0034, -0.014, 0.0042,
		-0.0157, -0.0024, -0.0212, 0.0242, -0.0063, -0.0056, -0.0128, 0.0051, 0.0107, 0.0081, -0.0087, -0.017, -0.0103,
		-0.0011, -0.0052, 0.0079, 0.0072, -0.0085, 0.0199, -0.0292, -0.0046, 0.001, -0.0032, 0.0152, 0.0151, -0.0072,
		0.013, 0.0178, 0.0137, 0.011, 0.0248, 0.0043, -0.0171, -0.0698, -0.0759, -0.0122, 0.0128, -0.0379, 0.0032,
		-0.0616, -0.015, -0.0146, 0.0044, -0.0144, -0.0734, 0.0652, -0.011, 0.0006, 0.0805, 0.1005, 0.1424, -0.0271,
		0.1822, 0.0324, 0.1412, 0.0541, 0.0572, 0.0031, 0.0073, 0.0866, -0.0045, -0.0696, 0.0939, -0.0762, 0.0518,
		0.0112, -0.0328, 0.0378, -0.0229, 0.0008, -0.022, -0.01, 0.0185, 0.0334, -0.0006, -0.004, -0.0227, -0.0186,
		-0.0032, 0.014, -0.0214, -0.0346, 0.0445, 0.0014, 0.0041, -0.0293, 0.0313, -0.0062, -0.0028, -0.019, -0.0169,
		-0.0067, 0.0064, 0.0093, -0.0009, 0.0096, 0.0221, 0.0234, -0.0132, 0.0097, -0.0118, 0.0559, 0.0224, -0.014,
		0.0285, -0.0643, 0.039, 0.0668, 0.0073, -0.0532, 0.0884, -0.0221, -0.0275, -0.0502, -0.0026, -0.0019, 0.05,
		0.0635, 0.0501, 0.0344, 0.0621, 0.1139, 0.0207, -0.0284, -0.016, -0.0022, -0.0467, 0.0035, 0.0189, -0.0142,
		-0.0259, -0.0004, -0.006, -0.0043, 0.0129, 0.0014, 0.0375, 0.0079, 0.0042, 0.2124, 0.001, 0.0067, -0.0384,
		-0.0315, 0.0193, -0.0053, 0.0252, -0.0893, 0.0231, 0.0082, -0.0007, 0.0421, -0.0156, 0.0117, 0.0199, -0.0149,
		-0.0054, -0.0201, -0.0109, 0.0304, -0.0263, -0.0362, -0.047, -0.0064, 0.0101, -0.0111, 0.0042, 0.0152, 0.0029,
		0.0197, -0.042, -0.0085, 0.0022, 0.039, -0.0334, -0.0343, 0.0032, 0.0359, -0.0037, -0.1497, -0.0377, -0.2315,
		-0.0541, 0.0152, -0.0473, 0.0017, -0.0622, -0.0407, -0.0406, -0.0186, -0.0436, -0.0392, -0.0307, -0.0842,
		-0.0286, 0.0088, -0.0135, 0.0252, 0.0046, 0.0305, -0.0811, 0.0013, 0.0391, -0.002, -0.08, -0.0009, -0.0117,
		-0.0244, 0.0034, 0.0002, 0.0193, -0.0129, 0.0155, -0.0119, 0.0139, 0.0053, -0.005, 0.0043, 0.0068, -0.0001,
		-0.0049, -0.0197, -0.0492, 0.1406, 0.0418, -0.1861, -0.0461, 0.2017, -0.2744, -0.0105, 0.0789, -0.0578, -0.0149,
		-0.0022, -0.0128, 0.027, -0.0123, -0.0097, -0.0115, -0.0084, 0.0044, 0.0248, -0.0463, 0.0063, -0.0013, -0.001,
		-0.011, 0.0256, 0.0474, -0.0634, 0.0467, -0.0001, 0.0584, 0.023, 0.0346, 0.0118, 0.0088, 0.0023, -0.0045,
		0.0104, -0.0078, 0.0523, 0.0671, -0.1393, 0.2274, -0.0985, -0.0807, -0.2313, -0.152, -0.0954, 0.0568, 0.015,
		0.006, 0.0262, -0.0116, -0.0244, 0.027, -0.0267, -0.0123, 0.0271, -0.0197, -0.0169, 0.0132, 0.0556, -0.0106,
		-0.0339, 0.0603, 0.0583, -0.1022, -0.0733, 0.1094, -0.0283, 0.0288, -0.0856, -0.0497, -0.0133, 0.0122, 0.0103,
		0.0172, -0.0052, 0.0128, 0.0082, -0.0023, -0.0085, 0.0034, -0.0111, -0.0134, -0.0103, -0.0023, -0.0062, 0.014,
		0.0174, 0.0038, -0.0167, 0.0072, -0.007, -0.0064, 0.0433, -0.0159, 0.0053, -0.0072, -0.0132, -0.0086, -0.0359,
		-0.0186, -0.0089, -0.0052, 0.0057, 0.0075, 0.0116, 0.0031, -0.0031, -0.0001, 0.019, 0.0091, 0.0195, 0.0299,
		0.0157, 0.025, -0.0398, -0.0573, -0.0366, 0.0009, 0.0759, 0.0192, -0.0402, 0.1032, -0.0031, -0.0302, 0.0022,
		0.0165, 0.001, -0.0075, -0.0051, 0.011, 0.0019, 0.0025, 0.0022, 0.003, 0.0097, -0.0029, -0.0111, 0.0085, 0.0086,
		-0.0003, -0.0235, 0.0471, -0.0196, 0.0585, -0.01, 0.0271, 0.014, 0.0488, -0.0106, -0.0125, -0.0162, 0.0004,
		-0.0188, -0.0022, 0.011, 0.0052, 0.0146, 0.0209, 0.0392, -0.0743, -0.023, 0.0056, 0.0288, -0.1402, 0.1589,
		-0.0439, 0.0328, 0.0086, -0.0528, -0.0264, -0.006, -0.0087, -0.0662, 0.0015, 0.0081, -0.0002, -0.01, 0.0172,
		-0.0139, 0.0101, -0.0071, -0.0027, 0.0061, -0.0041, 0.0172, -0.0188, 0.0052, 0.0299, -0.0208, -0.0019, -0.0004,
		-0.0121, -0.0009, 0.0243, -0.0154, -0.0027, 0.0072, 0.0037, -0.0021, 0.0201, 0.0133, -0.0102, -0.0022, -0.006,
		0.0133, 0.0273, -0.0189, -0.0018, -0.0193, 0.0157, 0.0477, -0.0299, 0.0241, -0.077, 0.0764, -0.2144, 0.0905,
		-0.055, -0.0196, -0.0392, 0.0742, 0.0749, -0.0616, 0.0805, -0.0025, 0.0008, -0.0117, -0.0112, 0.0354, 0.0089,
		-0.0007, -0.065, -0.024, 0.0493, -0.0568, 0.0442, -0.0144, 0.0182, -0.0066, 0.0207, -0.03, 0.0046, 0.0297,
		0.0679, 0.0528, -0.0389, -0.1522, -0.0089, -0.0978, 0.0283, 0.1556, 0.0793, 0.1573, 0.1396, -0.02, -0.0469,
		-0.1142, 0.0499, 0.0002, 0.0589, 0.0138, -0.0127, 0.0019, 0.0208, 0.0413, 0.0151, 0.0233, 0.0021, -0.0425,
		-0.0112, 0.0363, 0.0147, -0.0074, -0.0335, 0.0007, -0.0271, -0.0082, -0.007, -0.0105, 0.0003, 0.0754, -0.1126,
		0.1307, -0.0723, 0.0024, 0.0173, -0.1151, -0.0414, 0.1408, -0.0179, 0.0228, -0.0207, -0.0032, -0.0149, 0.013,
		0.0183, 0.0286, -0.0028, -0.0081, 0.0462, 0.0087, -0.0067, -0.0004, -0.0235, 0.016, 0.0651, -0.0779, -0.0024,
		-0.0434, 0.0483, -0.0187, 0.0467, 0.0184, 0.0085, 0.019, 0.0219, 0.005, 0.0236, 0.0584, 0.035, -0.0529, 0.0367,
		-0.0204, -0.0052, 0.0155, 0.0273, -0.0348, 0.047, 0.0007, -0.0175, -0.0184, 0.0032, -0.0093, -0.0133, -0.0083,
		-0.0005, 0.0109, -0.0005, -0.0186, -0.0129, 0.047, 0.0033, -0.0025, 0.0217, -0.09, 0.0818, 0.0165, -0.0283,
		-0.0462, -0.046, -0.0222, 0.0065, -0.0139, 0.0115, -0.0195, 0.034, -0.0282, 0.0126, -0.004, -0.0024, -0.0259,
		0.0196, -0.0116, -0.0271, -0.0172, -0.0033, 0.0207, 0.0221, 0.025, -0.0265, -0.0415, -0.0047, 0.0043, 0.0091,
		0.0029, 0.0026, 0.006, -0.0158, -0.0081, 0.0166, 0.046, -0.0505, 0.0117, -0.003, -0.0085, -0.0178, 0.0139,
		0.0321, 0.0003, -0.0218, 0.007, 0.0096, -0.0186, 0.0029, -0.0246, 0.016, 0.0193, 0.0105, -0.0275, 0.0038,
		0.0129, 0.0025, 0.0196, 0.0565, 0.0351, -0.0966, 0.0036, 0.003, -0.0661, -0.0015, 0.1689, 0.0233, -0.0648,
		0.0101, 0.1033, -0.1205, 0.2115, -0.0114, -0.0452, -0.0708, -0.0968, 0.047, 0.0732, 0.021, 0.0018, 0.0107,
		0.0055, -0.0438, 0.0107, 0.0134, 0.0014, -0.028, 0.0228, 0.0149, 0.022, 0.0215, -0.0087, -0.0171, -0.0099, 0.01,
		-0.0313, 0.0025, -0.0152, -0.0783, 0.0129, -0.0449, 0.0652, 0.0146, -0.0584, -0.0544, -0.007, -0.0167, 0.0455,
		-0.0316, 0.0395, -0.037, -0.0771, 0.0015, -0.0249, -0.0244, -0.0132, -0.0067, 0.0347, -0.0128, -0.0016, 0.0542,
		0.0317, 0.0473, -0.0001, -0.0553, 0.0224, 0.0123, 0.0062, -0.0319, -0.0185, -0.0023, -0.0579, 0.0366, 0.0307,
		0.048, 0.078, -0.1449, -0.0728, -0.0709, -0.0881, -0.0707, -0.1362, -0.0339, -0.0012, -0.0014, 0.03, 0.0176,
		0.0504, 0.0058, 0.0047, -0.0187, -0.0169, 0.0145, -0.0027, -0.0298, 0.0162, 0.0478, -0.0076, -0.1143, 0.0634,
		-0.0421, -0.0965, 0.0255, 0.0007, -0.0014, -0.0334, 0.0038, 0.0279, 0.0675, -0.0138, 0.0317, -0.0726, -0.048,
		-0.0567, -0.0436, 0.012, -0.01, 0.0101, -0.0109, 0.0054, -0.0043, -0.0073, -0.0078, -0.0427, -0.0135, 0.0294,
		-0.0202, 0.0656, 0.0045, -0.0121, -0.0054, 0.0265, 0.018, 0.0179, -0.0132, 0.0026, 0.0012, -0.0039, 0.0077,
		0.0022, 0.0137, 0, 0.009, -0.0118, -0.0233, 0.0035, 0.0048, 0.0097, -0.0022, 0.0207, -0.0082, -0.0041, 0.033,
		0.0151, -0.0042, -0.0333, 0.0408, -0.0177, 0.0185, -0.0196, 0.0021, -0.002, 0.0127, 0.0227, -0.0008, -0.0048,
		-0.0004, -0.0029, 0.0188, 0.0336, -0.0068, -0.0228, -0.0128, 0.0312, 0.0204, -0.0275, 0.0714, -0.0275, -0.061,
		0.0079, 0.0161, 0.0021, 0.0128, -0.0024, 0.0046, -0.0036, 0.0654, 0.1704, -0.0299, -0.0068, -0.0046, 0.0708,
		0.0286, 0.0541, 0.0408, -0.0051, 0, -0.0238, 0.0152, -0.0011, -0.0436, -0.0567, -0.0143, -0.0053, -0.0959,
		0.0183, 0.0456, -0.0744, -0.018, 0.0014, 0.0262, -0.0018, -0.0126, 0.0107, 0.0028, 0.0105, -0.0077, 0.0141,
		0.0223, -0.0381, -0.0701, 0.0338, 0.0148, -0.0479, 0.0843, -0.0815, 0.092, -0.062, -0.0094, -0.0274, -0.0598,
		0.0241, 0.0404, -0.1113, 0.0223, 0.0003, 0.0214, -0.0012, 0.0133, 0.017, -0.0065, 0.0019, 0.0034, 0.0059,
		-0.006, -0.0276, -0.0074, 0.0229, -0.04, -0.0304, -0.1465, -0.0154, -0.0976, 0.0277, -0.0047, -0.0237, -0.0022,
		-0.0051, 0.0126, 0.0035, -0.0243, -0.024, 0.0087, -0.0081, 0.0028, 0.011, 0.0113, -0.0024, -0.0219, 0.0004,
		-0.0013, 0.0065, 0.0089, 0.0367, -0.059, 0.0961, -0.0225, -0.0268, 0.0561, -0.0303, 0.0098, 0.0628, 0.0068,
		-0.0185, -0.0002, -0.0214, -0.0925, -0.0512, 0.0775, 0.0388, -0.1237, -0.0823, 0.1137, -0.1171, 0.1017, -0.1912,
		-0.051, -0.0494, -0.0934, -0.0035, 0.0335, -0.0424, 0.0417, 0.0024, 0.0066, 0.0161, -0.0109, -0.0102, 0.0077,
		-0.0119, 0.0296, 0.0033, 0.0147, 0.0493, 0.0255, -0.0221, 0.097, -0.031, 0.0307, 0.0077, -0.0098, -0.0166,
		-0.0048, 0.008, 0.0088, -0.0118, 0.0041, 0.0023, 0.0027, 0.019, 0.0112, -0.0051, -0.0035, -0.0121, -0.011,
		-0.0142, -0.0381, -0.032, 0.1296, -0.2074, 0.0833, 0.0141, -0.171, 0.0866, 0.0856, -0.0398, -0.0439, 0.0135,
		-0.0028, -0.014, -0.0029, 0.0004, 0.0077, 0.019, -0.0295, -0.0014, -0.0149, -0.0049, 0.0283, -0.0179, 0.0168,
		-0.015, -0.0086, 0.0098, 0.0396, 0.0091, -0.0119, 0.0101, 0.0576, -0.0124, -0.0393, 0.0602, -0.0479, 0.015,
		0.0114, -0.0111, -0.0463, 0.0065, 0.0246, -0.0288, 0.0392, 0.0092, 0.0378, 0.0089, -0.0048, -0.0009, -0.0874,
		0.0045, 0.0066, 0.0335, 0.0249, -0.035, 0.0098, 0.0114, 0.0169, 0.026, -0.0311, -0.0227, 0.0255, 0.0145, 0.032,
		0.0156, 0.0319, -0.045, 0.2155, 0.007, 0.0679, -0.0636, -0.0835, -0.0087, 0.0269, 0.0668, 0.0601, -0.0131,
		0.032, 0.0076, -0.0065, 0.0051, -0.025, 0.0055, -0.0264, 0.0136, -0.0007, -0.0486, -0.0151, 0.0257, -0.0082,
		0.0352, -0.0055, -0.0024, -0.0005, -0.0154, -0.0052, -0.0248, -0.0326, 0.0362, -0.014, -0.0039, 0.0196, 0.0342,
		0.0083, 0.0032, 0.0069, -0.0002, 0.0161, 0.0009, -0.0081, 0.0024, -0.0014, -0.0003, 0.0257, -0.0052, 0.0281,
		-0.0119, -0.0108, -0.0183, -0.0039, -0.0131, 0.0155, 0.0071, -0.0105, 0.0101, 0.0118, 0.0041, -0.0108, -0.007,
		-0.0272, -0.0028, 0.0215, -0.0208, -0.003, -0.0117, -0.002, 0.0016, 0.0196, 0.0151, 0.0232, -0.0021, -0.0076,
		0.0059, -0.0023, 0.0308, -0.0051, -0.0204, 0.0106, 0.0048, -0.0293, 0.0031, 0.0259, 0.0157, -0.006, 0.013,
		0.0392, -0.0221, -0.0084, -0.027, -0.002, -0.0515, -0.0321, 0.0941, -0.0399, 0.0321, 0.013, 0.0026, -0.0125,
		0.0058, -0.0049, 0.1197, -0.0321, 0.1207, -0.0408, -0.0188, 0.0743, -0.084, 0.0344, -0.0129, -0.0271, -0.0074,
		0.0062, 0.0082, -0.0153, -0.0356, 0.0052, 0.0367, 0.022, 0.0144, -0.0175, 0.0291, 0.0419, -0.0034, 0.0243,
		0.006, 0.0265, -0.0048, 0.0183, 0.0178, 0.0206, -0.0333, -0.0128, 0.0221, 0.0058, 0.0021, 0.016, 0.0001, 0.0245,
		-0.0068, 0.0133, 0.0187, 0.0031, -0.001, 0.0399, 0.0019, -0.0059, -0.0031, -0.0072, -0.0329, 0.0295, 0.0081,
		-0.0502, -0.0149, 0.0229, 0.0724, -0.0302, -0.098, 0.0885, 0.1384, 0.1128, 0.0365, -0.0552, -0.0078, -0.0041,
		0.0184, -0.0065, -0.0028, -0.0103, 0.0172, 0.0253, 0.0351, 0.0157, 0.0085, 0.0011, -0.0129, -0.0073, 0.0198,
		0.006, 0.0017, 0.0023, -0.0091, 0.0288, 0.0826, -0.1284, 0.0194, -0.0574, -0.1022, -0.0578, 0.0018, -0.0002,
		-0.0494, -0.0017, 0.0007, -0.0263, 0.0204, 0.0224, 0.0258, 0.0337, -0.0233, -0.0432, -0.0954, -0.0852, 0.0743,
		-0.0283, 0.0384, -0.0579, 0.0133, -0.016, -0.0093, -0.0153, -0.0049, 0.0209, 0.0011, -0.0133, 0.0045, 0.0135,
		-0.0143, 0.0145, 0.0345, -0.0101, -0.0063, 0.0249, 0.0299, -0.0087, -0.0061, -0.0131, 0.0047, 0.0137, -0.0031,
		0.0074, 0.0025, -0.0038, -0.0227, -0.0092, 0.0022, -0.0092, 0.0049, -0.0084, -0.0308, -0.0131, 0.0054, 0.0184,
		-0.0033, 0.0032, 0.025, -0.0107, -0.0088, -0.0249, 0.01, -0.0169, 0.0104, 0.0189, -0.0177, 0.0047, -0.0042,
		-0.0015, -0.0276, -0.0051, -0.0275, 0.0317, -0.0164, -0.0726, -0.0229, 0.065, 0.1425, 0.0193, -0.1133, -0.2067,
		-0.0025, 0.111, 0.0625, 0.0782, 0.0723, 0.026, 0.0013, 0.0547, 0.0269, -0.0009, 0, -0.0244, -0.015, 0.022,
		0.0314, 0, -0.0011, -0.0075, 0.0156, -0.0227, -0.0055, -0.0235, 0.151, -0.0355, 0.0423, 0.0361, -0.0025, 0.0167,
		0.0006, -0.0104, 0.0119, 0.007, 0.01, 0.0264, 0.0056, 0.0047, 0.0399, -0.0786, 0.0876, -0.0361, -0.0312,
		-0.0592, 0.0045, -0.0153, -0.0071, -0.0115, -0.0003, 0.007, -0.0111, -0.0091, -0.0517, -0.0575, 0.0875, -0.0814,
		-0.0028, 0.0071, -0.0292, 0.0112, 0.0107, -0.0052, 0.0174, -0.0035, 0.0144, -0.0014, 0.0088, -0.0402, -0.0053,
		0.0847, -0.0686, 0.0356, -0.0188, -0.0115, 0.0028, 0.0106, 0.034, -0.0258, 0.0232, -0.0102, 0.0238, 0.0031,
		0.0141, -0.0172, -0.0224, 0.083, -0.0428, 0.1289, -0.1275, 0.0297, -0.1123, 0.0125, 0.1133, -0.144, -0.1486,
		-0.0585, 0.1254, 0.0018, -0.0101, -0.0457, 0.0083, 0.0719, -0.0109, -0.0095, -0.005, 0.024, -0.018, -0.0052,
		-0.0102, 0.0129, -0.0339, 0.0124, -0.0107, 0.041, -0.0602, -0.1199, -0.077, -0.0578, 0.0022, -0.0544, -0.1079,
		0.0071, -0.0133, 0.0539, 0.0362, 0.0065, -0.0287, -0.0395, -0.0294, -0.0073, -0.0066, -0.0059, 0.0225, 0.0086,
		-0.018, 0.0189, 0.0106, 0.0138, 0.0084, 0.0032, -0.0066, -0.0098, 0.0125, 0, 0.037, -0.0217, -0.019, 0.0142,
		0.003, -0.0314, 0.0095, 0.0155, -0.0595, 0.0287, -0.0026, -0.0609, 0.0095, 0.0164, 0.0223, -0.1371, 0.0014,
		0.0937, 0.0033, -0.0342, 0.0041, -0.0299, 0.0053, 0.0029, 0.0082, -0.0346, 0.005, 0.0183, -0.0092, 0.0008,
		0.0242, -0.0121, 0.0196, 0.0118, -0.0404, -0.0162, 0.0408, -0.0127, 0.0596, 0.2295, -0.0202, -0.0572, -0.1919,
		0.0977, 0.0596, -0.0132, 0.0467, 0.0009, -0.0792, 0.0571, -0.0901, 0.051, 0.0314, 0.0031, -0.0044, 0.0086,
		-0.0178, 0.0099, 0.0041, -0.0037, -0.0055, -0.0058, 0.0086, -0.0176, -0.0041, 0.0216, -0.0304, 0.0076, -0.0102,
		-0.011, 0.0126, 0.0687, -0.0615, 0.0674, -0.0261, -0.046, 0.0025, -0.0315, -0.0044, 0.019, -0.0039, -0.012,
		-0.0516, 0.0145, 0.0038, 0.0294, -0.1074, -0.1075, 0.1895, 0.114, 0.0293, 0.0075, -0.1195, 0.0179, -0.0087,
		0.051, 0.0576, -0.0787, -0.017, 0.0586, -0.0607, 0.136, 0.0717, -0.0625, -0.0128, -0.0758, 0.093, 0.1289,
		-0.0488, -0.0154, -0.0528, -0.0077, -0.0106, -0.0144, -0.0296, 0.0157, -0.014, 0.0025, 0.0238, 0.0031, 0.0358,
		-0.0075, -0.0074, -0.0022, -0.0072, 0.009, -0.0382, 0.0065, 0.0297, 0.0184, -0.0123, -0.005, -0.0151, 0.0264,
		0.0004, -0.0338, -0.0016, 0.0156, -0.0233, -0.0003, 0.0074, 0.0242, 0.026, 0.0255, 0.0032, 0.0773, 0.0652,
		-0.07, 0.016, -0.0176, 0.0415, -0.0205, -0.0174, -0.0197, 0.0029, 0.0187, 0.0191, 0.0182, -0.013, -0.0164,
		0.0053, 0.0029, 0.0187, -0.0128, 0.0461, 0.011, -0.0498, 0.0162, -0.0911, -0.0889, -0.0229, -0.0078, 0.0125,
		-0.035, -0.0075, 0.0326, -0.0074, -0.0076, -0.0123, 0.003, 0.0177, 0.0207, 0.0003, 0.0028, -0.0197, 0.0205,
		0.0087, 0.0121, 0.0055, -0.0078, 0.0168, 0.0103, -0.0055, -0.0178, 0.0072, -0.0148, 0.0118, 0.0093, 0.0119,
		0.0181, -0.0089, 0.0089, -0.0918, 0.0378, -0.0563, 0.1082, -0.0935, 0.1161, 0.0364, -0.0962, 0.0265, -0.0096,
		0.0029, 0.0073, -0.0038, 0.0061, -0.0013, 0.0015, 0.019, -0.0076, -0.0071, -0.0006, 0.0265, -0.0115, 0.0173,
		0.0116, 0.0059, -0.0019, 0.0078, -0.0103, -0.0026, 0.0112, -0.0214, -0.0136, 0.0139, 0.004, -0.0033, -0.0059,
		0.0098, -0.0091, -0.0115, -0.0045, -0.0028, 0.0142, 0.0002, 0.0031, 0.0148, 0.0114, -0.0016, -0.0014, -0.0069,
		0.0106, 0.0201, -0.0076, -0.0234, 0.0226, -0.0339, 0.0006, 0.0029, -0.0382, 0.0068, 0.0383, 0.0097, 0.0195,
		0.0235, 0.0284, -0.0718, 0.0111, -0.0363, 0.0322, -0.0279, 0.0098, -0.0117, -0.0002, 0.0019, 0.0106, -0.0062,
		0.0464, 0.013, -0.0213, 0.0231, 0.0193, 0.0467, -0.197, 0.1279, 0.0478, -0.1043, 0.0162, 0.1015, 0.023, 0.1444,
		0.1557, 0.0558, 0.0061, -0.0042, -0.0085, 0.0041, 0.0083, 0.0096, -0.0058, 0.0062, 0.0212, -0.0245, 0.0206,
		0.0026, -0.117, -0.0931, 0.0695, 0.1049, -0.0717, -0.0439, 0.1283, 0.2037, 0.025, -0.0743, 0.0828, -0.0111,
		0.0114, -0.0569, -0.0621, -0.0031, 0.0097, 0.0168, -0.0013, -0.0054, -0.0161, 0.007, -0.0004, 0.0072, 0.0055,
		0.0003, 0.0244, 0.0061, 0.0266, 0.0093, -0.0625, 0.0003, 0.0007, -0.0063, 0.0115, 0.0232, -0.0198, -0.0074,
		0.0188, -0.0111, 0.0014, 0.0046, -0.0134, 0.0105, 0.0018, -0.0159, 0.0305, -0.002, 0.0158, 0.0069, 0.0106,
		0.0027, -0.0079, 0.0218, 0.0424, -0.0107, 0.0147, -0.0281, -0.0088, 0.0316, -0.1108, 0.0727, 0.0602, 0.0566,
		0.0144, -0.0004, -0.0107, 0.0058, -0.0096, -0.001, 0.0617, 0.0234, -0.0672, 0.048, 0.0345, 0.0309, 0.045,
		0.0189, -0.0231, -0.0425, 0.0023, 0.0055, 0.0185, -0.0015, -0.0082, 0.0388, -0.0101, 0.0072, -0.0115, 0.0169,
		-0.0251, 0.0012, -0.0097, -0.005, -0.0258, 0.0361, 0.0364, -0.0079, 0.0014, 0.0384, -0.0066, -0.0029, 0.0522,
		0.0025, 0.052, -0.0067, 0.0742, -0.118, 0.0189, -0.0147, -0.0689, 0.0505, 0.0319, -0.0034, -0.0086, 0.0024,
		-0.0031, 0.0077, 0.0196, 0.0042, 0.0334, 0.0085, 0.0259, -0.0263, 0.0032, 0.0074, -0.0056, 0.0018, 0.0024,
		-0.0039, -0.008, -0.0317, -0.0021, -0.0028, -0.0454, -0.0141, -0.0062, -0.0009, 0.019, -0.0039, 0.0198, 0.0179,
		-0.024, 0.0633, 0.0424, -0.0087, -0.0077, -0.0414, -0.0079, -0.003, 0.0111, 0.0012, 0.0145, -0.027, -0.0146,
		-0.0041, -0.0163, 0.019, 0.0269, -0.0052, -0.0046, -0.0046, -0.0099, 0.0036, -0.0061, 0.0129, -0.0051, -0.0215,
		-0.0202, 0.0042, 0.0036, 0.0192, -0.0163, -0.0115, -0.03, -0.0197, -0.0369, -0.0055, -0.0078, 0.0209, -0.0049,
		0.0303, 0.0474, -0.0062, -0.0005, -0.018, 0.0058, -0.0545, -0.0011, 0.0245, -0.0151, -0.0906, -0.0589, -0.1105,
		-0.032, -0.105, -0.0063, 0.0542, 0.0163, -0.0046, 0.0038, 0.0095, -0.0182, -0.0248, -0.0046, -0.0002, -0.0052,
		0.0166, 0.0076, 0.04, 0.0373, 0.0309, 0.0332, -0.0082, 0.0105, -0.0072, -0.0247, -0.0033, 0.0249, 0.0052,
		0.0081, 0.0088, -0.0471, -0.0131, 0.0177, -0.0278, 0.0259, -0.0334, -0.0586, 0.0073, -0.0024, -0.0011, 0.0252,
		-0.0136, -0.0119, 0.0034, 0.0149, 0.03, -0.0298, 0.0181, -0.0057, -0.0122, 0.0071, -0.0011, -0.01, -0.0212,
		-0.0739, -0.003, -0.0275, -0.005, 0.0167, 0.0426, -0.0028, 0.0277, 0.0183, -0.0505, -0.0109, 0.0215, 0.016,
		-0.006, -0.0045, -0.0268, -0.0069, -0.0019, 0.0108, 0.0152, 0.014, -0.0049, -0.0147, -0.005, 0.0007, -0.0009,
		0.0045, 0.029, 0.0053, 0.0016, 0.0197, -0.0216, 0.0079, 0.0063, -0.0162, -0.0252, -0.0085, 0.0234, -0.0186,
		-0.0058, 0.001, -0.0161, -0.031, 0.03, -0.0442, 0.0288, -0.0275, -0.0033, 0.012, 0.0149, -0.0056, -0.0058,
		-0.0069, 0.0024, 0.0097, -0.0157, 0.0248, 0.0051, -0.0043, 0.0024, 0.0098, 0.0005, -0.0014, -0.0084, -0.0061,
		0.0088, -0.0357, -0.0017, 0.0043, 0.0446, -0.0358, 0.0079, -0.014, 0.0018, -0.042, 0.012, -0.0047, 0.0161,
		-0.0108, -0.0016, 0.0015, -0.0075, 0.0307, 0.0113, -0.0107, 0.0024, 0.0174, 0.0108, -0.0068, -0.0028, -0.0055,
		-0.037, 0.0047, 0.0561, 0.0192, -0.013, -0.0011, 0.004, 0.0517, -0.0323, -0.0048, 0.042, 0.0193, -0.0143,
		0.0285, 0.0173, -0.0115, -0.0491, 0.0378, -0.0364, -0.0675, -0.1157, -0.0004, 0.0208, 0.0271, 0.0322, 0.0007,
		0.0193, -0.0166, 0.0126, -0.0055, -0.0187, 0.0107, -0.0236, 0.0333, 0.0419, 0.0123, -0.0375, 0.0207, -0.003,
		-0.048, -0.0146, 0.0038, 0.0089, -0.0084, 0.0104, -0.0118, -0.0024, 0.0222, -0.0021, 0.0009, 0.0159, -0.0227,
		-0.0159, -0.0007, -0.007, -0.0286, 0.0122, 0.0234, -0.0019, -0.031, -0.0016, 0.0482, -0.0085, -0.0154, -0.0138,
		-0.0254, -0.0092, 0.0028, -0.0022, -0.0037, -0.0084, 0.0538, -0.0167, -0.0174, 0.0115, 0.0184, -0.015, 0.0036,
		-0.0584, -0.0029, -0.0025, -0.001, 0.0153, -0.0013, -0.0153, 0.0057, 0.0009, -0.0081, -0.0214, -0.0061, 0.0224,
		0.0205, -0.0018, -0.044, 0.0036, 0.0159, -0.0139, -0.0218, -0.0315, 0.052, 0.0561, -0.0835, -0.0154, -0.0174,
		0.0092, 0.0032, 0.0161, 0.0218, 0.0181, -0.0785, 0.0328, -0.0001, -0.001, -0.0204, -0.0003, 0.0095, -0.0252,
		-0.0283, 0.0199, 0.0084, 0.0425, 0.0193, -0.0101, 0.0109, 0.0035, -0.0304, -0.0004, 0.0288, 0.0086, -0.0131,
		-0.0027, 0.0046, 0.0026, 0.0008, -0.0227, -0.0408, -0.0897, -0.0084, -0.0555, 0.0794, 0.0212, 0.0085, -0.0266,
		0.0067, -0.0164, -0.0225, 0.0066, -0.0374, 0.0266, 0.0153, -0.0074, -0.0005, -0.0294, -0.0051, 0.0323, 0.0179,
		0.0093, 0.0098, 0.014, -0.0007, -0.01, -0.0241, -0.0064, 0.0232, 0.0082, -0.0245, -0.0185, 0.013, 0.021, 0.0279,
		-0.002, -0.0133, -0.0085, -0.0066, 0.0307, 0.0068, 0.0826, 0.0509, 0.0572, -0.0316, -0.0092, 0.0318, -0.0105,
		0.0229, -0.0141, -0.0071, 0.0054, 0.0136, -0.0081, -0.0556, -0.0078, -0.0052, 0.0056, -0.022, -0.009, 0.0516,
		0.0263, -0.0054, 0.0313, 0.0378, -0.0006, 0.0251, 0.0187, -0.0059, 0.0618, 0.0334, -0.0038, 0.0493, 0.0484,
		-0.0197, 0.023, 0.0063, 0.0065, 0.009, 0.0213, -0.0013, 0.0404, -0.0321, -0.0197, 0.0119, -0.0516, -0.0622,
		0.0294, 0.0932, 0.1087, -0.0105, -0.0647, 0.0408, 0.0363, 0.0114, -0.0145, -0.0007, 0.023, -0.0035, -0.0428,
		0.0018, 0.0105, -0.0451, -0.0364, 0.0426, 0.0003, 0.0063, -0.0086, 0.0277, -0.0299, -0.0439, 0.04, -0.0647,
		0.035, -0.0087, 0.0837, 0.0178, 0.0182, -0.0157, -0.0156, -0.0089, -0.0027, -0.0275, 0.0157, -0.0088, -0.0229,
		-0.0116, 0.0426, -0.0269, -0.0248, -0.0108, 0.0684, -0.0048, -0.0248, 0.0231, 0.0142, -0.0386, -0.0187, -0.0124,
		-0.0279, 0.0357, -0.0071, -0.0357, 0.0085, 0.0032, -0.0014, 0.0081, 0.0045, -0.0085, 0.0323, -0.0124, 0.0232,
		0.0193, -0.0307, 0.0071, -0.0119, -0.0298, -0.0609, 0.0867, 0.0606, -0.0755, 0.0398, -0.0016, 0.0324, -0.0003,
		-0.0184, 0.0204, -0.0205, -0.0077, -0.0007, 0.0055, -0.0096, -0.0118, -0.0001, -0.0231, -0.0073, -0.0226, 0.025,
		0.0016, 0.0237, 0.0163, 0.0136, 0.0106, -0.0047, -0.0142, -0.0067, -0.0104, 0.0197, -0.0126, 0.0279, -0.0571,
		0.0057, -0.006, 0.0075, 0.0141, -0.0357, -0.0453, 0.0125, 0.0053, 0.0292, -0.0126, -0.0023, -0.0012, -0.0768,
		-0.0286, -0.0357, 0.0054, -0.0287, 0.058, -0.033, 0.0291, 0.0013, -0.0493, -0.016, 0.0193, 0.0442, 0.0009,
		-0.0228, 0.0195, -0.036, -0.011, 0.0081, 0.0062, -0.0017, -0.0034, 0.0108, 0, 0.0102, 0.0008, 0.0073, 0.0171,
		-0.0466, -0.0016, 0.0181, 0.0434, 0.0029, -0.0333, 0.02, 0.0024, 0.008, 0.0328, 0.0057, -0.0146, -0.0219,
		0.0023, 0.0219, -0.0077, 0.0282, -0.0067, -0.027, 0.0428, -0.0122, -0.0052, -0.0141, -0.0342, -0.0028, 0.058,
		-0.0126, 0.0168, 0.0395, 0.0113, 0.0208, -0.0225, -0.0291, -0.0058, 0.0168, 0.0196, 0.0138, -0.0241, -0.0024,
		-0.0074, -0.0232, 0.0384, 0.0316, -0.0192, 0.0247, -0.0181, -0.0216, -0.0134, 0.0074, -0.0214, -0.0152, 0.0193,
		-0.0083, -0.004, -0.0332, -0.0067, -0.0001, -0.0093, 0.0274, 0.0046, -0.0142, -0.0091, 0.0044, -0.0293, 0.038,
		-0.0112, -0.0248, -0.0297, 0.0162, 0.0182, 0.052, 0.0084, 0.0065, 0.0202, 0.0089, -0.0105, 0.011, 0.0216,
		0.0244, 0.0248, 0.0085, 0.0184, -0.0169, -0.006, -0.0141, 0.012, -0.0136, 0.03, -0.0184, 0.0278, -0.0118,
		-0.003, -0.0143, -0.0134, 0.0051, -0.0012, -0.0138, 0.0222, 0.0026, -0.0172, 0.0061, 0.009, -0.0005, -0.0043,
		-0.0056, -0.0109, -0.0022, 0.0063, 0.0213, -0.0142, 0.0002, -0.0081, 0.0117, 0.0214, -0.0194, 0.0016, -0.0002,
		-0.0318, -0.0161, 0.011, 0.0139, 0.0144, 0.0025, 0.0069, 0.0579, -0.0155, -0.0208, 0.0348, 0.003, -0.0013,
		0.0099, -0.0234, 0.0081, 0.0167, 0.001, -0.0197, 0.0064, 0.0026, 0.0373, -0.0316, -0.0134, 0.0034, 0.0079,
		0.0145, 0.0179, -0.0106, 0.0105, 0.0012, -0.0127, -0.0094, 0.0331, 0.0007, -0.023, 0.0125, 0.0014, -0.0022,
		0.0028, 0.026, -0.0036, 0.0086, -0.0223, -0.0395, 0.023, -0.0006, -0.0787, 0.0428, -0.0425, -0.0329, -0.0025,
		0.0021, 0.0097, -0.0026, -0.0107, 0.0017, -0.0038, 0.0251, 0.0039, -0.0194, -0.0123, -0.0077, 0.0054, 0.0122,
		-0.017, 0.0389, -0.0284, -0.0134, 0.0078, -0.0272, -0.0197, -0.0143, -0.0006, -0.006, 0.0133, 0.0032, 0.0033,
		0.0153, -0.0181, -0.0158, -0.0084, -0.0039, -0.0166, -0.0131, -0.0058, -0.0156, 0.0156, 0.0233, -0.0035,
		-0.0284, -0.0232, -0.0021, 0.017, 0.0123, -0.0252, 0.0183, -0.0113, 0.0038, -0.0085, 0.001, 0.0381, 0.035,
		-0.0564, -0.031, -0.01, 0.0435, -0.0841, -0.0912, -0.0478, 0.0001, 0.0099, -0.0459, 0.0334, 0.0259, -0.0155,
		0.0196, -0.0112, 0.016, -0.036, 0.0138, 0.0562, 0.0079, -0.0325, 0.0273, -0.0141, -0.0153, -0.0207, 0.0084,
		-0.0084, -0.0163, 0.0284, 0.0092, 0.0227, -0.0323, 0.0025, 0.0018, -0.0164, -0.0022, 0.0153, 0.0188, -0.0309,
		0.0147, 0.0239, -0.0082, 0.0053, -0.0268, -0.008, -0.0169, 0.0578, 0.0001, 0.0058, -0.0272, 0.0494, -0.0218,
		-0.0223, -0.0215, -0.0232, -0.0303, 0.0003, -0.0328, 0, 0.0372, -0.0583, 0.0202, 0.015, 0.0563, 0.0202, 0.0041,
		-0.0204, 0.0126, -0.0093, 0.0285, 0.0292, 0.009, -0.0145, -0.0121, -0.0059, 0.0198, 0.0006, -0.0032, 0.0129,
		0.0154, 0.0266, -0.0083, 0.0029, 0.0123, 0.0003, 0.0069, 0.0413, 0.0105, 0.0174, 0.0064, 0.0317, -0.0372,
		0.0056, -0.0167, -0.0111, -0.0176, 0.0228, 0.063, 0.0735, -0.0029, 0.0086, -0.0081, -0.0161, 0.0101, 0.0115,
		0.0176, 0.0035, -0.0298, 0.0166, 0.023, 0.0341, 0.0022, -0.018, 0.0646, -0.0259, 0.0277, -0.0637, -0.0247,
		0.0036, -0.0054, 0.0525, 0.0071, 0.0169, 0.0245, 0.0019, -0.024, -0.041, 0.0361, 0.04, -0.055, -0.0359, -0.0478,
		-0.0545, -0.0149, 0.0548, -0.0334, -0.0175, 0.0195, -0.0091, 0.0381, 0.0998, 0.0762, 0.0949, 0.094, 0.0966,
		-0.0479, 0.0137, -0.031, 0.0691, -0.0233, 0.0015, 0.0006, 0.0029, -0.0086, -0.0172, 0.0322, 0.0181, 0.0054,
		-0.0068, 0.0246, 0.0115, -0.024, -0.0148, -0.0069, 0.0052, 0.0267, -0.013, 0.006, 0.0427, -0.0015, 0.0507,
		0.0148, 0.0167, -0.0132, 0.0418, -0.0415, -0.0626, 0.0039, 0.0393, 0.0106, 0.0164, -0.0152, -0.0079, 0.0112,
		0.0029, 0.0126, -0.0179, -0.0026, 0.0115, 0.0142, -0.0193, -0.0061, -0.0345, -0.0011, 0.0061, -0.006, -0.0221,
		-0.0068, -0.0315, 0.006, -0.009, -0.0208, 0.029, 0.0338, 0.0161, 0.0075, -0.0535, 0.0553, -0.0667, -0.0349,
		-0.0382, 0.0205, -0.0218, -0.0111, 0.0029, 0.021, -0.0033, -0.0032, 0.0096, 0.0053, 0.0096, -0.0149, -0.0361,
		-0.0002, 0.0127, 0.0153, -0.0209, -0.0121, 0.0016, -0.0082, -0.0068, 0.0265, -0.0233, 0.0049, -0.0046, -0.0052,
		-0.0033, -0.0075, -0.0001, -0.0151, 0.029, -0.0803, -0.0428, 0.0044, 0.0394, -0.0551, 0.033, -0.0417, -0.0192,
		0.0251, -0.0402, -0.0051, 0.0201, -0.0039, 0.0353, 0.0062, -0.0545, 0.0338, 0.0076, -0.0276, -0.0326, 0.038,
		0.0228, 0.0244, 0.0157, 0.0157, -0.0353, -0.0508, 0.0209, -0.044, -0.0244, -0.0007, -0.0223, -0.018, -0.0223,
		0.0231, -0.0282, -0.001, -0.0004, -0.0053, -0.0084, -0.0239, -0.0189, 0.0275, 0.0028, 0.0014, 0.0019, -0.0169,
		-0.0084, 0.007, 0.0055, 0.0205, 0.0256, 0.0379, -0.0254, -0.0142, 0.0149, -0.0001, -0.0418, -0.016, 0.0118,
		-0.0039, 0.0016, 0.0051, 0.0253, -0.0171, -0.0115, 0.0299, 0.0042, -0.0187, 0.0908, -0.1639, 0.0861, -0.0513,
		-0.0178, 0.0486, 0.0691, -0.0073, 0.0021, -0.0147, 0.0609, 0.0487, -0.0131, -0.0479, 0.0134, 0.0408, -0.0244,
		0.0209, 0.0075, -0.0115, -0.0333, 0.0002, 0.0317, -0.045, -0.03, 0.0195, -0.0412, 0.0578, -0.0114, 0.0231,
		0.0052, 0.0221, 0.0169, 0.0215, -0.026, 0.0015, -0.0036, -0.0015, -0.0081, 0.0101, 0.0017, -0.0234, -0.0264,
		0.0242, 0.0117, -0.0307, 0.016, -0.0007, -0.037, 0.0678, -0.0287, 0.0332, 0.0379, -0.0107, 0.0455, 0.0065,
		-0.0015, -0.0384, 0.0615, 0.0144, -0.0074, 0.0175, 0.0071, 0.0167, 0.0075, 0.0046, 0.0032, -0.0012, 0.0159,
		0.0143, 0.0188, 0.0026, -0.011, -0.0208, -0.0147, -0.0163, 0.0053, -0.0168, -0.0007, 0.0024, -0.0102, 0.0062,
		-0.0128, 0.0038, 0.0182, -0.0074, 0.0108, 0.0051, 0.0189, 0.0045, -0.0019, -0.0151, 0.019, 0.0242, 0.0023,
		-0.0483, 0.0203, 0.0512, 0.0364, -0.0211, -0.0027, 0.0709, -0.0142, 0.0526, -0.0188, -0.0177, 0.0339, 0.0066,
		0.0536, -0.0671, -0.0675, 0.044, -0.0559, -0.0092, -0.0005, -0.0081, 0.0053, -0.0061, 0.0151, -0.0015, 0.0066,
		-0.0027, -0.0085, 0.0003, 0.0095, -0.0534, -0.027, -0.0099, -0.0189, -0.0224, -0.0138, 0.0243, -0.0279, 0.013,
		0.0124, -0.0056, -0.0091, -0.0423, 0.0386, 0.0308, 0.0013, -0.0043, -0.0152, 0.0088, -0.0063, -0.0265, -0.0091,
		-0.062, 0.0408, -0.0305, 0.0191, -0.0499, 0.048, -0.0289, -0.0266, 0.0541, -0.0122, 0.0025, -0.0589, -0.027,
		-0.0532, -0.0228, -0.0128, -0.0582, -0.0562, -0.0207, -0.0097, 0.0036, 0.0095, -0.0266, -0.0024, -0.0179,
		-0.0131, -0.0311, 0.0188, 0.0153, -0.0017, -0.0351, 0.0829, -0.0516, -0.0036, 0.045, -0.0329, -0.0184, 0.0525,
		-0.0197, 0.0023, 0.0147, 0.0336, 0.0009, 0.0031, 0.0066, 0.0144, 0.0383, 0.0339, 0.0204, 0.0123, 0.0355, 0.0212,
		0.0279, 0.0197, -0.017, 0.0603, 0.0168, -0.0269, -0.0029, -0.022, -0.0079, -0.0114, 0.0041, -0.0455, -0.0137,
		0.0123, 0.0063, -0.0012, 0.0043, 0.0297, 0.0397, -0.0033, -0.0116, 0.0094, 0.0088, -0.0219, -0.0168, 0.0009,
		0.0072, -0.0075, 0.0016, 0.021, 0.0244, -0.0197, 0.0294, 0.0198, -0.0092, 0.0094, -0.0112, 0.0398, -0.0001,
		-0.0004, 0.0522, -0.0158, 0.0015, -0.0066, -0.0274, 0.0042, 0.0093, -0.0421, 0.0151, -0.0381, -0.0528, 0.0329,
		0.057, 0.045, 0.0766, -0.0588, -0.1105, 0.0294, 0.0694, 0.0136, 0.011, 0.0051, -0.0046, -0.0015, -0.0192,
		-0.0271, 0.0679, 0.059, -0.0069, 0.0897, -0.0375, 0.02, 0.006, -0.0215, -0.071, -0.0861, 0.0252, -0.0547,
		0.1744, -0.1008, -0.0168, 0.0145, -0.1089, -0.0235, 0.0449, -0.0139, -0.01, -0.011, 0.0679, -0.0092, 0.0487,
		-0.0107, -0.0066, 0.0589, 0.0853, -0.0276, 0.0399, 0.0174, -0.0158, -0.0001, -0.015, -0.0295, -0.0041, -0.0096,
		0.006, 0.0149, 0.0114, -0.0281, 0.1245, -0.0411, -0.1011, 0.0348, 0.0775, -0.026, -0.0167, 0.0217, 0.0148,
		0.0073, 0.0062, 0.0157, -0.0116, -0.0175, -0.0296, -0.0149, -0.0009, 0.01, 0.0137, -0.017, -0.0095, -0.0169,
		-0.0053, -0.0073, 0.0007, 0.0045, -0.0018, 0.006, 0.0112, 0.0087, -0.0102, 0.0051, 0.0089, 0.0035, -0.0051,
		0.0127, -0.001, 0.0133, -0.0095, -0.0365, -0.0106, 0.0356, -0.016, -0.0039, -0.0033, -0.0062, 0.0139, -0.0218,
		0.0019, 0.058, -0.0399, 0.0545, -0.1299, 0.0106, -0.0376, -0.0062, -0.1156, 0.0642, -0.1362, 0.063, 0.011,
		-0.004, -0.0181, -0.0111, -0.0165, 0.0173, -0.0049, 0.0055, -0.0124, -0.0083, -0.0085, 0.0192, 0.0008, 0.006,
		-0.001, 0.0187, -0.0262, -0.0057, 0.0083, 0.0786, -0.0043, -0.0564, -0.0001, 0.0651, 0.0895, 0.0736, 0.0607,
		0.0267, 0.0316, 0.0245, 0.0373, 0.0919, -0.0143, 0.0011, 0.0126, 0.0216, 0.0033, -0.009, -0.0173, 0.0029,
		0.0169, -0.0111, 0.0679, 0.005, -0.0267, 0.0563, -0.0017, 0.0163, 0.0876, -0.0097, -0.057, -0.105, -0.0554,
		-0.0736, -0.0495, -0.055, -0.0688, -0.0184, 0.0025, 0.0207, -0.0421, -0.0702, -0.1149, -0.0382, -0.0122, 0.0402,
		0.0418, 0.0289, 0.0633, 0.0091, 0.0098, -0.0309, -0.0552, 0.0276, 0.0159, 0.0925, 0.0051, 0.0387, 0.0235,
		-0.017, 0.0477, 0.0304, 0.0488, -0.0613, -0.0356, -0.0377, -0.0445, 0.0126, -0.0062, -0.0012, 0.0033, 0.0115,
		-0.0065, -0.01, 0.0037, -0.0222, 0.0018, 0.0269, 0.0156, -0.0082, 0.0716, 0.077, -0.0293, 0.0365, -0.1227,
		0.0199, 0.0207, -0.0358, -0.0543, 0.0204, 0.0126, -0.0184, -0.0214, -0.0186, 0.0263, -0.1197, -0.0386, -0.1636,
		-0.1509, -0.1576, 0.2742, 0.0393, -0.0101, -0.1149, 0.0207, -0.0161, -0.0126, -0.0082, 0.003, -0.0148, 0.0046,
		0.0106, -0.0027, 0.0055, 0.0142, 0.0089, -0.04, -0.0084, 0.0539, 0.0825, 0.0625, -0.0653, 0.0462, 0.0697,
		-0.1032, -0.0459, 0.0074, 0.009, 0.0061, 0.0057, -0.0241, 0.0657, -0.0283, -0.0713, 0.0031, 0.0918, -0.0022,
		0.0724, -0.0576, -0.0869, 0.0628, 0.0572, 0.0532, -0.0003, 0.0315, -0.1019, -0.0039, 0.0124, -0.0304, 0.0518,
		-0.0155, -0.0301, -0.0126, 0.0304, 0.0179, -0.025, -0.0194, 0.0152, -0.0952, 0.0099, 0.006, -0.028, 0.0076,
		0.037, 0.0036, 0.056, 0.0337, 0.0082, 0.0984, 0.1611, -0.0439, -0.0503, 0.072, 0.1043, 0.01, -0.0422, 0.0164,
		-0.0168, -0.0389, -0.0412, 0.0475, 0.0372, -0.0778, 0.1288, 0.0794, -0.0588, 0.017, -0.0483, -0.0459, -0.029,
		-0.042, 0.0261, -0.0041, -0.0164, -0.0156, 0.0161, -0.0057, -0.0289, 0.0061, -0.0012, 0.0504, 0.0488, 0.0144,
		-0.0483, 0.0666, 0.089, 0.0401, 0.0198, -0.0259, -0.0383, -0.0741, -0.058, 0.0675, 0.0517, -0.0398, -0.022,
		0.0869, -0.0065, 0.043, 0.0227, 0.0299, 0.0133, 0.0263, 0.0293, 0.0168, 0.0052, -0.0133, 0.0111, 0.0168,
		-0.0085, 0.0158, 0.012, -0.0065, -0.0035, -0.0023, 0.0181, -0.0113, 0.0118, 0.0228, 0.0105, 0.0497, -0.0008,
		0.059, 0.0623, -0.0721, 0.1081, 0.0663, -0.0074, 0.0002, 0.0018, -0.005, -0.0086, 0.0005, -0.001, 0.0101,
		0.0366, 0.007, 0.037, -0.0453, -0.1315, 0.0441, -0.0511, -0.0593, -0.0647, 0.0542, 0.0533, 0.048, 0.0481,
		0.0481, 0.0194, 0.0329, -0.0015, -0.0346, 0.0106, -0.0104, -0.0351, -0.058, -0.0219, -0.0573, -0.0235, -0.0072,
		-0.0019, -0.0385, 0.0283, 0.0073, 0.0051, -0.0005, 0.0481, 0.0397, -0.047, 0.0028, -0.0037, 0.0142, -0.0235,
		-0.0281, 0.019, -0.024, 0.0598, -0.0298, 0.003, -0.0547, 0.0216, -0.0295, -0.0152, -0.027, 0.0024, 0.0333,
		-0.0187, 0.0025, -0.0232, 0.0096, -0.0317, -0.0032, -0.004, 0.0069, 0.0046, 0.0111, -0.0021, 0.0096, -0.0162,
		0.0374, -0.0014, 0.0119, -0.0098, -0.0061, -0.0044, 0.0111, 0.0182, -0.0127, 0.0035, 0.0029, 0.022, 0.0201,
		-0.0011, -0.0196, 0.0139, 0.0134, 0.0058, 0.0037, -0.0161, 0.0072, 0.0131, -0.0295, -0.0231, 0.0172, 0.0189,
		0.0035, 0.005, -0.0325, 0.0112, -0.0146, -0.0123, 0.011, -0.1511, 0.1128, 0.0347, -0.0533, 0.0424, -0.085,
		-0.0869, 0.0876, 0.0379, -0.0356, -0.0058, -0.022, 0.01, 0.0195, 0.0041, 0.0165, 0.0329, -0.044, -0.0879,
		0.0304, 0.0561, -0.1367, 0.0288, -0.0285, 0.0109, -0.057, 0.1033, -0.0108, -0.025, 0.0003, -0.0047, 0.0262,
		0.0018, -0.0353, -0.0067, -0.028, 0.0066, 0.0467, -0.1616, 0.031, 0.0369, 0.0349, 0.0644, -0.0029, 0.0105,
		-0.0191, 0.0333, -0.0316, 0.0104, 0.0152, 0.031, -0.07, 0.045, -0.0555, 0.0443, 0.0744, 0.0153, 0.0187, -0.1032,
		-0.0197, -0.0225, 0.027, 0.0611, -0.0175, 0.0492, -0.0769, -0.0233, 0.0355, 0.0023, 0.0294, 0.0174, 0.0227,
		0.0201, 0.0015, 0.0037, -0.0788, 0.0498, 0.0494, -0.0456, -0.0264, 0.0624, 0.0621, -0.08, 0.0349, -0.0777,
		0.0592, 0.0879, 0.0279, 0.0777, -0.0294, -0.0024, 0.0099, 0.0279, 0.0308, 0.0309, -0.0186, 0.0262, 0.0046,
		0.0034, 0.0126, -0.011, -0.0496, -0.002, 0.0733, 0.0367, -0.0545, 0.0559, 0.0286, 0.0712, -0.0105, -0.0187,
		-0.0127, 0.0156, 0.0034, -0.0146, -0.0124, -0.0097, 0.0161, 0.0146, -0.0046, 0.0012, 0.0413, -0.0176, 0.0053,
		0.0002, 0.0027, -0.0171, -0.0464, 0.0084, -0.0532, 0.0352, -0.0122, 0.007, -0.0081, -0.0046, 0.0048, 0.004,
		0.0145, 0.005, -0.0296, 0.0025, -0.0072, 0.0317, -0.0503, 0.0104, -0.0253, 0.0094, 0.0362, -0.0281, -0.0232,
		0.0182, -0.0515, -0.0303, -0.002, -0.019, 0.012, -0.1272, 0.0391, -0.0218, 0.0611, 0.0792, 0.01, 0.0175, -0.014,
		-0.0166, 0.0009, 0.0085, 0.0001, -0.0091, -0.0085, -0.007, -0.0054, -0.0401, -0.0149, 0.014, 0.0451, -0.0229,
		0.0336, -0.007, -0.0013, -0.015, 0.018, 0.0118, 0.0766, -0.0653, 0.0172, -0.0546, -0.0036, 0.0606, -0.0114,
		-0.0055, 0.0403, 0.0923, -0.0363, -0.0486, 0.038, 0.0357, 0.0539, -0.0157, -0.0426, -0.0084, 0.0159, 0.0061,
		-0.0295, 0.0168, -0.0138, -0.0112, -0.0109, 0.0012, 0.0341, 0.0193, -0.0125, -0.0249, -0.0316, -0.0042, -0.042,
		0.0239, 0.0449, 0.0381, -0.0638, -0.0381, 0.0842, -0.0156, 0.0118, -0.0257, 0.0605, 0.037, -0.0288, -0.0662,
		-0.0193, 0.0437, 0.0013, -0.0477, -0.0024, -0.0049, -0.0213, -0.0363, -0.0459, 0.0127, 0.0126, 0.0113, 0.0065,
		-0.0454, 0.0589, -0.0732, 0.0919, -0.0395, 0.0306, -0.023, 0.0193, 0.0125, 0.0236, -0.0066, 0.0132, -0.0066,
		0.0133, -0.058, 0.0105, -0.0003, -0.0041, 0.0077, -0.022, 0, 0.0009, -0.0082, -0.0004, -0.005, -0.0234, 0.0021,
		-0.0031, 0.005, 0.0128, -0.0283, -0.0637, 0.0395, -0.0981, -0.0215, 0.0723, -0.0605, 0.0531, -0.0138, 0.0028,
		0.0116, -0.0261, -0.0384, -0.0064, -0.0243, 0.0025, -0.0172, 0.003, 0.0182, -0.0038, 0.0197, 0.024, -0.0094,
		-0.0205, 0.003, 0.0324, 0.0008, 0.0045, -0.0176, -0.0028, 0.0132, -0.0039, -0.0217, 0.0007, 0.0147, -0.0285,
		0.0058, -0.0046, 0.0191, 0.0414, -0.0608, 0.0581, 0.048, 0.0461, -0.0563, 0.0118, -0.0505, -0.0196, -0.056,
		-0.0525, -0.0724, -0.0548, -0.019, -0.0536, -0.0682, -0.0527, -0.0283, -0.0252, -0.0129, -0.03, -0.0436, 0.0324,
		-0.0399, -0.0112, -0.0503, 0.0476, 0.0477, 0.0164, -0.0286, -0.0947, 0.1464, -0.0737, -0.0103, -0.0497, -0.0015,
		0.0014, -0.005, 0.0252, 0.0013, 0.0102, -0.0199, 0.0073, 0.0137, -0.0141, 0.0118, -0.0196, -0.0174, -0.0287,
		0.0343, -0.0219, -0.0077, 0.0087, -0.0438, 0.0121, 0.0289, -0.0392, -0.0155, 0.0025, 0.0136, 0.0151, -0.0017,
		-0.0063, 0.0246, 0.009, 0.0035, 0.0288, 0.0052, -0.0193, 0.0119, 0.0061, -0.0154, 0.0056, 0.0164, 0.003,
		-0.0018, 0.021, 0.016, 0.0071, -0.0331, -0.0246, 0.0097, -0.0262, 0.0096, 0.011, -0.0335, -0.0048, -0.0167,
		-0.019, 0.0015, -0.0047, 0.0118, 0.001, 0.0022, -0.0116, -0.0053, -0.0244, -0.0046, 0.0231, -0.0057, -0.0145,
		0.0009, -0.0075, -0.0099, -0.012, 0.0158, -0.0212, 0.0004, -0.0025, -0.0086, -0.0051, 0.0007, 0.0199, -0.0054,
		-0.0153, 0.0099, 0.0108, -0.0007, -0.0129, 0.0165, -0.0348, -0.0242, -0.0476, -0.0126, 0.0232, 0.0131, -0.0227,
		0.01, 0.0049, 0.0209, 0.0044, -0.0049, 0.0023, 0.0023, 0.0029, 0.0094, 0.0294, -0.0249, -0.0139, 0.0083,
		-0.0072, -0.0052, -0.0035, 0.0039, 0.011, -0.0238, -0.0008, -0.0275, -0.0133, -0.0164, -0.002, 0.0101, 0.0401,
		0.0234, 0.007, 0.0143, -0.0097, 0.0272, -0.0291, 0.0033, 0.0055, 0.0091, 0.0243, 0.0134, -0.0066, -0.0012,
		-0.0342, -0.0132, 0.0036, -0.0252, 0.0226, -0.005, 0.0008, 0.0053, -0.012, 0.0103, -0.0356, 0.059, -0.0083,
		0.0828, 0.0413, 0.0352, -0.0307, 0.0007, -0.0444, -0.1806, 0.0276, -0.0656, -0.1541, 0.0191, -0.0129, -0.0628,
		0.0354, 0.038, -0.0302, -0.0134, -0.0065, 0.021, -0.0037, 0.0285, -0.0029, -0.0076, -0.0173, 0.0162, -0.0124,
		0.018, 0.0088, -0.0024, 0.0077, -0.0247, 0.0358, -0.0285, -0.0257, -0.0039, 0.0028, 0.0265, -0.0088, -0.0045,
		-0.0029, 0.0134, -0.0017, -0.0092, 0.0662, 0.047, 0.0608, -0.0514, 0.0395, 0.0114, -0.0171, -0.0341, -0.0255,
		-0.021, 0.0428, -0.0051, -0.0085, 0.0214, 0.0029, 0.021, 0.0018, -0.0173, -0.006, 0.0026, -0.0133, -0.0228,
		-0.0038, 0.0144, 0.081, 0.0533, -0.0599, -0.1394, 0.0334, -0.0215, -0.01, 0.0607, -0.0142, 0.0893, 0.0449,
		0.0946, -0.0213, -0.0153, -0.0447, -0.0359, -0.0493, 0.0183, -0.006, 0.1101, 0.0423, 0.0401, -0.0003, -0.0222,
		0.0208, 0.03, 0.0102, -0.004, -0.0083, 0.0073, -0.0105, -0.0157, 0.0323, -0.0221, 0.0263, 0.0105, 0.0232,
		0.0369, 0.0725, 0.1493, 0.118, -0.1291, -0.0742, 0.0535, 0.0357, 0.0567, -0.0484, -0.1301, 0.1656, 0.096,
		-0.0399, 0.0007, 0.0026, 0.0068, -0.007, 0.0115, 0.0115, -0.0083, 0.0008, 0.0074, -0.0262, -0.0159, 0.0343,
		-0.0152, 0.0019, 0.1079, -0.0312, -0.0044, -0.0099, -0.0624, -0.016, -0.0064, 0.0068, -0.0032, 0.0079, -0.0204,
		-0.0348, -0.0197, 0.0144, 0.021, -0.0021, 0.0196, 0.0017, 0.0383, -0.0179, 0.0203, 0.0059, -0.0093, 0.03,
		-0.0089, -0.0072, 0.0062, 0.0208, -0.001, -0.015, -0.0459, 0.0458, 0.0444, 0.0671, 0.0175, -0.0353, 0.0523,
		0.0799, -0.0046, 0.0146, -0.0008, -0.065, 0.0758, 0.0572, 0.0364, 0.0346, 0.0029, 0.0295, 0.0161, 0.0164,
		0.0038, 0.037, 0.0375, -0.029, 0.0129, -0.0142, -0.0066, 0.031, -0.0038, 0.0046, -0.0094, 0.0157, 0.04, -0.0052,
		0.0295, 0.04, 0.0099, -0.0156, -0.0338, 0.0159, 0.022, 0.01, -0.0267, 0.0373, 0.0108, 0.0372, -0.0097, -0.0121,
		-0.0825, -0.0257, -0.0883, -0.0785, -0.1788, -0.0597, -0.0559, -0.0362, 0.0248, -0.0434, -0.004, -0.0078,
		-0.0018, 0.0247, 0.0141, -0.0317, -0.0513, -0.0639, 0.0177, 0.0568, 0.0502, 0.0471, 0.0359, 0.1022, 0.0425,
		-0.0705, 0.026, -0.0293, -0.0628, -0.0332, 0.0145, 0.083, 0.0251, 0.0016, -0.074, -0.0201, 0.0077, -0.0336,
		-0.0117, -0.0253, 0.0097, 0.0208, -0.0003, 0.0115, 0.0114, 0.0024, 0.0167, -0.0487, 0.0662, 0.0216, 0.0271,
		-0.0025, -0.0387, 0.0235, 0.0009, -0.0044, 0.0117, 0.0195, -0.0092, 0.0371, -0.0247, -0.0204, -0.0108, 0.0013,
		0.0284, 0.0103, 0.0169, -0.0184, -0.0317, 0.023, -0.0664, -0.038, -0.0136, -0.0431, 0.0068, -0.0423, -0.0502,
		0.0138, 0.0379, -0.071, -0.0176, -0.0422, -0.0081, -0.0034, -0.0067, -0.0103, 0.0238, -0.0192, 0.0295, -0.0057,
		0.0012, -0.0017, 0.0308, -0.0172, 0.0206, 0.0475, 0.065, -0.0807, 0.1002, 0.1176, -0.0552, 0.0802, -0.1077,
		-0.0466, 0.0084, -0.1277, 0.032, -0.0155, -0.0301, -0.0216, -0.0047, -0.0242, -0.0121, 0.0197, 0.0043, -0.0209,
		0.0159, 0.0096, -0.0058, -0.0076, 0.0064, 0.0382, -0.013, 0.0304, -0.0768, 0.0893, -0.0594, 0.0158, 0.0628,
		-0.021, -0.0284, -0.0763, 0.0113, -0.0048, 0.0052, 0.0006, -0.013, -0.014, -0.0052, -0.0057, 0.01, -0.0094,
		-0.0256, 0.02, 0.0107, 0.0071, 0.0062, -0.0089, -0.0089, 0.022, 0.0194, -0.0123, -0.0012, -0.012, 0.0045,
		-0.003, 0.0139, -0.0115, 0.0251, 0.0037, -0.0025, -0.006, -0.0003, -0.0047, -0.0016, 0.0178, 0.0246, 0.0086,
		-0.0257, 0.0075, -0.0102, -0.0291, -0.0382, -0.0109, -0.0362, 0.0051, -0.0209, -0.0121, 0.0014, -0.035, 0.0022,
		0.0612, 0.0111, 0.0298, 0.008, -0.0039, -0.0206, -0.0123, 0.0001, 0.0152, 0.0002, -0.0166, 0.0202, 0.0071,
		0.0356, 0.0114, 0.0579, 0.0009, 0.0305, -0.0707, -0.0343, -0.0177, 0.0053, 0.0176, -0.0245, -0.0542, 0.032,
		-0.0243, 0.0377, -0.0346, 0.0524, 0.04, 0.0144, 0.0359, 0.0052, 0.0313, -0.0079, 0.0255, -0.0121, 0.0049,
		0.0755, 0.0428, -0.0285, 0.0929, -0.0196, 0.0886, -0.005, 0.0339, 0.031, -0.0016, -0.0098, -0.0268, 0.0193,
		0.0357, 0.0297, 0.0184, -0.0152, 0.0295, -0.0075, -0.0118, -0.008, -0.0168, -0.0075, -0.0418, -0.0186, -0.0233,
		0.0215, 0.0056, -0.0103, 0.001, 0.0023, 0.0168, 0.0045, 0.0081, -0.038, 0.0221, 0.048, 0.0222, -0.0271, 0.0066,
		-0.0121, -0.03, 0.0169, 0.0158, -0.0368, 0.042, -0.0049, -0.0362, -0.0112, -0.0281, 0.0162, 0.0852, 0.0549,
		0.0857, -0.0377, -0.0381, -0.0239, 0.0424, -0.036, -0.0155, 0.0064, 0.003, 0.0042, 0.0054, -0.0303, 0.0123,
		-0.0045, -0.0218, 0.005, -0.0139, -0.009, 0.0096, 0.007, 0.0053, 0.0156, -0.007, -0.0037, -0.0039, -0.0215,
		-0.0005, 0.0025, 0.0114, -0.0059, -0.0161, 0.0164, 0.0053, -0.0007, 0.0114, 0.009, -0.0011, -0.0198, 0.0302,
		0.0063, -0.0286, -0.0057, -0.0275, 0.0169, 0.0169, -0.0099, -0.063, 0.009, -0.0131, -0.0039, -0.0028, -0.0172,
		-0.0067, 0.0334, -0.0321, -0.1, 0.026, -0.0172, 0.0016, 0.0123, -0.0123, 0.0217, 0.0048, -0.021, -0.0457,
		0.0765, -0.0209, -0.0677, -0.032, 0.0211, 0.0353, 0.0436, -0.0099, -0.0296, 0.0135, 0.0016, 0.0517, 0.0095,
		0.0291, 0.0132, 0.0348, 0.0163, -0.0237, 0.0232, -0.0817, -0.0631, -0.0117, -0.0616, 0.0401, 0.015, -0.0225,
		0.0292, -0.0028, -0.0203, -0.0344, 0.0085, -0.0212, -0.0111, 0.0137, 0.003, -0.0065, 0.0121, 0.0216, 0.0021,
		0.046, 0.0478, 0.0339, 0.0249, 0.048, -0.0077, 0.0283, 0.0124, -0.0075, -0.0117, 0.0172, 0.0112, -0.0131,
		-0.0023, 0.0305, -0.0199, -0.0174, 0.0086, 0.0151, 0.0081, -0.0039, 0.0247, -0.0126, 0.0247, 0.0008, -0.005,
		0.0045, -0.0161, -0.0075, 0.0478, 0.0183, 0.0321, 0.039, 0.0082, 0.0391, 0.0214, -0.0165, 0.0023, -0.0044,
		-0.0221, 0.0206, 0.0202, 0.011, 0.0093, 0.0076, 0.015, -0.0201, -0.016, 0.0263, 0.0246, -0.0157, -0.0279,
		0.0353, -0.0368, 0.0203, 0.0207, -0.0245, -0.0071, -0.0008, -0.0022, -0.003, 0.0053, 0.0302, -0.0483, 0.0619,
		-0.0547, 0.0507, 0.0766, 0.0094, 0.017, 0.0018, -0.0559, -0.1653, -0.0281, 0.0082, -0.021, 0.0599, -0.0086,
		0.0173, -0.021, 0.0271, -0.0101, 0.0277, 0.0005, 0.0174, -0.0045, 0.0151, 0.0026, -0.0105, 0.0715, -0.0634,
		-0.0289, 0.1201, -0.0426, -0.1659, -0.1674, 0.3038, -0.1626, -0.0545, 0.1516, 0.0713, -0.169, 0.0602, -0.0829,
		0.1028, 0.0079, -0.0859, -0.1549, -0.1792, -0.0865, -0.0239, -0.1024, 0.054, 0.078, 0.0391, -0.0406, -0.0782,
		0.0394, 0.2081, 0.1046, -0.017, 0.0281, 0.0096, -0.1807, 0.0207, -0.0828, 0.0945, 0.0466, -0.0667, 0.0264,
		-0.0019, 0.0826, -0.0722, 0.1112, -0.116, -0.0265, -0.1818, -0.0033, 0.2317, -0.1121, -0.0238, 0.1314, 0.0878,
		-0.1311, 0.0135, 0.1367, 0.0224, -0.1096, 0.1088, -0.1502, -0.0819, 0.1661, -0.0349, 0.293, -0.0107, 0.0718,
		-0.1046, -0.0639, 0.1285, 0.0126, -0.0558, -0.0685, 0.0059, -0.0273, 0.2341, -0.0062, -0.0622, -0.0756, 0.214,
		0.0891, 0.1672, -0.0698, -0.0815, 0.0032, 0.0559, 0.0098, -0.0855, -0.0203, -0.0483, -0.0152, 0.172, -0.0105,
		-0.0483, 0.0584, -0.0558, 0.1194, -0.0653, -0.0597, -0.1033, 0.034, -0.0295, -0.0331, -0.0735, 0.023, 0.0205,
		-0.1018, 0.0307, 0.0341, 0.0046, -0.0036, 0.0095, 0.029, -0.0147, 0.0439, -0.0373, -0.0065, 0.081, -0.0005,
		-0.027, -0.0172, -0.0081, -0.0138, -0.0108, 0.0276, 0.0304, 0.0303, -0.0586, 0.0126, -0.0072, 0.0006, 0.0283,
		-0.0172, 0.0359, -0.0095, 0.0287, 0.0303, 0.0174, -0.0059, 0.0254, 0.1256, 0.0546, -0.0202, 0.0176, -0.0299,
		0.0061, 0.0094, 0.0103, 0.0466, -0.1267, 0.0316, -0.0439, -0.0568, 0.0056, 0.2044, 0.2565, 0.1057, 0.1496,
		0.0912, 0.0724, 0.0523, 0.0896, 0.0211, 0.0268, -0.0724, -0.0202, -0.0608, -0.0191, -0.0274, 0.0135, -0.0288,
		0.1074, -0.1717, -0.0639, 0.0011, 0.1994, 0.1215, 0.1896, 0.0951, -0.1326, 0.0394, -0.0327, 0.1736, -0.1371,
		0.0614, -0.1246, 0.2455, -0.0548, 0.1921, -0.1749, 0.0374, -0.0644, -0.0606, 0.0098, -0.1529, 0.2387, 0.0579,
		-0.1264, 0.1182, -0.0876, 0.012, 0.0446, -0.057, -0.0026, 0.0049, 0.0017
	]
]

export const peak2 = [
	[
		0, 0.0044, -0.0026, 0.0046, 0.0037, -0.0005, 0.0339, 0.0056, -0.0374, -0.0167, -0.0067, -0.024, -0.0036, 0.0256,
		-0.0059, 0.0337, -0.1131, 0.0217, 0.0461, 0.0644, 0.1336, 0.1276, 0.066, 0.021, -0.0219, -0.0252, 0.0447,
		0.0164, 0.0138, -0.1701, 0.1216, 0.0492, -0.1015, 0.1025, -0.0196, 0.0245, -0.0377, -0.0327, 0.0803, 0.046,
		-0.1122, 0.0581, 0.0098, 0.0153, -0.0318, -0.0668, -0.1048, -0.1698, -0.0731, 0.0116, -0.0053, 0.055, 0.0346,
		-0.0329, -0.01, 0.0289, 0.0022, 0.0283, -0.0465, -0.013, -0.0145, 0.0106, -0.0178, 0.0698, -0.0415, 0.0018,
		0.0265, -0.0494, 0.0866, -0.021, -0.0212, -0.0031, -0.0362, 0.0236, 0.0057, -0.0357, 0.0448, -0.0965, 0.0182,
		-0.0107, 0.0441, 0.0235, 0.0302, 0.017, 0.0107, 0.0265, 0.0116, -0.0037, -0.0078, -0.0015, 0.0193, -0.0035,
		0.035, 0.0591, -0.136, 0.3011, -0.1835, 0.1974, -0.2605, 0.0629, 0.0286, 0.036, 0.098, -0.1811, 0.2359, 0.0077,
		-0.0506, -0.1112, 0.0117, -0.0138, 0.0024, 0.0086, 0.0021, -0.0073, -0.0059, 0.0055, -0.0283, 0.0064, 0.0131,
		-0.0085, 0.0038, 0.0008, -0.027, 0.019, 0.0821, 0.1201, -0.0191, -0.0253, 0.0211, 0.0099, 0.0229, -0.0881,
		0.0404, -0.0217, 0.0552, -0.08, -0.0092, 0.0585, -0.1534, -0.069, -0.0155, 0.0775, 0.0466, -0.1356, -0.2074,
		0.0858, 0.422, 0.072, 0.1699, -0.0817, 0.0721, 0.0279, 0.0077, 0.0119, -0.0125, 0.0003, 0.001, -0.0078, 0.0016,
		0.0278, 0.2398, 0.2399, -0.1024, 0.2185, -0.1112, -0.0706, -0.0472, -0.1034, 0.1265, -0.1353, -0.1469, -0.0373,
		-0.0192, -0.031, 0.0234, 0.006, -0.006, 0.0001, 0.0083, 0.0028, 0.0018, -0.0006, 0.0107, -0.0143, -0.0022,
		0.0077, -0.0033, 0.0099, -0.0108, -0.0019, -0.0234, 0.0112, 0.0021, 0.0087, 0.0021, 0.0079, -0.0049, 0.0014,
		0.004, 0.0055, -0.0007, 0.004, -0.0017, 0.0143, -0.0015, 0.0089, 0.0096, -0.0141, -0.0086, -0.029, 0.0039,
		-0.004, 0.0063, -0.0234, 0.0086, 0.006, -0.0224, 0.0036, -0.0014, 0.0063, -0.0005, 0.0036, -0.0051, -0.0074,
		-0.0091, 0.0035, -0.001, 0.0037, 0.0136, -0.0003, -0.0012, 0.0016, -0.0081, -0.0084, -0.0056, 0.0044, 0.0012,
		0.0107, 0.0025, -0.0097, -0.0129, -0.0042, -0.0105, -0.0068, -0.004, 0.0084, -0.0053, -0.0156, -0.0122, -0.0001,
		0.0086, -0.0826, 0.0986, -0.1832, -0.1408, -0.0549, 0.0167, 0.0769, 0.0012, -0.0449, -0.002, 0.0009, 0.0205,
		-0.0121, -0.2441, -0.3757, -0.0311, -0.1474, 0.0261, -0.0814, 0.1792, 0.0474, 0.0709, 0.0666, 0.01, -0.0212,
		-0.0002, 0.0129, -0.0355, -0.0835, -0.1241, -0.0967, -0.0218, 0.0867, 0.0249, -0.0115, 0.0063, -0.0159, -0.1788,
		0.0873, 0.0605, -0.1265, 0.1604, -0.0138, -0.1145, 0.073, 0.2258, 0.1189, -0.0546, 0.0683, 0.2137, -0.0932,
		0.0194, 0.0178, -0.0142, 0.0134, 0.0144, 0.0241, -0.1034, -0.0471, 0.0572, 0.1717, -0.0603, -0.028, 0.1451,
		-0.0998, 0.0855, -0.0588, -0.0694, -0.0484, 0.1168, 0.0068, 0.0001, -0.031, -0.0119, 0.0151, 0.0098, -0.0038,
		0.0329, 0.1033, 0.0383, 0.0194, -0.0665, -0.0309, 0.1093, 0.1311, -0.0316, -0.0503, -0.0133, 0.0331, -0.1029,
		0.0082, -0.0294, -0.1257, -0.054, 0.0077, 0.0763, 0.1838, -0.0155, -0.1081, 0.0344, 0.0493, -0.1292, -0.0378,
		0.0851, -0.1244, 0.0177, 0.0141, 0.2085, -0.0804, -0.0426, -0.1541, 0.1002, 0.2774, -0.1814, -0.1448, -0.2319,
		0.1671, 0.0064, -0.038, -0.057, 0.0408, -0.1376, 0.0214, 0.0054, -0.114, 0.0135, -0.0492, 0.0135, 0.0263,
		0.0224, 0.0177, 0.0178, -0.0768, 0.0173, -0.0004, 0.0517, 0.1876, 0.0208, -0.0285, -0.0011, 0.0117, -0.0072,
		0.0017, -0.0168, 0.0023, 0.0195, 0.0025, 0.0034, -0.0098, -0.0035, -0.0069, 0.0034, -0.0062, 0.005, -0.0069,
		0.0196, 0.0093, 0.1378, 0.0479, 0.02, 0.003, -0.1312, 0.3176, -0.064, -0.2828, -0.0616, 0.0736, -0.1314,
		-0.0073, -0.1876, 0.01, 0.1923, 0.04, 0.1888, 0.0652, -0.0873, 0.0905, 0.0743, -0.1619, -0.1897, 0.1868,
		-0.0618, -0.0335, 0.076, -0.1097, -0.0237, 0.0245, -0.0309, 0.0395, -0.0038, 0.0276, -0.0034, -0.0128, -0.0133,
		-0.0012, 0.0169, 0.0115, 0.0045, -0.0014, 0.0117, 0.0379, -0.0004, -0.0544, -0.0255, 0.0191, -0.0136, -0.0243,
		-0.01, 0.0205, -0.0083, -0.0052, -0.0049, 0.0086, 0.0085, -0.0037, -0.0051, -0.0628, 0.0505, -0.1789, 0.0336,
		0.0701, 0.0223, 0.0339, 0.0076, -0.0052, 0.0656, -0.0548, -0.0158, 0.0152, 0.1121, 0.0987, 0.2053, 0.1608,
		-0.0304, -0.0776, -0.1598, 0.0793, -0.2166, 0.1574, 0.1341, -0.0536, 0.0464, 0.0754, 0.0198, 0.0043, -0.0051,
		-0.0089, -0.0131, 0.0053, -0.0019, -0.01, 0.0158, -0.0211, 0.0102, 0.0134, -0.0005, -0.104, 0.0646, 0.1845,
		0.2551, 0.1579, 0.0537, -0.0446, 0.0133, 0.0455, -0.072, 0.0196, 0.0004, -0.0146, -0.0002, -0.0026, 0.0121,
		0.0084, 0.0002, 0.0011, -0.0196, 0.0192, 0.079, 0.0922, 0.0798, 0.0498, -0.0046, 0.2625, 0.1717, -0.1568,
		0.0809, 0.2375, 0.2582, 0.1014, -0.1206, -0.2058, -0.0042, -0.168, -0.2415, -0.2859, -0.0672, -0.057, -0.196,
		-0.0295, 0.0115, -0.1291, -0.0471, 0.0237, -0.019, 0.0476, 0.0151, -0.0486, -0.0314, 0.0179, 0.0332, 0.0146,
		-0.0032, -0.01, 0.0017, 0.0119, -0.0059, -0.0047, -0.0108, -0.0033, -0.0008, 0.0149, -0.0012, 0.0039, -0.0071,
		0.0061, 0.0083, -0.0147, 0.0039, -0.0003, 0.0017, 0.0047, 0.0077, 0.0005, 0.0056, 0.0132, 0.0011, 0.0067,
		-0.0013, 0.0026, -0.006, 0.0004, 0.0039, 0.0107, 0.0049, -0.0064, -0.0125, -0.0047, 0.0059, 0.003, 0.0007,
		0.0037, -0.0108, 0.0062, -0.0097, -0.0026, -0.0065, -0.0064, 0.004, -0.006, -0.0104, 0.012, 0.0032, 0.0091,
		-0.0013, 0.0128, 0.0088, -0.0152, -0.0116, -0.0122, -0.0043, 0.0237, -0.0078, -0.0068, 0.0159, -0.0054, -0.0065,
		0.0017, -0.0023, -0.0039, -0.0008, 0.0032, 0.0053, -0.0067, 0.0025, -0.0033, 0.0089, -0.0059, 0.0036, -0.0027,
		0.006, 0.0054, -0.0732, 0.0942, 0.0396, 0.0103, 0.2426, 0.1543, -0.1204, -0.1356, 0.0092, 0.1289, -0.0195,
		0.1247, 0.0105, -0.0792, 0.0246, 0.0347, -0.0012, 0.0308, -0.0223, 0.0533, -0.13, 0.026, -0.0337, 0.0468,
		-0.0251, 0.009, -0.0285, 0.0419, 0.0212, 0.0088, 0.0061, 0.0382, 0.0018, 0.0142, 0.0193, 0.0618, -0.0581,
		-0.0322, -0.0169, -0.0161, -0.0697, 0.0203, -0.0237, -0.0074, -0.0224, 0.0327, -0.0363, 0.0059, -0.0138,
		-0.0005, 0.0247, -0.02, -0.0182, -0.0039, 0.0138, 0.0064, 0.0162, -0.0073, -0.0006, 0.0101, -0.0176, 0.0007,
		-0.0011, 0.0181, -0.0047, 0.0004, -0.0014, -0.0086, 0.0029, 0.0135, 0.0192, 0.0002, -0.0175, -0.0005, 0.0005,
		0.0057, -0.0001, -0.0007, 0.0147, 0.0172, -0.013, 0.0089, 0.0048, -0.0178, 0.0351, 0.0167, 0.061, -0.0068,
		0.0429, -0.1514, -0.2792, 0.0108, -0.0116, 0.0593, 0.1081, 0.008, -0.0076, 0.0128, 0.0281, 0.0149, -0.0537,
		0.0277, 0.0184, -0.0131, 0.0135, 0.0118, -0.0041, 0.0289, 0.0113, 0.0008, -0.0022, -0.0011, 0.0071, -0.002,
		0.0286, -0.0024, 0.0662, 0.0097, 0.0031, -0.1058, -0.0397, 0.0649, 0.16, 0.093, 0.0752, -0.0239, -0.0303,
		-0.0623, -0.0799, -0.0599, -0.0246, -0.0037, 0.0205, 0.015, -0.0048, -0.0102, 0.0375, -0.0036, -0.0687, 0.0499,
		0.1029, 0.0299, -0.0195, 0.0066, -0.0132, 0.0023, 0.0136, -0.0382, 0.0261, 0.0038, 0.0242, -0.1307, 0.0147,
		-0.0313, 0.0031, -0.0942, 0.0219, -0.0314, 0.0516, -0.0431, 0.0109, -0.028, 0.0069, -0.0132, -0.0307, 0.0742,
		-0.0773, -0.1066, 0.0169, 0.0096, -0.0476, 0.0078, -0.0318, -0.015, -0.0313, 0.1742, 0.2602, -0.0344, -0.0229,
		-0.3453, 0.1313, 0.2138, -0.0261, -0.0283, -0.2306, -0.158, 0.1239, -0.0784, -0.0115, -0.0095, -0.0125, 0.0078,
		0.0047, -0.0085, 0.026, 0.0537, -0.038, -0.1392, -0.2519, -0.1332, -0.0229, 0.0239, -0.1194, -0.026, -0.0531,
		-0.0045, 0.0284, -0.0003, 0.006, -0.0001, -0.047, -0.0289, -0.0506, 0.0525, -0.1722, 0.0387, -0.0769, 0.1824,
		-0.0313, -0.0655, 0.2576, 0.0364, -0.1768, -0.0195, -0.0101, -0.0115, 0.0043, -0.0824, -0.0133, -0.2062,
		-0.0077, -0.0411, -0.0083, -0.0847, 0, -0.0036, -0.029, 0.0035, -0.0084, -0.0042, 0.0127, -0.0129, 0.013,
		-0.1383, -0.1232, -0.2073, 0.0524, 0.2645, -0.0078, -0.1022, -0.2593, -0.154, 0.2264, 0.0044, 0.0842, 0.0105,
		-0.2522, -0.0893, -0.0938, 0.0097, 0.0117, -0.0123, -0.0025, -0.005, -0.0029, 0.0184, 0.0062, -0.0008, -0.002,
		0.0106, -0.001, 0.0092, -0.0985, -0.018, 0.03, -0.0002, -0.1202, -0.0051, -0.0103, 0.0267, 0.0561, 0.0656,
		0.0537, 0.0608, 0.0307, 0.1287, -0.016, 0.0756, 0.0342, 0.1071, 0.0092, 0.0803, 0.0372, -0.0178, 0.0062,
		-0.0009, -0.0075, -0.0128, 0.0015, 0.0066, -0.0081, 0.0355, 0.0198, -0.1646, 0.0277, -0.0671, 0.1182, 0.0381,
		-0.0241, -0.1639, 0.0893, -0.0522, 0.3604, 0.045, -0.1486, 0.1455, 0.3105, -0.038, -0.1415, -0.0541, -0.0827,
		0.082, 0.0017, 0.0099, 0.0053, 0.0137, -0.0096, -0.0037, -0.0091, 0.005, -0.0024, -0.0116, -0.0004, -0.0028,
		0.0053, 0.0017, -0.0052, -0.0041, -0.0042, 0.001, -0.0023, -0.0022, -0.0016, 0.0026, -0.0028, 0.0008, -0.0044,
		-0.0076, -0.0025, -0.0125, 0.003, -0.0008, -0.0167, -0.0057, -0.0063, 0.0048, -0.0007, -0.0057, -0.0086, 0.0026,
		-0.0118, 0.0015, 0.0074, 0.0144, -0.003, 0.0071, 0.0091, 0.0094, 0.0057, -0.0045, -0.005, -0.0192, 0.0132,
		-0.0071, 0.0065, -0.0022, 0.0019, -0.0033, -0.0073, -0.001, -0.0091, -0.0002, 0.009, 0.0286, -0.0244, -0.0654,
		0.0261, -0.0257, 0.4658, 0.0255, 0.0259, -0.1897, -0.1523, 0.046, 0.0001, 0.0132, 0.0716, 0.0248, 0.2365,
		0.2341, -0.1704, -0.1728, 0.094, -0.0203, 0.1794, -0.1404, -0.0437, -0.0144, -0.0234, 0.0365, 0.0768, -0.0555,
		0.0226, 0.0788, 0.084, 0.0654, 0.0829, 0.0554, 0.0382, 0.0571, 0.0308, -0.0668, -0.0891, -0.0662, -0.0123,
		-0.0418, -0.0749, 0.0969, 0.0787, 0.182, -0.0795, -0.0167, -0.2261, -0.245, -0.0238, 0.0645, 0.1416, 0.3619,
		0.0486, -0.1314, -0.1053, -0.3368, 0.3592, -0.0042, 0.2217, -0.1077, -0.0657, -0.1607, 0.0555, 0.0765, -0.013,
		0.0256, -0.0316, 0.0151, 0.0115, 0.004, -0.0219, 0.0632, -0.0089, -0.0224, -0.0076, 0.0837, -0.0207, -0.1059,
		-0.3556, -0.0196, -0.0327, -0.0952, 0.1179, -0.0015, 0.0972, -0.0441, 0.0762, 0.1916, 0.0755, 0.0658, -0.1944,
		0.0092, -0.0425, -0.004, -0.0269, -0.1553, -0.1374, 0.088, 0.0096, 0.1166, 0.2836, -0.0274, -0.0779, 0.096,
		-0.0036, 0.0229, 0.0267, 0.016, 0.0178, -0.0479, 0.0607, 0.1202, -0.0421, -0.0242, 0.0369, 0.0227, 0.0328,
		0.0089, 0.034, -0.0455, 0.039, -0.0429, 0.029, -0.0041, -0.0401, 0.0298, 0.1069, -0.0668, -0.1181, -0.0455,
		-0.0635, -0.0739, 0.171, 0.0306, -0.1443, 0.0219, -0.0661, -0.0917, 0.0154, 0.1544, 0.018, -0.0106, -0.0351,
		0.0473, -0.1596, 0.082, 0.0512, -0.0497, -0.2311, 0.0447, -0.019, 0.0183, -0.091, 0.0285, 0.0253, 0.0008,
		-0.0009, 0.0022, 0.0011, 0.0012, -0.042, 0.0293, 0.1296, -0.1351, -0.0524, 0.0114, -0.0006, 0.0015, -0.0394,
		0.0061, 0.0083, 0.0084, 0.0162, -0.0032, -0.0435, 0.0396, -0.1411, 0.0703, -0.0156, 0.1782, -0.0044, 0.0863,
		0.058, 0.0186, -0.0502, -0.0583, 0.1168, 0.1872, 0.0172, -0.002, 0.0406, 0.0042, -0.1203, 0.0326, 0.0658,
		0.0462, -0.0393, 0.0078, 0.0016, -0.0022, 0.0087, 0.0057, -0.0022, 0.052, -0.0902, 0.0046, -0.0589, -0.1895,
		-0.0767, -0.0754, -0.0702, 0.1419, 0.0338, 0.0086, -0.1642, 0.0299, -0.0299, 0.015, -0.0002, 0.0089, -0.0125,
		0.0089, -0.0093, 0.0005, 0.1038, 0.1278, -0.2379, 0.2419, -0.1056, 0.0046, -0.2416, 0.3578, -0.0598, -0.0907,
		0.0584, 0.0923, -0.0311, -0.0112, -0.1865, -0.09, 0.1146, -0.0053, -0.0201, 0.0029, 0.0059, -0.0058, 0.0054,
		0.0027, 0.0021, 0.0052, -0.0005, -0.0025, 0.0098, -0.002, 0.007, -0.0039, -0.0062, -0.0134, -0.002, 0.0299,
		0.0198, 0.0115, -0.0024, -0.0344, -0.0122, -0.0479, 0.0253, 0.0743, 0.0063, 0.054, -0.0774, -0.0921, -0.0064,
		0.0885, -0.1491, 0.178, -0.2095, 0.0322, 0.0424, 0.1412, -0.0048, -0.0274, 0.0687, 0.0338, 0.0166, 0.0071,
		0.0437, -0.0519, -0.1316, 0.1022, 0.0377, -0.029, -0.045, -0.0935, 0.0558, -0.0457, 0.0736, 0.0072, 0.0832,
		0.0834, 0.0661, -0.0149, 0.0723, -0.0333, 0.0397, 0.0007, -0.0266, 0.0091, 0.0013, -0.0086, 0.0032, 0.0063,
		-0.009, 0.0083, 0.0074, -0.0062, -0.0061, 0.0009, 0.0031, -0.0013, -0.0004, -0.0071, 0.0004, -0.0044, -0.0004,
		-0.0058, 0.0029, 0.0093, -0.0013, -0.0035, -0.0052, 0.0067, 0.0017, -0.0024, -0.0054, -0.0003, -0.0023, -0.0021,
		-0.0055, -0.0029, 0.0059, 0.0055, -0.0039, -0.0043, -0.0051, -0.0071, -0.0052, -0.0017, 0.016, 0.0042, -0.0055,
		0.0088, -0.0183, 0.0011, 0.0064, 0.0097, 0.0023, 0.0002, 0.005, -0.0053, 0.0066, -0.0058, 0.0014, 0.0018,
		0.0004, 0.0039, -0.0095, 0.0056, -0.0006, -0.0064, -0.0086, -0.0077, -0.002, 0.0046, 0.0015, 0, -0.0067,
		-0.0036, -0.0089, 0.0581, 0.0001, 0.0041, 0.0374, -0.1307, 0.0768, -0.0499, -0.077, 0.2227, 0.1095, 0.1454,
		0.2587, 0.2021, -0.101, 0.0202, 0.0161, 0.0118, 0.0013, -0.0054, -0.0129, 0.0067, -0.0045, -0.0001, 0.0056,
		0.124, -0.1285, -0.0221, -0.0024, 0.0118, 0.0026, 0.006, -0.0003, 0.0022, -0.0002, 0.0039, 0.0041, -0.0011,
		0.0086, 0.003, 0.0186, -0.1872, -0.0191, 0.0801, 0.0377, -0.102, 0.0179, -0.2439, 0.0099, 0.0005, 0.0589,
		-0.0088, 0.0008, 0.0009, 0.007, 0.0008, -0.0037, -0.0019, 0.0025, -0.0023, -0.0075, -0.0091, 0.0175, 0.0221,
		-0.0041, 0.1109, -0.0795, -0.2698, -0.1529, 0.0594, -0.0776, -0.0174, -0.0828, 0.0398, -0.2144, -0.1177,
		-0.0249, 0.0089, -0.0113, -0.0231, -0.0211, 0.0017, -0.0108, -0.0434, -0.0109, -0.0271, -0.06, -0.0727, -0.1154,
		-0.0746, -0.0015, -0.0032, -0.0006, 0.0048, 0.0143, -0.0121, 0.0016, 0.0144, 0.0508, 0.0483, -0.0248, 0.183,
		0.0876, -0.1596, 0.0943, 0.1662, -0.0481, -0.2082, -0.0725, 0.1051, 0.1252, 0.1115, 0.1454, 0.1878, 0.1713,
		0.0856, -0.0293, -0.0073, -0.211, -0.1851, 0.0641, 0.2199, -0.113, 0.0073, -0.0626, 0.0845, -0.1405, -0.1321,
		0.0811, -0.0807, 0.0144, 0.0032, -0.0104, -0.0123, -0.001, 0.0016, 0.0025, 0.0024, -0.0001, -0.0037, 0.0068,
		0.0206, -0.0151, 0.0281, -0.0039, -0.0114, 0.0107, 0.0092, 0.0157, -0.0116, 0.0158, -0.0036, 0.151, 0.045,
		-0.2399, 0.1809, -0.1271, -0.0639, 0.0871, 0.1589, -0.1728, 0.1601, 0.0634, 0.3251, -0.062, 0.0215, -0.0129,
		0.0095, 0.0226, -0.0051, 0.0401, -0.0749, -0.1325, -0.1166, 0.1117, 0.2295, -0.0028, -0.1732, -0.026, 0.2133,
		-0.0703, -0.1255, -0.0831, 0.2537, -0.2357, -0.1874, -0.0376, -0.1742, -0.1193, 0.0975, 0.1511, 0.1325, 0.031,
		0.008, -0.0322, 0.0601, 0.107, -0.1533, -0.1053, -0.3037, 0.0978, -0.2439, 0.1913, -0.073, 0.0842, -0.0085,
		-0.0335, -0.0248, 0.0077, -0.0126, 0.0115, -0.0058, -0.0459, -0.0358, -0.0164, -0.0296, -0.0146, 0.0926,
		-0.2002, -0.0739, -0.0012, 0.0359, -0.0084, 0.2275, 0.0858, -0.2036, -0.0637, 0.123, 0.0835, -0.2309, 0.0059,
		0.2888, -0.0658, 0.0686, -0.1239, 0.1055, -0.0558, 0.2405, 0.0886, 0.0041, -0.1173, 0.1144, 0.0196, -0.0672,
		0.0739, -0.1954, -0.01, -0.0571, 0.0022, 0.0462, -0.1334, -0.0395, 0.1682, 0.0183, 0.0204, -0.0781, 0.1794,
		-0.021, -0.1001, -0.0709, 0.0026, 0.0853, -0.1462, -0.1009, -0.0078, -0.0199, 0.0589, 0.0118, 0.0327, -0.0801,
		0.0868, 0.0232, 0.1733, -0.118, -0.0054, 0.0127, 0.0063, 0.0027, 0.0341, -0.0745, -0.0022, 0.0783, 0.0761,
		0.1561, -0.03, -0.0395, 0.0301, 0.0075, 0.0398, 0.0337, 0.0008, 0.0514, -0.0019, 0.0874, -0.0474, -0.0661,
		0.1387, -0.2185, 0.0642, -0.0214, 0.0353, 0.0105, 0.0019, 0.0176, -0.0195, -0.0026, -0.0396, 0.0308, -0.1529,
		-0.0736, -0.0877, 0.0021, 0.0035, 0.0088, -0.0006, 0.003, 0.0029, -0.006, -0.0149, 0.0136, 0.0296, -0.0384,
		0.0904, -0.0475, -0.073, 0.0532, 0.1206, 0.082, 0.0773, 0.0716, 0.0569, 0.0638, 0.0449, -0.0009, -0.0523,
		0.0504, -0.0099, 0.0519, 0.1664, 0.0764, -0.0918, -0.0483, 0.1315, -0.0776, -0.031, -0.0122, 0.0871, -0.0219,
		0.0375, -0.0701, -0.0208, 0.0177, 0.0093, -0.0308, 0.0334, -0.2058, -0.0766, 0.1196, 0.1795, -0.0732, 0.0115,
		0.089, -0.0011, -0.1536, -0.0055, -0.0361, -0.212, -0.0314, -0.0297, -0.04, -0.1695, 0.0421, 0.0311, 0.0974,
		0.0079, 0.0807, -0.0204, -0.0564, 0.0026, 0.0386, 0.0385, 0.0115, -0.0111, -0.0163, 0.0483, 0.0436, -0.0189,
		-0.0076, 0.0864, -0.0766, 0.0095, 0.0353, 0.0064, 0.0039, 0.0399, -0.0424, 0.0133, -0.06, 0.041, 0.0947, 0.0323,
		-0.0224, 0.0777, 0.0485, -0.0308, -0.0065, 0.0006, 0.0636, -0.044, 0.0514, 0.0003, 0.0186, -0.0305, -0.006,
		0.0549, 0.0054, 0.0173, -0.0767, 0.0032, 0.0402, -0.0574, 0.0567, -0.0454, 0.0017, -0.0775, -0.0126, 0.016,
		0.0929, -0.0044, 0.026, -0.0022, -0.1097, 0.006, 0.0077, -0.0245, 0.0598, -0.0551, 0.0517, 0.0877, -0.0222,
		-0.0417, -0.0166, 0.1148, 0.0096, 0.0441, 0.0163, 0.0055, -0.066, -0.1523, 0.161, 0.0937, -0.0923, 0.0033, 0,
		-0.0138, -0.0021, 0.0157, -0.0311, 0.0166, 0.0686, -0.0049, 0.0745, 0.0137, -0.0443, 0.2036, 0.0224, -0.0771,
		0.209, 0.0169, 0.0727, 0.1662, -0.0875, -0.1166, -0.0129, 0.1235, -0.0596, -0.0856, 0.0201, -0.0283, 0.0029,
		-0.031, -0.0065, 0.1018, -0.0249, 0.0786, -0.1325, -0.0055, 0.0323, 0.0049, -0.1343, 0.0316, 0.1039, -0.2196,
		-0.1518, 0.0872, 0.0036, 0.2943, 0.1357, 0.2022, 0.0517, -0.0019, -0.1867, 0.1718, 0.1247, 0.0538, -0.1402,
		0.0764, 0.1163, -0.0369, -0.1574, 0.0839, 0.0845, -0.1037, -0.1125, -0.1103, 0.0463, 0.1146, 0.0024, -0.0075,
		-0.0244, 0.0327, 0.0567, 0.0298, -0.0872, 0.2233, 0.0444, 0.0307, -0.0448, -0.0436, -0.0129, 0.0113, -0.0127,
		0.005, 0.0143, 0.0036, 0.0014, 0.0143, 0.0014, 0.0017, -0.0003, -0.0028, -0.0116, 0.0355, 0.0226, -0.0625,
		0.023, 0.029, -0.0576, 0.0301, -0.0101, 0.0815, -0.1137, -0.068, 0.1291, 0.2017, 0.2021, 0.1912, 0.1295, 0.0297,
		-0.1416, -0.2603, -0.0226, 0.0864, 0.1309, -0.011, -0.1495, -0.119, 0.0665, 0.0541, -0.077, -0.1291, 0.012,
		-0.1188, 0.042, 0.0014, -0.0069, -0.1035, 0.1062, -0.0299, 0.1084, 0.048, 0.0073, 0.0143, -0.0202, 0.0098,
		0.0065, 0.0084, 0.0217, -0.0299, -0.0817, -0.0195, 0.1882, 0.1778, 0.0072, -0.0671, -0.0718, 0.0451, 0.0921,
		0.1091, 0.0123, -0.0037, 0.0066, -0.0038, 0.0085, 0.0072, 0.0044, -0.0274, 0.0244, -0.0186, 0.0234, 0.0266,
		0.0009, -0.0179, 0.0153, -0.2101, -0.0223, 0.193, 0.1267, -0.0397, 0.0529, -0.0305, 0.0286, -0.0436, 0.0184,
		0.0144, -0.0069, -0.004, -0.0034, 0.0029, 0.0237, 0.0248, 0.041, 0.0206, -0.0577, -0.0035, -0.1004, -0.0013,
		-0.1056, 0.0105, -0.0509, 0.0825, 0.1496, 0.0465, -0.1797, -0.0721, -0.1768, 0.2834, 0.0464, 0.1954, -0.0017,
		0.1884, -0.1864, -0.0493, -0.0316, 0.0883, -0.0013, -0.005, -0.0192, 0.0001, 0.0151, -0.0016, 0.0041, 0.0029,
		0.002, 0.0025, -0.0114, 0.0177, 0.0013, -0.0008, -0.0084, -0.002, 0.008, -0.0094, 0.0013, -0.0047, 0.0064,
		-0.0049, 0.001, -0.0016, 0.0056, -0.0005, 0.0012, 0.0112, 0.0063, -0.0023, 0.0015, 0.0035, -0.0058, 0.0098,
		-0.0043, -0.0094, 0.0008, -0.0025, -0.0235, -0.0322, 0.0121, 0.0099, 0.0179, 0.0563, 0.0018, -0.0379, -0.1145,
		0.026, 0.1938, -0.115, 0.0679, 0.0603, -0.2006, -0.0601, 0.0206, -0.0966, -0.0084, -0.0763, 0.0361, -0.0022,
		0.0064, 0.0028, -0.0053, -0.0233, 0.0062, 0.0144, -0.0298, -0.0841, -0.1408, -0.0173, 0.0444, -0.0217, 0.0052,
		0.0043, -0.0718, -0.082, 0.066, 0.117, 0.0716, -0.1366, 0.0023, -0.0357, -0.004, -0.0124, 0.1126, -0.0507,
		0.0603, -0.0287, 0.0178, 0.008, -0.0014, 0.0028, -0.0081, 0.0043, 0.0031, -0.0404, 0.0171, 0.1457, -0.1237,
		0.0374, 0.0928, 0.0399, -0.0116, 0.0303, 0.1706, 0.0265, -0.1092, -0.0171, 0.0295, -0.07, 0.0196, -0.0234,
		-0.0088, 0.0324, 0.0122, 0.0622, 0.0539, -0.0311, -0.1413, -0.0368, 0.0086, 0.1268, -0.3276, -0.2337, -0.0131,
		-0.1532, -0.2594, 0.0307, -0.2756, -0.1586, -0.123, -0.0167, -0.0494, 0.0069, 0.023, 0.0119, -0.0019, 0.0397,
		0.0531, 0.0141, -0.0099, 0.0033, 0.0043, -0.0119, -0.0258, 0.0234, 0.0015, 0.009, -0.0249, 0.0076, -0.0092,
		0.0146, 0.004, 0.0302, 0.004, -0.0259, -0.0197, 0.0525, 0.029, 0.0224, 0.0264, -0.0769, -0.0035, 0.0617, 0.0981,
		-0.0307, -0.0097, 0.0485, 0.0724, 0.138, 0.0996, -0.0227, -0.0622, -0.0059, -0.0429, -0.034, -0.1207, -0.0753,
		0.0903, 0.0017, 0.0002, 0.0175, 0.2024, 0.0642, 0.0753, 0.0057, 0.0207, -0.0375, 0.0445, 0.0731, 0.0875, 0.0514,
		0.1493, -0.0005, 0.0438, -0.0153, 0.0487, 0.0273, -0.0769, 0.0543, -0.0609, -0.0578, 0.016, -0.043, 0.0258,
		0.019, 0.0094, 0.0222, 0.001, 0.0031, 0.0075, -0.0068, 0.007, -0.0117, -0.0167, -0.01, -0.0109, 0.0127, 0.0937,
		-0.0409, -0.1947, -0.108, 0.0322, -0.0416, 0.0427, -0.0256, -0.1382, -0.0208, -0.0825, 0.0221, -0.0393, -0.0351,
		0.0231, 0.0168, 0.0141, 0.0093, -0.0481, 0.0446, 0.0196, 0.0097, 0.1023, -0.082, -0.0003, 0.0139, -0.1683,
		-0.1962, -0.4562, -0.1888, 0.0107, 0.0245, 0.0019, -0.0059, -0.0569, 0.0108, 0.0447, 0.0216, -0.0056, 0.0213,
		0.0158, 0.0296, 0.0123, 0.036, 0.1004, -0.2335, -0.1459, 0.1688, 0.0569, -0.0496, 0.1403, -0.1558, 0.0801,
		0.0159, 0.0051, 0.0059, 0.008, -0.0002, 0.0054, 0.0057, -0.0017, -0.0031, 0.0255, 0.0272, 0.0543, 0.0705,
		0.3301, -0.1129, 0.0968, 0.0065, -0.0035, -0.0764, 0.0573, 0.0326, -0.0106, 0.0371, 0.048, 0.0696, -0.002,
		0.0203, 0.0047, 0.0095, -0.0009, 0.0261, -0.0463, 0.1002, 0.1458, 0.1249, 0.0261, -0.0603, 0.0373, 0.0301,
		0.029, 0.0036, -0.0037, 0.0045, -0.0071, -0.0039, -0.002, -0.0142, -0.0085, 0.0464, 0.0328, -0.2944, 0.1775,
		-0.0399, -0.0722, -0.1381, 0.151, -0.0157, -0.0053, 0.1666, 0.0133, 0.048, -0.2093, 0.052, -0.0181, -0.0047,
		0.006, 0.0541, 0.0947, -0.0472, 0.0709, 0.1887, 0.2493, 0.0569, -0.09, -0.027, 0.0094, 0.0067, 0.1986, -0.0729,
		-0.0046, -0.0186, 0.0072, -0.0008, -0.0218, -0.0034, -0.0053, 0.0005, 0.0023, 0.0171, 0.011, -0.0006, -0.0119,
		-0.0949, 0.0769, 0.0509, -0.017, -0.1555, 0.1235, -0.0324, 0.0249, 0.2711, 0.024, 0.039, 0.107, 0.0131, -0.0133,
		0.0046, -0.009, 0.0016, 0.0145, 0.0023, 0.0014, 0.0071, -0.0018, 0.0014, -0.0009, -0.0101, 0.014, -0.0066,
		0.0046, -0.0001, -0.0058, -0.0043, -0.0099, -0.0047, 0.0016, -0.0056, -0.0051, 0.0051, -0.0076, 0.0054, -0.0065,
		0.0004, 0.0048, 0.0042, 0.0016, -0.0031, 0.0044, -0.0038, 0.0083, 0.0072, 0.0022, 0.0026, 0.0073, 0.0041,
		0.0125, -0.0057, -0.0147, 0.0013, -0.0022, -0.0339, -0.0089, -0.0566, -0.14, 0.0595, -0.0066, -0.1272, 0.0446,
		0.104, -0.0606, 0.0815, 0.1904, -0.1443, -0.0953, 0.1581, -0.0665, -0.0707, -0.0806, 0.1329, 0.0914, -0.0712,
		-0.0494, 0.085, -0.0364, 0.0462, -0.0104, -0.0115, -0.0068, 0.0236, 0.0469, 0.0921, 0.0933, 0.1205, -0.0359,
		-0.1839, 0.176, -0.0842, 0.1397, -0.0392, -0.0249, 0.0328, 0.0489, -0.1006, -0.0563, 0.124, -0.0649, 0.104,
		-0.136, 0.0597, 0.0579, -0.3295, 0.1368, 0.0441, -0.0419, -0.0169, 0.0221, 0.0415, 0.0604, 0.0247, -0.0349,
		-0.0004, -0.0035, 0.0058, 0.0004, 0.0101, 0.0077, -0.0661, -0.1418, -0.2036, -0.0975, -0.0634, -0.0327, 0.0151,
		0.0264, 0.0066, 0.0181, 0.0163, -0.0903, 0.0498, 0.047, 0.0705, -0.0446, 0.0519, -0.0074, 0.028, 0.011, 0.0026,
		0.0064, 0.0105, -0.0023, 0.0117, 0.0053, -0.0258, 0.0083, 0.0066, 0.0447, -0.0524, -0.0836, -0.0175, -0.043,
		0.1276, 0.0541, 0.0092, 0.0801, -0.1511, 0.1512, 0.0412, 0.0457, 0.1354, 0.0131, -0.0844, 0.0077, 0.0507,
		0.0029, -0.0089, -0.0154, -0.0408, -0.0304, 0.0157, 0.0406, 0.0572, 0.0472, -0.0129, -0.0258, 0.0018, 0.066,
		-0.0748, 0.0113, -0.0134, 0.063, -0.0596, 0.0555, 0.043, 0.0106, 0.012, -0.0135, 0.006, 0.0015, -0.0105, -0.013,
		0.0058, -0.0567, 0.0441, -0.1638, -0.026, 0.0017, 0.0929, -0.0482, -0.0381, -0.0121, -0.0073, 0.0407, 0.0472,
		0.0242, -0.0067, 0.0126, -0.0028, 0.0125, -0.006, -0.0015, 0.0142, -0.0071, -0.0117, -0.0041, 0.0069, 0.0169,
		0.0056, 0.0043, 0.0251, 0.0162, 0.029, 0.0617, 0.0981, -0.0314, 0.0993, -0.0288, 0.0012, 0.0034, -0.0082,
		0.0041, 0.0041, -0.0114, 0.0148, 0.0497, 0.0041, 0.0447, -0.1015, -0.0001, 0.0222, -0.0475, 0.008, 0.0149,
		0.0028, -0.0071, 0.0021, -0.0015, 0.003, -0.0067, 0.0121, 0.0118, 0.0383, 0.0611, -0.2457, -0.0403, 0.0432,
		0.0251, 0.0944, 0.0533, -0.0253, -0.0087, -0.0337, -0.0631, -0.0056, -0.0567, -0.1873, -0.0547, 0.0057, 0.0145,
		0.0307, -0.0267, -0.0931, 0.0816, 0.008, -0.0452, 0.2117, -0.2004, -0.0079, -0.1237, 0.2531, 0.1092, -0.0692,
		-0.0227, -0.0051, -0.0455, 0.0572, -0.0429, 0.0338, -0.1059, -0.0623, 0.2563, 0.0838, -0.0287, 0.0414, -0.003,
		-0.0527, -0.2504, 0.0253, -0.065, 0.0274, 0.0967, -0.0973, 0.1576, -0.0726, 0.0177, 0.0165, -0.0215, 0.0115,
		-0.0755, -0.056, -0.1652, 0.0105, 0.0478, 0.0319, -0.0056, 0.0107, -0.0163, 0.0055, -0.0121, -0.0049, -0.0321,
		0.0677, 0.0321, 0.017, -0.0504, 0.0124, -0.0273, -0.0067, 0.0221, -0.041, 0.0163, -0.0597, 0.0984, -0.104,
		-0.0673, -0.0724, 0.144, 0.0175, 0.0167, 0.0301, 0.0298, -0.1692, 0.021, -0.0674, 0.0034, 0.0045, 0.0124,
		0.0119, 0.0128, 0.0071, 0.0034, -0.0092, 0.0028, 0.0078, -0.0054, 0.0051, -0.0033, 0.0026, -0.0096, 0.0071,
		0.005, 0.0055, -0.0058, -0.0141, -0.001, -0.0006, -0.0049, -0.0072, -0.001, 0.0008, 0.0088, -0.0007, 0.0006,
		0.0028, -0.0019, 0.0022, 0.0007, -0.0091, 0.0005, -0.0008, 0.0033, 0.0068, 0.0079, 0.0076, -0.0053, -0.0102,
		-0.0314, 0.0422, -0.0047, 0.0352, -0.1198, -0.041, 0.116, 0.1239, -0.0175, -0.0467, -0.1967, 0.1085, 0.1288,
		-0.2506, -0.1634, -0.1372, -0.1532, 0.1142, -0.0295, -0.0616, 0.1127, 0.1141, 0.0672, -0.0374, -0.0502, -0.0478,
		0.0102, 0.1071, 0.0233, -0.0507, -0.0609, -0.1361, -0.094, -0.0407, 0.0053, 0.0705, -0.1694, -0.208, -0.1154,
		0.1181, 0.1619, -0.0906, -0.1538, -0.0042, -0.0361, 0.0193, 0.0599, 0.0758, 0.0686, -0.0463, -0.0021, 0.025,
		-0.0063, 0.0048, 0.0311, -0.0072, 0.0052, -0.0234, 0.1948, 0.1911, -0.1236, 0.0841, 0.0229, 0.068, 0.1159,
		-0.005, 0.0411, 0.025, -0.0062, -0.0284, 0.041, 0.0145, 0.0245, -0.0467, 0.0219, 0.0136, -0.0123, 0.0025,
		0.0056, -0.0007, -0.0023, -0.0032, -0.0022, 0.0171, -0.0213, 0.0122, 0.0025, -0.0056, -0.006, -0.0006, 0.0028,
		0.0036, 0.0049, 0.0038, 0.0102, 0.0022, 0.0134, 0.0029, -0.0158, -0.0011, -0.0024, -0.0014, 0.0055, 0.0065,
		0.002, 0.0012, -0.0034, 0.0049, -0.0028, 0.0143, 0.001, -0.0048, 0.0054, 0.0026, -0.0241, 0.0404, 0.0695,
		0.0164, -0.2852, -0.0147, 0.18, -0.0161, 0.0168, -0.0316, 0.0271, -0.002, 0.0229, 0.0541, -0.0852, 0.0342,
		0.0437, 0.0424, 0.0117, -0.2401, -0.1432, -0.2287, 0.2558, -0.0732, -0.0237, -0.0411, -0.0822, -0.0626, -0.0656,
		0.0053, -0.1482, -0.0307, 0.1072, -0.1342, 0.1157, -0.0211, 0.1575, -0.0611, -0.0701, -0.1302, -0.1496, -0.1309,
		-0.0619, -0.0397, 0.0819, -0.0315, -0.0608, -0.0032, 0.0561, -0.058, 0.1218, -0.0968, 0.0404, 0.0214, 0.0047,
		-0.0689, 0.0334, 0.0163, 0.0201, -0.0142, -0.0302, 0.021, 0.0116, 0.0159, 0.0009, 0.0058, 0.0132, 0.0025,
		-0.0051, 0.0071, -0.0243, -0.0504, 0.0049, 0.0278, -0.1057, -0.095, -0.0162, 0.0872, 0.2137, 0.0598, -0.2398,
		0.1446, 0.0845, -0.1692, 0.1141, 0.0548, 0.1404, -0.0336, 0.101, 0.1435, 0.0094, 0.0306, -0.0699, 0.1776,
		0.0111, 0.0363, 0.0186, -0.2048, -0.0075, -0.0442, 0.0034, -0.0033, 0.0113, -0.0009, 0.0099, -0.0108, -0.0019,
		-0.0331, -0.0277, 0.0323, -0.0614, 0.113, -0.0303, 0.2639, 0.0251, 0.0191, 0.0355, -0.0513, -0.0035, 0.0227,
		-0.0361, 0.008, -0.1797, -0.0145, 0.0247, 0.0516, -0.0102, -0.0186, 0.001, -0.0039, 0.0666, -0.0751, 0.0377,
		0.0154, 0.0365, 0.0216, -0.0272, -0.0036, 0.011, 0, -0.0022, -0.0087, -0.0061, -0.0121, 0.1498, 0.1345, 0.3017,
		0.364, -0.2152, -0.173, 0.1494, -0.1205, 0.0505, -0.0755, -0.2698, 0.2056, -0.0905, 0.0526, 0.0755, 0.0135,
		0.0266, 0.0146, 0.038, 0.0364, 0.058, 0.0855, 0.0091, -0.0446, -0.0186, 0.0847, -0.0803, 0.0005, 0.0323, 0,
		-0.0289, 0.023, 0.037, 0.0994, -0.0064, -0.0067, 0.0404, -0.0233, -0.0181, 0.0062, 0.0025, -0.0024, -0.0016,
		0.0074, -0.0079, 0.0034, -0.0074, -0.0047, -0.0007, 0.0279, 0.0539, -0.0183, 0.2556, 0.0785, 0.055, 0.0305,
		-0.1107, 0.0945, -0.1214, 0.1874, 0.0749, -0.0364, 0.0109, -0.0076, -0.0002, 0.005, -0.0142, 0.0065, -0.048,
		-0.1483, 0.0005, 0.0668, -0.0305, 0.0263, 0.0445, -0.0396, -0.0169, 0.0334, -0.0201, -0.0955, -0.0282, 0.0379,
		-0.0655, 0.0282, 0.023, -0.0285, 0.0079, 0.0453, -0.0682, 0.2658, 0.0111, 0.2131, -0.2069, 0.0776, 0.0903,
		-0.2346, 0.1383, -0.1004, -0.0599, 0.1771, -0.0297, 0.0103, 0.0069, 0.0001, 0.0012, 0.0074, -0.0085, -0.0048,
		0.002, 0.0016, -0.1474, 0.0301, 0.0868, -0.0479, -0.134, 0.1577, 0.0745, 0.0205, 0.2661, -0.0668, 0.0263,
		0.1494, 0.0199, -0.0413, 0.0728, -0.0411, 0.0134, 0.0133, 0.0038, 0.0034, 0.0024, -0.0095, -0.0329, -0.0538,
		-0.0121, -0.0319, -0.0067, 0.1009, -0.0165, -0.0262, -0.0125, 0.0067, -0.013, -0.0025, -0.013, -0.0057, 0.0091,
		-0.011, 0, -0.0025, -0.0314, -0.0211, -0.0651, 0.0131, 0.0078, -0.0017, 0.0034, -0.0129, 0.0078, 0.0035, 0.0037,
		0.0163, 0.0056, -0.0264, -0.0965, 0.0203, 0.1801, 0.1564, 0.0585, 0.2007, -0.2654, -0.1255, -0.0227, 0.0267,
		-0.0006, 0.011, 0.0352, 0.0486, -0.0329, 0.0114, -0.0042, 0.015, -0.0083, 0.0081, 0.0023, 0.0105, -0.001,
		-0.0005, 0.0069, -0.0097, 0.0073, -0.0117, 0.0306, 0.018, -0.0772, 0.1116, -0.0888, -0.029, 0.1053, -0.0567,
		0.0892, 0.0099, -0.0046, -0.0002, -0.0064, -0.0096, -0.0051, -0.0185, 0.0132, 0.0129, 0.0608, 0.1701, -0.045,
		0.3558, -0.1012, 0.0111, -0.0469, -0.0875, 0.0414, 0.1162, -0.1035, -0.0324, 0.0765, 0.0476, -0.0075, 0.0022,
		-0.0481, 0.0071, 0.0075, 0.0064, 0.0158, -0.0012, 0.0049, -0.0081, 0.0055, -0.0018, -0.0015, -0.0037, -0.0032,
		-0.0046, -0.0021, 0.002, -0.0081, 0.0003, -0.0015, -0.0004, 0.0071, 0.0093, -0.0053, 0.0002, -0.012, -0.0004,
		-0.0007, -0.0071, 0.005, -0.0077, 0.0133, -0.0201, 0.0123, -0.0146, 0.0117, -0.0025, 0.014, 0.0125, 0.0234,
		0.1663, -0.1286, 0.1154, 0.0081, -0.0008, 0.0287, 0.0519, 0.038, 0.0453, 0.0273, -0.0463, -0.0108, 0.052,
		0.0016, -0.0209, -0.0059, 0.0244, -0.0742, -0.054, -0.011, 0.0938, 0.0148, 0.0282, -0.0443, 0.0567, -0.0002,
		0.028, -0.0021, 0.0177, -0.0225, 0.004, 0.0106, -0.0012, -0.1279, 0.0179, 0.2059, 0.0402, -0.0819, -0.0077,
		-0.064, 0.1253, 0.3884, 0.1345, 0.0591, -0.1784, -0.0085, -0.0364, 0.0258, 0.0337, 0.0055, -0.0429, 0.0306,
		-0.0171, 0.213, -0.0743, -0.0007, -0.0544, -0.1042, 0.0359, -0.0704, -0.0167, 0.0248, 0.0051, 0.0184, 0.0165,
		-0.0117, -0.0297, 0.0181, 0.0308, -0.0838, -0.0464, 0.1459, -0.1357, 0.0791, 0.2155, -0.0082, -0.0618, -0.0401,
		-0.0052, -0.0005, 0.0018, 0, -0.0095, 0.0059, 0.0007, 0.0073, 0.0037, 0.0016, -0.0019, -0.0035, 0.0489, -0.0043,
		-0.1145, 0.0424, 0.005, -0.0015, 0.0365, -0.0135, -0.0117, -0.0483, 0.0012, 0.1334, 0.0392, 0.0398, 0.056,
		0.0052, 0.0485, 0.0124, 0.0366, -0.1301, 0.003, -0.0733, 0.0081, -0.0226, -0.0415, 0.0277, 0.0221, -0.0055,
		-0.0336, 0.014, 0.0143, 0.0048, 0.0035, -0.0253, 0.0133, -0.0067, -0.0842, 0.0858, -0.0981, -0.1747, -0.0093,
		0.0651, 0.0311, -0.0804, -0.1965, 0.0183, 0.0618, -0.0133, 0.0321, 0.0802, 0.0353, -0.0095, 0.0319, 0.0075,
		0.0014, 0.0246, -0.0509, 0.0284, 0.0043, 0.0085, 0.0234, 0.03, 0.0024, -0.0112, 0.0038, 0.0131, 0.0105, 0.0137,
		-0.0007, -0.017, -0.0044, 0.0033, -0.0021, 0.016, 0.008, 0.0165, 0.0138, -0.0276, 0.046, 0.0082, 0.0105,
		-0.0308, -0.0079, 0.0105, 0.0199, -0.1192, 0.0475, 0.0129, -0.0915, 0.0453, -0.0221, 0.0016, 0.0045, 0.0042,
		-0.0083, 0.0045, 0.0069, -0.0049, -0.0001, 0.0046, 0.025, 0.2464, -0.0865, -0.1268, 0.0137, -0.2239, 0.0272,
		-0.1901, -0.0324, 0.0632, 0.1063, -0.1481, -0.1376, 0.3643, -0.0804, 0.0605, -0.1573, 0.0289, 0.0981, -0.045,
		-0.0433, -0.0647, 0.2703, -0.042, 0.0087, -0.0641, 0.01, 0.0119, 0.034, -0.0111, 0.0149, 0.0436, 0.0126, -0.053,
		0.0212, -0.0916, 0.0332, 0.0301, 0.0555, -0.0138, -0.1717, -0.0096, -0.052, 0.1447, -0.0031, -0.1795, -0.1262,
		-0.0289, 0.292, 0.0938, -0.0425, -0.0352, -0.1154, 0.2166, 0.0326, -0.0228, -0.1127, 0.0488, 0.1097, -0.063,
		0.0231, 0.0376, 0.0054, -0.0058, 0.0496, 0.0069, -0.0249, -0.0039, -0.0394, -0.0167, 0.0364, 0.0361, -0.1017,
		0.169, -0.0163, -0.0959, 0.0767, 0.0011, 0.1701, -0.0967, 0.0661, 0.1037, 0.1099, 0.2266, 0.14, 0.1705, 0.0816,
		0.0094, -0.0201, -0.046, -0.0044, 0.0079, -0.0071, -0.0009, -0.0063, 0.0056, -0.0101, 0.0131, 0.012, 0.0303,
		0.2002, -0.0762, 0.0139, -0.0437, 0.0411, -0.081, -0.012, -0.0014, 0.015, -0.0019, 0.0063, -0.0941, -0.1723,
		-0.0795, -0.0529, -0.0915, -0.0322, 0.1085, 0.0376, -0.0011, -0.0207, 0.0003, 0.0318, 0.0207, 0.0054, 0.0085,
		0.0102, -0.0223, 0.0303, -0.0418, 0.0611, -0.0044, 0.0134, 0.028, 0.0021, 0.0289, -0.0048, -0.0001, -0.0012,
		0.0057, -0.0141, 0.0133, 0.0117, -0.0101, -0.0017, -0.0042, 0.0079, -0.0018, 0.0042, -0.0021, -0.0126, -0.0077,
		0.0141, -0.0063, -0.0112, -0.008, 0.0238, 0.0198, -0.0107, 0.0187, -0.0031, 0.0339, -0.0174, -0.0134, -0.0172,
		-0.0083, -0.0007, -0.0072, -0.0079, 0.0072, 0.0173, -0.003, 0.0032, 0.0182, 0.0025, -0.0025, 0.0009, -0.0525,
		-0.0957, 0.0768, -0.0186, 0.1367, -0.067, -0.0134, -0.0019, -0.0049, -0.0041, 0.019, -0.164, -0.0128, -0.0281,
		0.0656, -0.1186, 0.1003, -0.0638, -0.0081, 0.0143, 0.0029, 0.0188, 0.022, 0.0006, 0.0057, -0.0224, 0.1567,
		0.128, -0.0493, -0.0758, 0.0282, 0.0715, 0.1182, 0.0019, 0.0447, 0.0182, 0.0005, 0.014, 0.0056, -0.0038, 0.0011,
		-0.0059, -0.0019, -0.0771, -0.0598, 0.192, -0.1185, 0.1235, -0.1417, 0.1481, -0.1057, -0.0136, 0.0765, -0.0074,
		0.0553, -0.0144, 0.1334, -0.0055, 0.0996, 0.007, 0.0025, -0.0266, 0.0262, 0.0022, 0.0001, 0.0092, 0.0149,
		0.0013, 0.0013, -0.0253, 0.0104, 0.0002, -0.0051, -0.0148, 0.0205, -0.0356, -0.1216, -0.0755, -0.061, 0.1144,
		-0.0187, 0.0662, 0.0033, 0.0091, -0.0079, 0.0049, 0.0126, 0.0218, -0.002, -0.004, -0.0063, -0.0059, 0.0016,
		0.0369, -0.0085, -0.0265, 0.0141, -0.0569, 0.1279, -0.0911, -0.0946, 0.1435, -0.0491, 0.07, -0.0309, -0.025,
		0.0058, 0.0347, -0.0052, 0.0044, 0.0261, 0.1315, 0.001, -0.1147, -0.0064, 0.2107, 0.1009, -0.1166, 0.0655,
		-0.2123, -0.0616, -0.0032, 0.1208, 0.0753, 0.0799, -0.0231, 0.0221, 0.1681, -0.0825, -0.0678, 0.037, -0.0373,
		-0.0145, 0.0381, 0.0133, 0.0047, 0, 0.0397, -0.0517, 0.0398, -0.0536, 0.1452, 0.0176, -0.1786, 0.0004, 0.0083,
		0.0081, 0.0085, 0.0064, -0.0035, 0.0023, 0.0109, 0.0028, 0.0081, -0.0697, -0.0314, 0.0041, 0.0129, -0.0048,
		-0.003, 0.0169, 0.0076, 0.0564, 0.0662, -0.0417, 0.0136, -0.0423, -0.1321, 0.07, 0.2954, -0.2079, -0.1344,
		-0.0954, 0.0262, -0.0798, -0.001, -0.0064, 0.0087, -0.0018, -0.0038, 0.0039, -0.0046, 0.0013, -0.0064, 0.0105,
		-0.0222, 0.0128, -0.0089, 0.0087, 0.0553, 0.0972, 0.0676, 0.1268, -0.0217, -0.1198, -0.0175, 0.0111, 0.0229,
		0.0156, -0.001, -0.0038, 0.0087, 0.0388, -0.0645, 0.011, -0.0276, 0.0496, -0.1055, -0.1009, 0.0155, -0.0117,
		0.0065, -0.028, -0.1262, 0.0024, 0.1048, -0.298, -0.0441, -0.1006, -0.0593, 0.0218, 0.042, 0.0058, 0.0274,
		-0.0367, 0.0357, -0.0746, -0.1863, -0.0122, -0.0397, 0.1289, -0.0694, 0.1291, -0.0797, -0.1769, -0.2139,
		-0.1226, -0.0505, -0.1101, -0.1331, -0.0035, -0.009, -0.003, -0.0031, -0.0057, 0.0022, -0.0216, -0.0514, 0.0212,
		-0.0114, 0.0589, 0.0374, 0.0016, -0.008, -0.0232, 0.007, 0.0981, -0.0008, -0.0296, -0.0074, 0.0279, 0.0049,
		0.004, -0.0409, 0.0101, 0.0109, -0.0069, 0.0058, -0.0046, 0.0011, -0.005, 0.0037, -0.0022, 0.0039, -0.0019,
		-0.0043, -0.0011, -0.0051, -0.0009, 0.0018, -0.0014, 0.0024, 0.0006, 0.0054, 0.0029, -0.0012, 0.0102, -0.0015,
		-0.0047, -0.0017, -0.011, 0.0048, -0.0036, -0.0009, -0.0112, -0.009, -0.0091, -0.0156, -0.0095, -0.0029,
		-0.0071, -0.0057, 0.0013, -0.012, 0.0108, 0.001, 0.0012, 0.004, 0.0062, 0.001, 0.002, -0.0105, -0.0019, 0.0994,
		-0.1469, -0.1224, 0.0632, -0.0684, -0.0241, 0.0232, 0.0002, -0.008, 0.0283, 0.0424, 0.1103, -0.0409, -0.2333,
		0.0565, 0.0085, 0.02, 0.0217, 0.0187, 0.0248, -0.0028, -0.1563, 0.0558, -0.0122, 0.1517, -0.093, 0.0121,
		-0.0932, -0.1272, -0.0719, -0.0957, -0.1041, -0.1075, -0.1104, -0.1618, -0.2018, -0.0921, -0.1373, -0.1817,
		-0.0468, 0.0135, 0.0259, -0.0443, 0.0437, -0.0351, 0.0295, -0.0008, -0.0517, -0.019, 0.0378, -0.0018, 0.0579,
		0.0191, 0.0183, -0.0376, -0.0893, -0.1829, -0.1386, -0.1442, -0.1734, -0.1576, -0.1691, -0.1633, -0.1687,
		-0.0707, 0.017, -0.0138, -0.012, 0.0325, 0.0174, -0.0039, 0.0084, -0.0482, 0.0798, -0.0808, 0.1163, -0.2014,
		0.0863, 0.3497, -0.0211, 0.1079, 0.0285, -0.0322, 0.016, 0.0082, 0.0019, 0.004, 0.001, -0.0128, -0.0382, 0.0296,
		0.0198, -0.0051, -0.0037, 0.0089, 0.0115, 0.0047, -0.0018, 0.004, -0.0036, -0.0228, -0.0082, -0.0275, 0.0326,
		-0.1824, -0.2687, -0.0233, 0.1092, 0.2329, 0.0167, 0.0287, -0.146, 0.1292, 0.0259, -0.0873, 0.0605, -0.067,
		0.0066, -0.1843, -0.1635, 0.0836, -0.146, -0.0306, 0.1306, -0.0284, 0.059, -0.0398, -0.0134, -0.001, 0.0138,
		0.0085, -0.0141, -0.0073, -0.0161, 0.004, -0.0056, -0.0064, -0.0029, 0.0105, -0.0049, 0.0166, -0.0057, -0.0159,
		-0.0046, -0.007, -0.0053, 0.0084, -0.0008, -0.0102, -0.0013, 0.0087, 0.0082, 0.0033, -0.0155, -0.011, 0.0013,
		-0.012, -0.0073, 0.0085, -0.0098, 0.001, -0.0162, 0.0036, 0.0032, 0.0033, -0.0053, -0.0156, -0.0047, -0.003,
		0.0058, 0.0055, 0.0009, -0.004, 0.0064, -0.0021, 0.0006, -0.0042, -0.0058, -0.0533, -0.0818, 0.098, 0.1671,
		0.3068, 0.3449, 0.2878, 0.0728, 0.2097, -0.0507, -0.297, -0.1588, -0.0635, -0.1288, -0.1095, -0.0266, -0.063,
		-0.0817, -0.0681, -0.0768, 0.0277, -0.0603, 0.0621, -0.0584, -0.0054, 0.0171, -0.0025, 0.0078, 0.0015, -0.0053,
		0.0192, 0.0058, -0.038, -0.1837, 0.1493, -0.0036, 0.0466, -0.0525, 0.0037, -0.0088, -0.0011, -0.0005, 0.0014,
		-0.0185, -0.0035, -0.0076, 0.024, 0.2049, -0.0942, 0.1565, -0.0874, 0.1166, 0.0164, 0.0234, 0.0148, 0.0132,
		0.0045, -0.0016, 0.0098, -0.0092, 0.047, -0.1152, 0.0266, 0.1471, -0.0029, -0.0274, 0.0077, -0.0024, 0, -0.0076,
		-0.0012, 0.0005, -0.0025, -0.0114, 0.0162, -0.0068, -0.0053, -0.0052, 0.0367, -0.013, -0.1137, -0.0662, -0.0544,
		-0.0585, -0.0023, 0.0188, -0.0062, -0.0006, 0.0003, 0.0028, 0.003, 0.0057, 0.0144, -0.0182, 0.0144, 0.0738,
		-0.0958, 0.1381, 0.0203, 0.4228, -0.0266, 0.0292, -0.1455, 0.1763, 0.1096, -0.0078, 0.1704, 0.0214, -0.1278,
		-0.0483, 0.1209, -0.0396, 0.0051, -0.0583, -0.0105, -0.0324, 0.0024, -0.0296, 0.1149, -0.0455, 0.1915, 0.1397,
		0.0117, -0.1011, -0.1105, -0.1258, -0.0932, 0.0461, -0.0171, -0.0015, -0.0664, 0.0196, -0.1004, 0.0974, -0.004,
		-0.0108, 0.0271, 0.0289, -0.018, 0.0268, -0.0246, 0.0093, -0.0263, -0.0052, -0.0017, 0.0039, 0.0027, -0.0017,
		-0.0027, 0.002, -0.019, 0.0016, -0.015, 0.0078, 0.0068, -0.0195, 0.0115, 0.0673, 0.0407, -0.0934, -0.0019,
		0.0533, -0.0401, 0.0759, 0.0025, -0.0517, -0.0365, -0.007, 0.2211, -0.098, 0.0856, -0.1277, 0.0147, -0.0202,
		0.0491, -0.0056, -0.0054, -0.0031, -0.0076, 0.0085, 0.0069, -0.0004, -0.0003, -0.0055, 0.0276, 0.0246, -0.0117,
		0.0483, -0.0791, -0.019, -0.0021, -0.1086, 0.2362, 0.1676, -0.2665, -0.0876, 0.0287, -0.0124, -0.1551, 0.075,
		-0.0713, 0.0444, 0.0571, -0.0979, 0.0337, -0.0114, -0.0026, 0.0032, 0.003, -0.0027, 0.0039, -0.0061, 0.0004,
		0.0042, 0.0058, 0.003, -0.0243, 0.011, 0.0466, -0.0636, 0.0692, -0.0109, -0.0793, -0.3693, 0.0382, -0.2117,
		-0.0436, 0.1213, 0.1574, 0.0423, -0.0624, 0.0961, -0.0061, 0.0021, 0.0147, 0.0197, 0.0001, 0.0214, 0.2265,
		0.0072, -0.2145, -0.069, 0.0059, -0.0313, 0.0563, -0.0287, 0.1038, 0.0121, -0.1343, 0.0635, 0.0268, 0.0531,
		-0.0391, -0.1382, 0.1003, 0.1217, -0.0563, -0.0522, -0.2147, -0.0712, 0.1269, -0.0257, -0.0007, 0.0136, 0.0109,
		-0.0023, 0.0036, 0.0021, 0.0082, -0.0006, 0.0052, -0.0039, -0.0089, 0.0063, -0.0086, 0.0104, 0.043, 0.0676,
		-0.1201, -0.0039, 0.029, -0.0167, 0.0049, 0.0271, 0.0266, 0.0082, 0.0359, 0.096, 0.0834, -0.0185, -0.0216,
		-0.0163, 0.084, -0.125, 0.0431, -0.115, 0.0119, -0.1033, 0.0297, 0.0284, -0.0538, 0.0104, -0.0043, 0.0104,
		0.0282, 0.0226, 0.0045, 0.0006, 0.0184, -0.0682, 0.019, -0.0017, -0.0377, 0.0463, -0.0354, 0.2229, -0.065,
		0.081, 0.0542, 0.0506, -0.1462, 0.0542, -0.0107, -0.0124, 0.0054, 0.0428, -0.0837, 0.0166, 0.0296, 0.0041,
		-0.0007, -0.0719, 0.0261, 0.011, 0.037, -0.0177, 0.0152, -0.0227, 0.0175, 0.0358, -0.0097, -0.0095, -0.0112,
		-0.0008, 0.0098, 0.0056, -0.0001, 0.001, -0.0092, 0.0025, 0.0034, 0.0162, -0.0357, 0.021, -0.1033, 0.1496,
		-0.1927, 0.178, -0.2004, -0.0005, 0.1384, -0.0812, -0.0118, -0.0061, -0.0117, -0.0003, -0.0023, -0.0039, 0.0078,
		-0.0058, -0.0016, -0.0105, 0.0033, 0.0002, -0.0055, -0.0019, 0.0018, -0.0072, 0.0017, -0.0043, 0.0109, 0.0014,
		0.0015, -0.0085, 0.0043, 0.0007, 0.0002, -0.0017, -0.0037, -0.0028, 0.01, -0.0008, 0.0068, 0.0081, -0.0059,
		0.0014, 0.0018, -0.0129, -0.0045, -0.0029, 0.0054, 0.0038, -0.0012, 0.0049, -0.0071, -0.0027, -0.0029, 0.0139,
		0.0286, -0.0614, -0.0151, -0.0171, 0.0255, -0.1695, 0.0182, -0.2306, 0.0096, -0.0465, 0.0108, 0.0465, 0.0604,
		-0.0134, -0.0391, -0.0005, 0.0073, -0.0018, -0.0016, -0.0002, 0.0146, 0.0077, -0.0011, -0.0048, -0.095, 0.1188,
		0.0324, 0.1229, -0.1483, 0.2071, 0.0973, -0.1064, -0.1496, -0.2283, -0.0772, 0.0871, -0.0218, 0.0034, -0.0037,
		0.0024, 0.0014, -0.0006, -0.0118, -0.0044, 0.0001, -0.0057, 0.0125, 0.0437, 0.2148, 0.0162, 0.0264, 0.0589,
		-0.0465, 0.2006, -0.2619, -0.0391, 0.0096, -0.0808, -0.0255, 0.0127, -0.1186, 0.0898, 0.0262, 0.0684, -0.0818,
		0.005, -0.0339, -0.0125, 0.004, -0.0281, -0.0044, 0.0028, -0.0075, -0.0058, 0.0586, 0.113, 0.0775, 0.0697,
		0.0918, 0.0377, 0.0063, 0.006, -0.0021, -0.0008, -0.0022, -0.007, -0.0014, -0.0142, -0.005, 0.0096, 0.001,
		0.0043, -0.0056, -0.0111, -0.0023, 0.0085, 0.0081, -0.0034, 0.0049, -0.0003, 0.0046, 0.0109, 0.0199, -0.0518,
		0.017, 0.053, 0.0258, 0.1636, -0.1245, -0.121, -0.0483, 0.2626, -0.1065, -0.1093, -0.0471, -0.0187, 0.0109,
		0.0114, -0.0031, 0.1128, -0.0248, -0.0425, -0.0465, -0.1608, 0.1477, 0.0077, -0.1503, 0.0565, 0.028, -0.0045,
		0.0061, -0.0048, -0.0044, -0.0023, -0.0041, 0.0062, -0.0077, 0.0054, 0.0075, 0.015, 0.0131, -0.0214, -0.0495,
		-0.0424, 0.0182, -0.0193, 0.084, 0.0063, -0.0312, -0.0097, -0.0005, -0.014, -0.1074, -0.0361, 0.0645, -0.16,
		0.1644, -0.1276, -0.0464, 0.1231, 0.004, -0.008, 0.022, 0.0092, 0.017, 0.013, 0.0219, 0.0098, 0.0025, 0.0233,
		-0.0028, -0.0256, -0.0383, -0.0417, -0.0203, 0.0212, 0.0243, -0.0317, 0.0168, -0.0199, -0.021, -0.0347, -0.0291,
		0.0162, 0.0106, -0.0056, -0.0288, -0.0025, -0.0405, -0.0103, 0.0404, 0.0213, 0.0803, -0.0197, -0.0582, 0.0381,
		0.0108, -0.0164, -0.0155, 0.0629, -0.0198, 0.0084, 0.0077, 0.0241, 0.0187, 0.0074, -0.0048, 0.0119, 0.0024,
		-0.0048, -0.0034, 0.0077, 0.0087, -0.0051, 0.0108, 0.0171, -0.04, -0.0006, 0.0849, -0.191, 0.0011, 0.088,
		-0.0246, -0.0193, -0.0555, -0.0421, -0.0082, 0.0664, 0.0245, -0.0306, 0.029, -0.0078, 0.0444, -0.0084, 0.0763,
		-0.0429, 0.0163, 0.0681, 0.0371, 0.0179, -0.0963, -0.2137, -0.1045, 0.0614, 0.0491, 0.0154, -0.0124, 0.1207,
		-0.0667, 0.0306, 0.0029, 0.0056, 0.0144, -0.0014, -0.0033, 0.0041, -0.0117, -0.0047, 0.0436, -0.0686, -0.0077,
		0.0036, -0.0045, -0.0051, -0.0032, -0.0049, -0.0361, -0.0534, -0.0004, 0.0171, 0.0607, 0.0533, -0.0228, 0.0007,
		0.047, 0.1576, 0.0064, -0.0404, 0.0208, 0.0252, -0.0301, 0.0101, 0.0227, 0.0151, 0.115, -0.0899, -0.0121,
		0.0103, -0.004, -0.008, 0.0003, 0.0023, 0.0049, -0.0054, 0.0964, 0.0565, -0.106, 0.0703, 0.096, -0.0986, 0.0264,
		0.035, -0.0349, -0.0382, 0.0127, 0.0269, 0.018, 0.0126, 0.0058, -0.006, 0.0009, 0.0104, 0.0117, -0.0036, 0.0118,
		0.0018, 0.0025, -0.003, -0.012, -0.0049, -0.0014, 0.0023, 0.0044, 0.0075, -0.0012, 0.0032, 0.0091, -0.0054,
		0.0061, -0.0079, -0.0036, -0.0079, -0.0167, 0.0063, 0.0011, 0.0194, 0.0027, 0.0855, -0.0032, 0.0476, -0.0014,
		0.0888, -0.0148, 0.0767, -0.0449, 0.0163, 0.0021, -0.0067, 0.0095, -0.0109, 0.0147, 0.0013, -0.0056, 0.0105,
		-0.0081, -0.0194, -0.0048, -0.0065, 0.0193, 0.0102, 0.0025, 0.0076, 0.0437, -0.1275, 0.0071, 0.1128, -0.0337,
		0.0362, -0.0407, 0.0854, -0.0552, -0.0357, -0.012, -0.0033, -0.0016, -0.0024, 0.0065, -0.0113, -0.0126, 0.0085,
		-0.0061, 0.013, -0.0024, -0.0056, -0.0054, -0.0063, 0.0402, -0.1087, -0.0038, -0.0087, -0.0656, 0.0195, -0.0104,
		-0.0952, 0.0892, 0.0367, -0.042, 0.0353, -0.0325, -0.0026, 0.0246, 0.0118, 0.0262, -0.0958, -0.0594, 0.0932,
		0.0132, -0.0436, 0.0031, -0.0124, 0.0049, -0.0111, -0.0027, -0.0116, -0.0082, -0.0131, -0.0342, 0.0373, -0.0069,
		0.1507, 0.0169, 0.0359, 0.0288, -0.0097, -0.0096, -0.0475, -0.0473, -0.0242, -0.0262, 0.0132, -0.0056, -0.0077,
		0.0148, -0.0204, 0.005, -0.0132, -0.0062, 0.0082, 0.003, 0.0259, 0.0233, 0.0099, 0.0015, -0.0032, -0.0011,
		0.0029, 0.0042, 0.0029, 0.0019, -0.0101, 0.0069, 0.054, -0.0202, 0.0724, 0.0215, -0.0076, -0.1534, 0.0426,
		0.0167, 0.0471, 0.0116, 0.0094, -0.0079, -0.0036, 0.0034, 0.005, 0.0119, 0.0098, -0.0752, -0.0573, 0.0611,
		0.0296, -0.0233, 0.0105, -0.0023, 0.0083, -0.0095, -0.0049, 0.007, 0.0075, -0.0004, -0.0487, -0.0214, 0.0433,
		0.0095, -0.0062, -0.0377, 0.078, -0.0707, 0.1271, -0.1345, -0.0071, 0.0308, -0.015, -0.0973, 0.0165, 0.0197,
		0.045, -0.0675, -0.0453, -0.0175, 0.0291, -0.0072, -0.0799, 0.0269, -0.0401, -0.0299, 0.014, 0.0029, 0.0034,
		0.001, 0.0004, 0.0093, -0.0323, 0.0042, 0.0095, 0.0089, -0.0173, 0.0139, -0.0181, -0.0444, 0.0152, 0.1295,
		-0.0338, -0.1074, 0.0261, -0.0161, -0.0009, -0.0041, 0.0036, 0.0042, -0.0073, 0.0054, 0.0514, -0.0366, 0.0879,
		0.1096, 0.0755, 0.0672, 0.013, -0.0066, -0.0172, -0.0035, 0.0029, 0.0111, 0.0041, 0.0078, 0, -0.0191, -0.0162,
		-0.0676, 0.0042, 0.0673, 0.0091, -0.005, -0.007, 0.0022, 0.0008, -0.0044, 0, -0.0046, -0.0148, -0.0867, -0.0397,
		-0.0104, 0.0914, -0.0325, -0.0129, -0.0021, -0.0046, -0.0048, -0.0008, -0.0017, -0.0089, 0.0369, 0.0534, -0.048,
		0.0313, -0.0049, -0.0026, 0.0067, 0.008, -0.001, 0.0158, -0.0078, 0.1018, 0.0539, 0.0167, -0.0966, 0.0915,
		0.1038, 0.0007, 0.1191, 0.0108, -0.0636, -0.0992, -0.0142, -0.0177, -0.0044, 0.0012, -0.0199, 0.0051, 0.0125,
		-0.0083, 0.001, -0.0033, 0.0089, 0.1637, -0.1298, -0.2429, -0.202, 0.1145, -0.0187, -0.0543, -0.0013, 0.105,
		-0.1058, -0.1163, 0.0378, 0.1271, 0.088, 0.0535, 0.0562, 0.0707, 0.0631, -0.0135, 0.0011, 0.0088, -0.0332,
		0.0721, 0.0039, 0.0326, -0.1084, 0.2568, -0.0462, 0.15, 0.0101, -0.2046, -0.0153, 0.0318, 0.0518, 0.0146,
		0.0058, 0.0095, 0.0161, 0.0003, -0.0028, 0.009, 0.001, -0.0225, 0.0566, -0.0165, 0.0356, 0.0249, 0.0128, 0.0104,
		0.0029, -0.005, 0.0051, 0.0092, 0.0202, -0.065, -0.0999, 0.0164, -0.053, 0.0145, -0.0062, 0.0003, -0.0005,
		-0.0003, -0.0072, -0.0008, -0.0036, 0.0043, -0.0076, -0.0089, -0.0144, 0.0447, -0.1397, -0.1069, -0.0878,
		-0.0014, 0.0284, 0.0271, 0.0249, 0.0167, 0.0001, 0.0273, -0.0053, 0.0061, 0.006, -0.0009, -0.0518, -0.0371,
		-0.0102, 0.022, 0.0277, 0.0068, 0.0179, -0.0257, -0.0157, -0.0006, 0.0016, 0.0086, 0.0047, -0.0128, 0.0042,
		-0.0024, 0.0505, -0.0333, -0.0678, 0.0292, -0.117, -0.0342, 0.0454, 0.0174, 0.0232, 0.0109, -0.0578, -0.0302,
		-0.0053, 0.0341, 0.0428, -0.0264, -0.0265, 0.0239, -0.033, 0.0183, -0.0247, -0.0741, 0.0812, 0.0031, 0.0464,
		-0.0778, -0.137, 0.0557, -0.0262, 0.0433, -0.0077, -0.0447, 0.0093, -0.0349, -0.0279, 0.0165, 0.0033, -0.0204,
		-0.0013, 0.0128, -0.0135, 0.0137, -0.0267, 0.009, 0.0456, -0.0055, -0.0383, -0.0528, -0.0182, -0.0232, 0.0123,
		-0.018, -0.0056, 0.0058, 0.0024, -0.0061, -0.0011, 0.0042, 0.0008, -0.0229, 0.0786, -0.1301, -0.07, -0.0387,
		-0.0136, -0.0287, 0.0095, 0.0034, 0.0797, -0.0955, 0.0827, -0.0228, -0.012, -0.0051, 0.0089, -0.0145, -0.015,
		-0.0003, 0.0126, 0.0168, -0.0041, -0.0001, -0.0047, 0.0018, 0.0337, 0.0278, -0.0839, -0.0437, 0.0012, -0.0015,
		0.0031, 0.0344, -0.0073, -0.0153, -0.0127, 0.0092, 0.0048, -0.0065, 0.0044, 0.0093, 0.0047, 0.0121, 0.0053,
		-0.0058, 0.0008, -0.0247, -0.0742, -0.0021, 0.0033, 0.0101, 0.0241, 0.043, 0.0878, 0.0823, 0.0523, 0.0451,
		0.0908, -0.0044, 0.0394, -0.03, -0.007, 0.003, -0.0147, 0.0277, 0.0259, -0.0375, 0.0074, 0.0233, -0.0272,
		-0.0005, -0.0005, -0.0028, -0.0033, 0.0012, -0.0002, 0.0027, -0.0101, 0.0033, -0.0085, 0.0051, -0.0002, -0.0011,
		-0.0024, -0.0009, -0.001, -0.0004, 0.011, 0.0012, -0.0027, 0.0086, 0.0019, -0.0025, 0.0041, -0.0103, 0.0016,
		-0.0105, -0.0036, -0.0023, -0.0027, -0.0056, -0.0046, -0.0027, 0.0048, 0.0045, 0.0161, -0.0112, -0.0164, 0.0112,
		-0.0263, -0.0513, -0.0667, 0.0234, 0.0038, -0.0139, 0.0066, 0.0047, 0.0006, -0.003, -0.0175, -0.0605, -0.0372,
		0.0057, -0.0026, -0.0054, 0.0021, 0.0026, -0.0027, -0.0023, -0.0023, -0.001, 0.0079, 0.0012, -0.0004, -0.0014,
		-0.0058, -0.0038, 0.0066, -0.0019, -0.0008, 0.0065, -0.0026, -0.0031, 0.0027, -0.0006, 0.0129, 0.0095, -0.1288,
		-0.1465, -0.0971, 0.1608, -0.0399, -0.0314, -0.015, -0.0044, -0.0028, -0.0061, -0.005, -0.0024, -0.0012,
		-0.0006, 0.0046, 0.0033, 0.0003, 0.0025, 0.0013, -0.0097, -0.0838, 0.0899, -0.0585, -0.0182, -0.0049, 0.015,
		0.0206, 0.0297, 0.0175, 0.004, -0.0105, -0.0109, 0.0137, 0.0125, -0.0211, 0.0037, -0.0221, 0.0176, 0.0176,
		0.0043, -0.0109, -0.0022, 0.0119, 0.0026, -0.0052, -0.013, -0.0097, -0.0011, -0.0198, -0.0079, -0.001, -0.0087,
		-0.0178, -0.0029, 0.0064, -0.0114, -0.0168, 0.0055, 0.0239, 0.03, 0.0155, 0.0601, -0.0433, -0.0303, 0.0113,
		0.0451, 0.0084, 0.0074, -0.0042, -0.0276, -0.0136, 0.0025, 0.0016, 0.0082, -0.0099, -0.0042, 0.0017, 0.0076,
		0.005, 0.0033, -0.0013, -0.0024, 0.003, -0.0137, 0.0087, 0.0023, -0.0004, -0.0017, -0.0027, 0.0082, -0.0149,
		0.0084, 0.0027, 0.0136, -0.0129, -0.014, -0.0172, 0.0697, -0.0131, -0.0014, -0.0282, 0.0698, 0.0527, -0.0003,
		0.0246, -0.0203, -0.0006, 0.0067, 0.0278, -0.0198, -0.0131, -0.0064, 0.0136, 0.0229, -0.0032, -0.0145, 0.0151,
		0.0115, -0.0405, -0.0332, -0.017, -0.079, 0.0201, -0.054, 0.0501, -0.0474, 0.0134, -0.0631, -0.0092, 0.0261,
		0.0046, 0.0274, 0.0437, -0.0403, -0.0086, 0.0124, 0.0058, 0.0077, 0.005, 0.0003, -0.0007, 0.0061, 0.0004,
		0.0012, -0.1025, -0.0499, 0.007, -0.1255, -0.0162, -0.0389, -0.0046, -0.0337, -0.0223, 0.0455, 0.0634, 0.0023,
		-0.0491, -0.0463, -0.0152, -0.0372, -0.0083, 0.015, -0.0028, -0.0206, 0.0191, 0.0047, -0.0179, -0.0163, 0.0056,
		-0.0135, 0.0522, -0.0221, -0.1084, -0.1026, 0.087, -0.0137, 0.1348, -0.0509, 0.0169, 0.0078, 0.0136, 0.0347,
		0.0143, 0.0324, 0.0806, -0.0285, -0.0206, -0.0379, -0.1149, -0.081, 0.0045, -0.0094, 0.0162, 0.0435, 0.0724,
		0.1047, 0.0485, 0.1355, 0.0322, -0.0078, 0.0914, 0.1174, 0.2122, -0.1439, 0.073, 0.0296, -0.0947, 0.0151,
		0.0174, -0.0311, 0.0311, -0.0073, 0.006, 0.0106, -0.0072, -0.012, 0.0163, -0.0293, -0.026, -0.0102, 0.0078,
		-0.0177, -0.0141, -0.0382, 0.081, 0.0888, 0.0085, 0.0074, -0.0054, 0.0357, -0.0763, -0.0422, -0.0016, -0.0512,
		-0.0176, -0.0532, -0.092, 0.062, 0.0543, 0.0044, 0.0122, -0.0117, 0.004, -0.0323, -0.0036, -0.006, 0.0157,
		0.0111, 0.0203, 0.0263, 0.0034, 0.0548, 0.0318, -0.0061, 0.0338, -0.0233, -0.0103, -0.035, 0.0029, -0.0042,
		-0.0106, -0.0204, -0.0191, -0.0102, 0.0445, -0.0336, 0.0344, -0.0149, 0.0478, 0.0377, 0.0188, 0.0078, 0.0384,
		-0.0466, -0.0167, 0.0009, 0.0038, 0.0068, -0.0331, 0.02, -0.0139, -0.0282, -0.0006, -0.0033, -0.0106, 0.0081,
		0.0016, -0.0168, 0.008, 0.0208, 0, 0.0245, 0.0008, 0.0031, -0.0019, -0.0115, 0.005, -0.008, 0.0666, 0.0356,
		-0.0697, 0.0794, -0.2007, 0.0898, -0.1319, -0.0216, 0.0004, 0.0314, 0.0136, -0.1876, 0.0096, 0.2238, 0.099,
		-0.015, -0.0176, 0.0404, -0.0057, -0.0415, 0.0071, -0.0527, -0.0673, 0.0046, -0.0016, 0.0121, 0.0623, -0.0118,
		-0.0088, -0.0176, 0.0128, 0.0145, 0.0246, 0.0063, 0.0041, 0.0016, -0.0003, -0.0094, 0.0062, -0.0005, -0.005,
		0.0043, 0.0006, -0.0057, 0.0027, -0.0079, -0.0007, -0.0084, 0.0016, -0.0117, -0.0019, 0.0001, 0.002, -0.0027,
		0.0162, -0.0033, -0.0301, 0.0297, -0.0032, -0.0048, -0.0031, 0.0065, -0.0002, -0.0336, 0.0215, 0.0137, 0.0091,
		-0.0412, -0.032, 0.035, -0.0619, 0.0194, -0.1686, 0.0033, 0.0633, 0.0578, -0.0047, -0.0344, -0.0545, 0.0058,
		0.006, 0.0034, 0.043, 0.0462, -0.0475, 0.0427, -0.0297, 0.0153, 0.0008, 0.0017, 0.0098, 0.023, -0.0722, 0.0719,
		0.0519, 0.1827, 0.0062, -0.0348, -0.0127, -0.052, 0.0307, 0.0682, 0.0134, 0.0053, -0.0119, 0.0179, 0.0067,
		-0.0082, 0.0182, -0.0107, 0.0067, -0.0152, 0.007, 0.0048, -0.0128, -0.0071, 0.0145, 0.0173, -0.0529, 0.0384,
		0.0774, 0.1385, -0.0455, -0.0594, -0.1743, 0.0299, 0.0716, 0.1269, 0.0334, -0.0576, 0.0088, 0.0111, 0.0097,
		0.0107, -0.0076, -0.0129, -0.0198, -0.0013, -0.0094, -0.0228, -0.0151, -0.0183, -0.0185, -0.0033, -0.0164,
		-0.0213, -0.0096, -0.0023, -0.0107, -0.002, -0.0004, 0.0015, 0.0054, 0.0004, 0.0054, 0.0022, 0.0003, -0.0126,
		-0.0076, -0.0005, -0.0125, -0.003, 0.0244, 0.022, 0.0107, 0.0227, 0.0443, -0.0013, 0.1629, -0.0943, -0.0349,
		0.134, -0.0155, -0.1771, -0.2168, 0.0927, -0.0412, -0.0296, -0.0794, -0.0615, -0.0832, -0.0256, 0.0051, 0.0038,
		0.004, 0.0027, 0.0019, -0.0021, 0.0381, 0.0169, 0.0045, 0.0023, -0.0664, -0.0038, 0.0568, -0.2895, 0.0286,
		0.0586, 0.0752, 0.0478, -0.0542, 0.0447, 0.0336, -0.091, 0.0565, 0.0112, 0.026, 0.0351, 0.0264, -0.0019,
		-0.0226, -0.0108, 0.0112, 0.0161, 0.0091, -0.021, 0.0901, 0.1605, -0.089, -0.0023, -0.0501, -0.1548, 0.207,
		-0.165, 0.1514, -0.1021, 0.0938, -0.0636, 0.0994, -0.0698, 0.0072, 0.0321, 0.0194, 0.0211, 0.0316, -0.0603,
		0.0412, 0.0144, -0.0536, -0.0019, 0.0037, 0.0321, -0.0234, 0.0836, -0.0265, -0.0156, -0.0756, 0.1281, -0.11,
		0.05, 0.0424, -0.0311, -0.1123, -0.0118, 0.0184, -0.0661, -0.0674, -0.0287, -0.019, -0.0163, -0.0316, -0.0135,
		0.006, 0.0269, -0.0011, -0.0081, -0.0029, -0.002, -0.0119, -0.0621, 0.0415, 0.0118, -0.0454, -0.1154, -0.121,
		0.0531, 0.0091, 0.0336, -0.0044, -0.0054, 0.0088, 0.0054, -0.0047, -0.0064, -0.0398, -0.0064, 0.0029, -0.0591,
		-0.0101, 0.0845, -0.0627, -0.0334, -0.0761, 0.0455, -0.0414, -0.0007, -0.0312, -0.0797, 0.0504, 0.1484, 0.0273,
		0.009, 0.0167, -0.0748, 0.0348, 0.0277, -0.0759, 0.0346, -0.0035, -0.0374, 0.0453, -0.0431, 0.0181, 0.0098,
		0.0284, -0.154, 0.0348, 0.1717, 0.1249, -0.0932, 0.0844, 0.2991, 0.2839, 0.1751, 0.0087, -0.042, -0.0171,
		-0.0124, 0.0187, -0.0035, 0.015, -0.024, 0.0154, 0.0246, -0.0983, -0.1406, -0.0264, -0.0712, -0.0331, -0.0154,
		0.0072, 0.0479, 0.0472, 0.0087, -0.0545, -0.1512, 0.3753, -0.1076, 0.2588, 0.0715, -0.2132, -0.0634, -0.0484,
		-0.0127, -0.0218, -0.0298, -0.0034, 0.042, -0.1042, -0.1186, -0.0086, 0.105, 0.1105, -0.1543, 0.0039, 0.0957,
		-0.0669, 0.0028, 0.0124, 0.0037, 0.0026, -0.0115, -0.001, 0.0077, -0.0001, -0.0043, 0.0529, -0.0719, 0.0528,
		-0.0895, -0.0451, 0.2292, -0.0653, -0.0278, -0.0132, 0.0025, -0.0086, -0.0018, -0.002, 0.0071, 0.001, -0.0124,
		-0.0069, -0.0004, -0.0011, -0.006, 0.0053, -0.0021, 0.0061, -0.0014, 0.0033, 0.0003, 0.0001, -0.0009, -0.0046,
		0.0035, 0.0031, 0.0034, 0.0003, -0.0099, 0.0074, -0.0075, 0.0009, -0.0062, 0.0092, -0.0024, 0.0018, -0.0012,
		0.0031, 0.0038, 0.0061, -0.0011, 0.01, 0.0024, 0.0076, 0.0193, -0.1072, -0.0786, 0.1586, -0.1201, 0.1211,
		0.0795, -0.217, -0.3128, 0.2431, -0.1976, -0.1737, -0.0141, 0.0265, -0.0495, -0.0148, 0.0075, -0.0007, -0.0322,
		0.0239, -0.0109, 0.0032, 0.0078, -0.0129, -0.0166, 0.0378, -0.0542, 0.0345, 0.0146, 0.0477, 0.1706, -0.043,
		-0.0025, -0.1322, 0.2352, 0.1209, 0.1565, 0.1532, 0.1253, 0.0979, 0.0962, 0.1007, 0.1558, 0.0249, -0.0226,
		0.0069, 0.0103, -0.0209, 0.0215, -0.0272, 0.0145, -0.0054, 0.0161, -0.0676, -0.0173, 0.0649, 0.1159, -0.2122,
		-0.1445, 0.1437, -0.0548, 0.0915, 0.1242, 0.0668, -0.0378, -0.0518, -0.1027, -0.1698, -0.1706, -0.1236, -0.0631,
		0.0472, 0.0961, -0.0067, 0.0078, 0.0031, -0.0077, 0.0274, 0.0509, -0.1112, 0.0211, -0.0064, -0.0384, 0.0629,
		0.0319, 0.0525, 0.0208, 0.082, 0.1212, -0.1798, 0.1965, 0.1535, -0.2356, -0.1723, -0.2064, -0.1146, -0.1114,
		-0.0889, -0.0214, 0.0106, -0.0039, 0.0037, 0.0072, 0.0031, -0.001, -0.008, 0.0069, -0.0074, -0.0173, -0.0085,
		0.0199, -0.104, -0.0794, -0.0383, -0.0136, -0.0113, -0.0009, 0.0072, 0.1552, -0.1167, 0.0577, 0.0217, 0.028,
		-0.0102, 0.0283, -0.0107, -0.0229, -0.1303, -0.2114, -0.1765, -0.1727, 0.1327, 0.1708, -0.003, -0.2701, -0.0286,
		-0.0141, 0.0166, -0.0103, -0.0084, -0.0057, -0.0068, 0.0051, 0.0003, -0.0028, 0.0026, -0.0142, 0.0069, 0.0742,
		-0.0748, -0.148, -0.1296, 0.0683, 0.0372, -0.008, -0.0169, 0.121, -0.1718, -0.0098, -0.061, 0.0159, 0.029,
		0.0281, 0.0848, -0.0582, -0.1301, 0.409, 0.0361, 0.0791, 0.1689, -0.0214, -0.127, -0.1351, 0.016, 0.0088,
		-0.1213, 0.0328, -0.05, -0.1434, -0.0207, 0.0247, -0.005, -0.0505, 0.021, 0.0578, 0.0105, -0.0175, -0.067,
		0.0026, 0.1225, 0.0472, 0.097, 0.1233, -0.0539, 0.0527, 0.004, -0.0421, 0.005, 0.0917, -0.1123, -0.1895,
		-0.0879, -0.2365, -0.0372, -0.1135, 0.1786, -0.0135, -0.0623, -0.105, 0.0675, -0.0673, -0.0935, -0.099, -0.1816,
		0.2325, 0.0688, -0.0581, 0.0488, -0.0473, -0.0064, 0.0534, -0.0821, -0.0322, -0.016, 0.0128, -0.01, 0.0134,
		-0.0414, -0.0058, 0.0011, 0.1596, 0.001, 0.0367, 0.0474, 0.014, -0.1676, 0.2041, -0.1733, -0.1447, 0.0445,
		0.3832, 0.3349, 0.1575, 0.0325, -0.074, -0.0811, -0.0714, -0.0239, 0.0212, -0.008, 0.0252, 0.0045, 0.0127,
		-0.0019, 0.0044, 0.0014, 0.0147, -0.0022, 0.0196, 0.0255, -0.0376, -0.0137, 0.0047, 0.0051, -0.0127, 0.0077,
		-0.0062, -0.0169, -0.0733, -0.0341, 0.0253, -0.0418, 0.0904, 0.1562, -0.1066, 0.316, 0.0321, -0.1226, 0.2583,
		0.0016, 0.0128, 0, -0.0092, -0.0117, -0.0048, 0.0041, -0.009, -0.1951, 0.1762, 0.1131, 0.0265, -0.1505, -0.1768,
		0.0824, 0.1983, -0.1358, -0.207, 0.079, -0.0775, 0.1101, -0.018, 0.0523, 0.0329, 0.0273, 0.1545, 0.0041, 0.1569,
		0.0122, 0.0633, -0.0501, -0.0441, -0.0394, 0.0383, 0.0066, 0.0045, -0.001, -0.0088, 0.0182, 0.01, -0.0056,
		0.1407, 0.0397, -0.0831, -0.0327, 0.1381, 0.0991, -0.074, 0.0252, -0.0611, 0.0275, -0.0801, -0.0236, 0.0077,
		-0.0105, -0.0266, -0.0154, 0.006, 0.0364, 0.0336, 0.0013, -0.0202, 0.0114, 0.0092, -0.0152, 0.0044, 0.015,
		0.0043, 0.006, 0.0075, 0.0053, -0.002, -0.0117, 0.0042, 0.0018, -0.0057, 0.0018, -0.0013, 0.0065, 0.012,
		-0.0045, -0.0025, -0.0118, 0.0038, 0.0048, 0.0038, -0.0013, -0.0012, -0.007, -0.0011, 0.0011, -0.0069, 0.0011,
		-0.0015, 0.0101, -0.0036, -0.0018, 0.0104, -0.0011, 0.0203, -0.0174, 0.0279, -0.0065, 0.0222, 0.0231, -0.0934,
		-0.0075, -0.1277, -0.2724, -0.0212, -0.0106, 0.179, -0.2418, -0.0444, -0.1535, -0.2417, 0.0117, 0.0093, -0.0076,
		0.038, 0.0203, 0.0243, -0.1581, 0.0506, -0.0351, -0.0787, -0.0197, -0.0428, 0.2048, -0.0384, 0.0915, -0.135,
		-0.0232, 0.0125, 0.0211, 0.0029, 0.0129, 0.0107, -0.0117, 0.0264, -0.0053, 0.0038, -0.0513, -0.1681, -0.2291,
		-0.1674, 0.1426, -0.0555, 0.1581, 0.0834, 0.0202, 0.0883, 0.0923, 0.0175, 0.1917, 0.0257, 0.2474, 0.0314,
		0.2076, 0.0241, 0.0758, -0.0714, -0.0749, -0.0416, 0.0092, -0.1224, 0.0206, -0.0793, 0.0188, 0.0126, -0.0204,
		-0.0069, -0.0737, 0.0241, 0.0782, -0.0031, -0.0184, -0.0166, -0.1927, 0.1045, -0.18, 0.0113, 0.0973, 0.122,
		-0.0507, -0.0406, 0.0857, -0.1907, -0.0147, -0.1115, -0.0085, -0.0496, 0.064, -0.0195, -0.2399, 0.0326, 0.0226,
		0.0316, -0.1151, 0.202, -0.2646, 0.0483, 0.0093, -0.0031, 0.0232, 0.0235, -0.0381, 0.0081, 0.0618, 0.0311,
		0.0464, -0.0107, 0.1309, -0.1268, 0.1208, -0.1029, 0.0199, -0.005, -0.0189, -0.0562, -0.0032, 0.011, -0.0003,
		0.0178, 0.0048, 0.021, 0.0535, -0.015, 0.0063, -0.0538, 0.0592, -0.0807, 0.0799, -0.0381, -0.1505, 0.1038,
		-0.0124, -0.0108, 0.0004, -0.0509, -0.0083, 0.006, 0.009, 0.0058, -0.0142, 0.0105, -0.0007, -0.0031, -0.0016,
		-0.0226, -0.0068, -0.0537, -0.006, 0.0102, -0.0068, 0.0855, -0.0816, -0.0326, 0.0678, -0.0631, 0.0363, -0.1911,
		0.0142, -0.0185, 0.2721, 0.1551, -0.0482, 0.02, -0.0151, -0.005, 0.0056, 0.015, -0.0269, 0.0064, 0.0176, 0.0101,
		-0.1098, 0.0495, -0.1584, -0.0259, -0.0958, -0.0124, -0.1244, 0.0661, -0.0571, 0.0052, 0.0362, 0.0029, 0.0374,
		-0.0426, 0.107, -0.0341, -0.0863, 0.0328, 0.0946, 0.0545, 0.1394, -0.1227, -0.1853, -0.1175, 0.0394, 0.0402,
		0.1564, -0.0688, -0.0188, -0.0061, -0.0038, 0.0163, -0.0035, 0.0161, 0.0057, 0.0087, -0.0162, 0.0377, 0.0794,
		0.0197, 0.0031, 0.0096, 0.0111, -0.0057, 0.0438, -0.024, -0.043, 0.0975, -0.0607, -0.162, 0.0549, 0.1301,
		-0.0253, 0.0076, 0.078, 0.0272, -0.0525, -0.0807, -0.1064, 0.1185, 0.1539, -0.204, 0.0205, 0.0285, -0.0312,
		0.0078, -0.0085, 0.1437, 0.1717, 0.0456, 0.1982, 0.0525, -0.1481, 0.1528, -0.1331, 0.0479, -0.0372, -0.0073,
		0.0465, 0.0037, 0.009, 0.0373, -0.0743, -0.0132, 0.0214, 0.0362, 0.1033, -0.0879, 0.0089, 0.0246, 0.019,
		-0.0007, 0.0051, 0.0097, 0.0137, -0.0001, -0.0057, 0.0012, 0.0618, -0.032, 0.1271, -0.0149, -0.0053, 0.0256,
		0.0521, -0.0009, -0.0474, -0.0179, -0.1632, 0.0501, 0.0989, 0.0524, 0.0772, -0.0562, -0.0031, -0.02, 0.0164,
		-0.0118, 0.0118, 0.0204, 0.021, -0.0074, 0.0039, -0.0043, -0.0076, -0.0095, -0.0342, -0.0107, 0.0126, 0.014,
		0.0098, -0.0211, -0.0031, 0.0084, 0.0121, -0.0187, -0.0062, -0.0044, 0.0123, 0.0016, -0.0741, -0.0485, 0.0452,
		0.0251, -0.0674, 0.1032, 0.0812, -0.0106, 0.2232, -0.035, 0.177, -0.0371, 0.1284, -0.0615, 0.0491, -0.0583,
		-0.0556, -0.0726, -0.0643, -0.09, -0.0404, -0.0835, -0.1539, -0.0324, -0.0711, -0.0456, 0.0385, 0.0355, -0.0389,
		0.0365, 0.1383, -0.1819, 0.1073, 0.0035, -0.0721, -0.0213, -0.0081, 0.0087, 0.0005, -0.0006, 0.0014, 0.0085,
		-0.0013, 0.0034, 0.0022, -0.0082, 0.0133, 0.0038, -0.0192, 0.0208, 0.008, 0.0205, 0.0175, -0.0117, 0.0136,
		-0.0096, -0.0024, -0.0037, 0.0097, -0.0038, -0.0023, -0.003, 0.0023, -0.0134, 0.0108, -0.0257, -0.0085, -0.0376,
		-0.0305, 0.0049, 0.0052, -0.0053, -0.0046, -0.0058, -0.0072, -0.0005, 0.0021, -0.0137, -0.0087, -0.0104,
		-0.0029, -0.0026, 0.0106, 0.0013, -0.0093, 0.0066, 0.0035, 0.0141, 0.0114, 0.0047, 0.0017, 0.0033, 0.0074,
		0.0039, -0.0039, -0.0017, -0.0166, -0.0078, 0.008, 0.0032, 0.0011, 0.0001, 0.0167, -0.0089, -0.0036, 0.0042,
		-0.0083, 0.0167, -0.011, -0.0108, -0.0146, 0.0131, -0.0069, 0.0063, -0.009, 0.0128, 0.021, 0.0008, -0.0027,
		-0.0035, 0.0025, -0.0081, 0.0152, 0.0097, 0.0061, 0.0303, 0.0049, -0.0082, 0.0157, -0.0006, -0.0111, -0.0013,
		-0.0023, 0.0015, -0.0114, 0.0023, -0.0124, -0.0198, 0.0042, 0.0075, 0.0071, 0.0068, -0.0005, -0.0083, 0.0007,
		0.0044, 0.0037, -0.0028, -0.0167, 0.0202, 0.0143, 0.0009, 0.0119, 0.0173, 0.0059, 0.0033, 0.0107, -0.0091,
		-0.0126, 0.0068, -0.0053, 0.0018, -0.0056, 0.0009, -0.0021, 0.0057, -0.0086, 0.0135, -0.0131, 0.0007, 0.0059,
		-0.0026, 0.0038, -0.0041, 0.0032, 0.0042, -0.0116, -0.0034, -0.1383, 0.2626, -0.0871, 0.0662, -0.0998, -0.026,
		-0.3983, -0.2899, 0.2178, -0.3007, 0.0215, 0.0743, -0.0879, -0.0731, 0.03, 0.0472, 0.0646, 0.0324, -0.0086,
		-0.0347, -0.0091, -0.0281, 0.0014, -0.0254, -0.0178, -0.0438, -0.0008, -0.0385, -0.0194, -0.0242, -0.001,
		0.0005, -0.0074, 0.0771, 0.0469, -0.0368, 0.007, -0.0025, 0.0141, -0.0135, -0.0022, -0.064, 0.0298, 0.0643,
		-0.1392, -0.0007, -0.0174, 0.02, -0.0064, -0.0126, 0.0598, -0.016, 0.0228, -0.0008, 0.0051, 0.0121, 0.0009,
		-0.0064, -0.0077, 0.0048, 0.0119, 0.0017, -0.0057, 0.0205, -0.0055, -0.0134, 0.0168, -0.0628, -0.0951, 0.223,
		-0.0996, -0.0614, -0.0629, -0.1636, -0.0219, -0.0638, 0.1544, -0.0816, 0.1046, -0.2008, -0.0044, -0.0271,
		-0.1285, 0.141, -0.1046, 0.038, -0.0901, -0.2522, 0.1091, -0.0707, 0.0176, -0.091, 0.0425, 0.0047, -0.0067,
		0.0021, 0.0079, -0.0013, 0.0031, 0.0038, -0.0078, -0.0074, 0.0295, 0.0004, -0.0728, 0.0124, 0.0318, 0.1079,
		0.1594, 0.209, -0.0966, -0.0258, -0.3596, 0.4241, -0.1611, 0.0371, -0.0044, 0.0195, 0.0586, 0.0228, -0.0101,
		0.0243, -0.0135, -0.0089, -0.0041, -0.0013, -0.0037, -0.0032, 0.0223, 0.0886, 0.0835, -0.0377, -0.0982, -0.0094,
		-0.0399, 0.0434, 0.2876, -0.0428, 0.0311, -0.151, -0.0548, 0.0193, -0.0004, 0.0168, -0.0201, 0.0048, 0.0154,
		-0.0424, 0.0412, 0.0321, 0.0234, 0.0562, 0.0008, -0.0451, 0.0083, -0.0029, -0.0017, -0.0031, 0.0006, 0.0064,
		-0.0087, 0.0035, -0.012, 0.113, -0.1052, -0.0726, -0.1625, 0.0757, 0.2717, 0.0905, -0.1747, -0.0694, -0.0096,
		-0.0478, -0.0599, -0.0977, 0.2003, -0.0042, 0.0366, -0.0143, 0.0045, 0.0817, -0.0027, 0.0446, -0.0365, 0.0122,
		-0.0711, -0.0967, 0.0223, 0.0016, 0.0133, 0.012, 0.0112, 0.0042, 0.0106, 0.09, 0.0074, 0.1668, -0.0661, 0.0084,
		-0.0292, -0.0439, -0.01, 0.0539, 0.0303, 0.0071, -0.0253, -0.0209, -0.0917, 0.0351, -0.0321, 0.0244, -0.1531,
		0.1578, -0.1154, 0.1362, -0.1643, -0.0655, -0.2014, -0.0528, -0.0761, 0.0259, 0.0114, -0.0581, 0.0329, -0.0002,
		-0.0832, -0.0568, 0.1024, -0.0355, 0.0639, 0.0876, -0.0026, -0.0154, 0.1303, -0.0669, 0.0136, 0.026, 0.0443,
		-0.1961, 0.097, 0.0283, -0.2318, -0.1234, -0.0976, 0.0099, 0.1041, 0.0193, -0.0061, 0.006, 0.0052, -0.0153,
		-0.0023, 0.0089, 0.0027, 0.0163, 0.0128, 0.1219, -0.0878, 0.0293, 0.0814, -0.059, 0.0258, -0.1049, -0.0052,
		-0.012, 0.0408, -0.1004, 0.024, -0.0133, 0.0282, -0.0241, 0.0203, 0.0446, -0.0298, -0.0687, 0.0206, 0.026,
		-0.1217, 0.04, -0.0017, -0.1418, -0.0191, 0.0088, -0.1867, -0.0026, 0.0334, -0.0848, 0.1198, 0.0654, 0.0671,
		0.0503, 0.0067, -0.002, -0.0013, -0.0096, 0.0151, -0.0217, 0.009, -0.0079, 0.0021, 0.0039, 0.0152, 0.0349,
		-0.0023, -0.0024, -0.0013, 0.0952, -0.0915, -0.0506, 0.1489, -0.0356, -0.0734, 0.0102, -0.114, 0.0037, -0.2159,
		-0.1444, 0.2405, -0.1259, 0.0267, 0.0031, 0.001, 0.0055, 0.0103, 0.0057, -0.0021, -0.0054, -0.0075, 0.0008,
		-0.0065, 0.0014, -0.0096, -0.0327, 0.0383, -0.0548, 0.0408, 0.1109, 0.0168, -0.0432, -0.0043, 0.0654, 0.0178,
		0.0455, 0.0575, 0.0031, -0.0212, -0.0318, -0.0053, -0.0004, -0.0035, 0.0037, 0.0099, 0.001, -0.0065, -0.0083,
		-0.0041, 0.0144, -0.004, 0.0084, 0.0088, -0.0023, 0.0066, -0.006, 0.0017, 0.004, 0.0021, 0.0067, 0.0046, 0.0035,
		-0.0011, -0.0042, -0.0036, -0.0013, -0.0002, 0.0046, 0.0093, 0.0003, -0.0159, 0.0006, 0.01, -0.057, -0.0648,
		0.0208, -0.0466, 0.0218, -0.04, -0.0288, 0.1681, 0.0175, 0.0612, 0.0097, 0.0014, 0.0117, -0.0515, 0.0287,
		-0.0282, -0.0121, 0.0213, 0.0005, 0.0047, 0.0409, 0.0168, 0.0312, 0.0243, 0.0489, 0.0291, -0.0739, 0.024, 0.002,
		-0.1803, -0.0475, 0.0228, -0.0184, 0.1157, -0.0344, 0.0917, -0.0079, 0.1704, 0.0114, -0.0179, 0.1261, 0.0732,
		0.0318, -0.0089, 0.0193, -0.0611, -0.0127, 0.0131, -0.0405, 0.0479, 0.0041, -0.0488, -0.2206, 0.164, 0.0425,
		-0.1861, 0.1078, 0.0149, -0.1257, -0.0113, 0.0245, 0.0224, 0.0763, -0.1478, 0.009, -0.0344, 0.0377, 0.0215,
		-0.0812, -0.0435, -0.1283, 0.0902, 0.0077, -0.0307, 0.0144, 0.0606, 0.0235, -0.0175, -0.0183, 0.0322, -0.0035,
		0.0188, -0.006, -0.0132, 0.0345, -0.0123, -0.0317, -0.0081, 0.0024, -0.0281, -0.0059, -0.0504, 0.0493, -0.0067,
		-0.0843, 0.0585, 0.0272, 0.0659, 0.0866, -0.1092, -0.0929, 0.0231, 0.0733, 0.1172, -0.0331, -0.0777, -0.05,
		0.1396, 0.0398, 0.0426, -0.0475, -0.015, -0.0061, -0.0049, 0.0186, 0.0038, 0.0117, 0.0062, 0.0022, -0.0086,
		-0.0021, 0.0048, 0.0012, 0.02, 0.0284, 0.0099, -0.0059, -0.0303, 0.0313, -0.0444, 0.0262, -0.0151, 0.0157,
		-0.0212, 0.0249, -0.0165, 0.0056, -0.0185, -0.0228, -0.0022, 0.0082, 0.0063, 0.0103, 0.0339, 0.0481, 0.0539,
		-0.0456, -0.0332, 0.0364, 0.0338, -0.0597, 0.0177, 0.0032, -0.0304, 0.0441, 0.0046, -0.0435, 0.0289, 0.0734,
		-0.0861, -0.0298, 0.0956, -0.1208, 0.1848, -0.0978, 0.0508, 0.1179, -0.1867, -0.0479, -0.0477, -0.0201, 0.0987,
		-0.0043, -0.0933, 0.005, 0.004, -0.2371, -0.0267, 0.0831, -0.0473, 0.0369, -0.0557, 0.0584, 0.1054, -0.0297,
		0.0044, 0.0071, -0.1001, -0.0868, -0.1916, -0.0562, 0.0492, 0.0364, -0.0322, -0.0881, 0.0534, -0.1046, 0.0583,
		-0.0384, 0.0158, -0.0101, 0.0012, -0.0148, -0.009, 0.1055, -0.1421, -0.0112, 0.0579, 0.09, 0.1141, 0.0286,
		-0.0994, -0.0459, 0.0253, 0.0345, 0.0088, 0.01, -0.0068, 0.0124, 0.005, -0.0727, -0.0993, 0.2579, 0.1451,
		-0.0298, 0.1084, -0.1638, -0.0326, -0.0324, 0.0328, 0.0047, -0.0107, -0.0062, -0.002, -0.0029, 0.0033, -0.0103,
		0.0258, 0.1314, -0.0783, 0.0334, 0.0879, -0.0177, 0.0353, -0.0217, -0.0105, 0.0467, -0.063, 0.0215, -0.0054,
		-0.0043, 0.0036, -0.0081, -0.0036, -0.0053, 0.0114, -0.0024, 0.0696, 0.0286, -0.126, 0.1048, -0.0822, -0.0596,
		0.0186, 0.0371, 0.0208, -0.0215, -0.0452, -0.0167, -0.0142, -0.0355, -0.0342, 0.0834, -0.1625, 0.1971, 0.0493,
		-0.0036, -0.1601, 0.0867, 0.1581, 0.0433, 0.0398, 0.013, -0.0316, -0.0199, 0.0923, -0.0283, 0.0711, 0.0519,
		-0.0455, 0.0239, 0.0018, -0.0674, -0.0604, 0.0056, 0.0259, 0.0802, 0.0239, 0.011, 0.4595, -0.1564, 0.0991,
		0.1784, 0.0532, -0.0946, -0.2062, -0.0332, -0.1333, 0.0196, -0.207, 0.3629, -0.1598, -0.1071, 0.0514, 0.1561,
		0.1617, 0.2284, 0.0723, 0.1629, -0.1138, -0.1673, -0.156, 0.1153, -0.1895, 0.0068, 0.1266, -0.1511, 0.2022,
		-0.2014, -0.1961, -0.1098, -0.1665, 0.0504, -0.1347, 0.0952, 0.0756, -0.0208, -0.0067, 0.0344, -0.0048, 0.0978,
		-0.1331, 0.4067, 0.083, -0.0495, -0.2334, -0.1161, 0.0909, 0.1508, -0.0422, -0.2803, -0.0138, -0.1078, -0.2228,
		0.2961, -0.0143, -0.1091, 0.2458, 0.2151, 0.2675, -0.0659, 0.0083, 0.2002, 0.1212, -0.1653, 0.0948, 0.3105,
		-0.0963, -0.114, -0.0182, 0.0443, 0.2781, 0.2002, 0.1597, -0.2921, -0.1312, -0.0308, 0.154, 0.1932, 0.113,
		0.0129, 0.1146, 0.0421, 0.0026, -0.1065, 0.0106, -0.0152, 0.1305, -0.0662, -0.1592, -0.1587, 0.3255, -0.1348,
		0.2075, -0.0645, 0.0771, 0.2251, -0.0941, -0.0761, -0.006, 0.2007, -0.091, -0.3051, 0.025, -0.047, 0.0482,
		0.1541, -0.1811, 0.1039, 0.0199, 0.055, 0.0016, 0.056, -0.0512, 0.0593, -0.0278, 0.0006, 0.0042, 0.0119, 0.0112,
		-0.0054, -0.009, 0.0013, -0.0351, -0.0698, 0.0216, -0.0082, 0.0578, -0.0329, 0.0316, -0.0555, -0.0731, 0.0192,
		-0.0269, -0.2007, -0.0989, -0.1094, 0.0991, -0.0521, -0.052, -0.0288, -0.0948, 0.0153, 0.0112, 0.0389, 0.0561,
		-0.0472, -0.0097, -0.0506, 0.1912, -0.0552, 0.1562, 0.1208, 0.0683, -0.1521, -0.1255, -0.1806, 0.0306, 0.0322,
		-0.0369, 0.0517, 0.001, -0.0333, 0.0081, -0.0745, 0.1569, -0.0947, 0.2316, -0.2506, 0.0836, 0.0137, 0.0404,
		0.4329, -0.1487, 0.1126, -0.4895, 0.6558, -0.3811, -0.124, 0.2012, 0.3305, -0.115, 0.0014, -0.16, 0.3136,
		-0.0827, 0.0971, -0.1947, 0.0424, 0.2549, -0.2225, 0.0342, -0.0577, -0.2133, -0.1105, -0.1539, 0.2227, -0.1575,
		0.1809, -0.0016, -0.008, 0.0045, -0.0022
	]
]

export const peak3 = [
	[
		-0.0001, 0.0015, -0.0057, 0.0021, 0.0034, 0.0016, 0.0233, 0.0157, -0.0263, -0.0223, -0.016, -0.0171, 0.0022,
		0.0126, -0.0059, 0.0253, -0.1019, 0.0185, 0.0681, 0.048, 0.1049, 0.0859, 0.0533, 0.0226, -0.026, -0.0216,
		0.0339, 0.0085, 0.0108, -0.147, 0.1012, 0.0533, -0.0807, 0.0894, -0.0104, 0.0222, -0.0506, -0.0127, 0.0774,
		0.0332, -0.0958, 0.0528, 0.0041, -0.0015, -0.0379, -0.0704, -0.0668, -0.1514, -0.084, 0.0408, -0.0062, 0.0656,
		0.0293, -0.0334, 0.0027, 0.0081, 0.009, 0.0187, -0.0327, -0.0069, -0.018, 0.0083, -0.0001, 0.063, -0.0438,
		-0.0034, 0.0267, -0.0712, 0.0751, -0.0181, -0.0043, 0.0134, -0.028, 0.0173, -0.0005, -0.0359, 0.0399, -0.0789,
		0.0202, -0.01, 0.0427, 0.0208, 0.0256, 0.0113, 0.0016, 0.0215, 0.0104, -0.0104, -0.0141, 0.0063, 0.0186,
		-0.0055, 0.0288, 0.0542, -0.1131, 0.2837, -0.158, 0.1864, -0.2538, 0.0416, 0.0347, 0.0502, 0.0751, -0.1505,
		0.191, 0.0206, -0.0262, -0.0992, 0.002, -0.0162, 0.0057, 0.0021, 0.0007, -0.0117, -0.0117, 0.0022, -0.019,
		0.0065, 0.0184, -0.0093, 0.0076, -0.0005, -0.0231, 0.0119, 0.0803, 0.1082, -0.0188, -0.0273, 0.0178, 0.011,
		0.0203, -0.0737, 0.0269, -0.0147, 0.0451, -0.0678, -0.0105, 0.0494, -0.1395, -0.0643, -0.0097, 0.0645, 0.0428,
		-0.1055, -0.1856, 0.1002, 0.3766, 0.037, 0.1538, -0.0886, 0.0443, 0.025, 0.012, 0.005, -0.0084, 0.0037, 0.001,
		-0.0146, -0.0025, 0.0275, 0.2152, 0.2273, -0.0842, 0.195, -0.1011, -0.0609, -0.0387, -0.0967, 0.1005, -0.1167,
		-0.1274, -0.0352, -0.0237, -0.025, 0.0249, 0.0091, -0.0027, 0.0034, 0.0142, -0.0016, 0.0005, 0.0008, 0.013,
		-0.0039, 0.0027, 0.005, -0.0033, 0.013, -0.0125, -0.0079, -0.0181, 0.009, 0.0011, 0.0103, -0.0008, 0.0069,
		-0.0082, -0.0063, 0.0017, 0.0007, -0.0024, 0.0029, -0.0015, 0.0074, -0.0012, -0.0011, 0.0047, -0.0023, -0.0007,
		-0.0215, -0.0043, -0.0254, 0.0065, -0.0035, 0.0136, 0.0024, -0.0112, -0.0147, 0.0077, -0.0009, -0.0024, -0.0043,
		-0.0032, -0.0052, -0.0048, 0.0038, -0.0043, 0.0027, 0.0075, -0.0029, 0.002, -0.0069, -0.0057, -0.0068, -0.0061,
		0.0022, 0.0002, 0.0078, 0.0051, -0.011, -0.0161, 0.0005, 0.0026, -0.0116, 0.0074, 0.0049, -0.0133, -0.0032,
		-0.0102, -0.0033, -0.0303, -0.1631, 0.0388, -0.1363, -0.128, -0.0846, -0.0125, 0.0622, -0.0075, -0.0188,
		-0.0119, 0.0018, 0.0153, -0.0042, -0.17, -0.4432, -0.188, -0.1876, 0.4061, -0.1, 0.0867, -0.0686, 0.07, 0.0409,
		0.0082, -0.0163, 0.0018, 0.009, -0.0121, -0.05, -0.084, -0.0193, 0.0381, 0.0684, 0.0241, -0.0224, 0.0336,
		-0.0542, -0.1073, 0.0827, 0.0002, -0.0833, 0.21, -0.0276, -0.1851, 0.0425, 0.221, -0.231, -0.3677, 0.0282,
		0.0522, -0.1138, 0.0032, 0.0234, -0.0095, 0.0061, 0.021, 0.0581, -0.1298, -0.0321, -0.0935, 0.2596, -0.0712,
		-0.1095, 0.2235, -0.0999, -0.0872, 0.1673, -0.0741, -0.0901, 0.1322, -0.0684, 0.0021, -0.0197, -0.0147, 0.0079,
		0.0154, -0.0141, 0.0646, 0.1727, -0.0031, -0.1113, -0.1562, -0.068, 0.0117, 0.1863, -0.0089, -0.053, -0.0065,
		0.0308, -0.0835, 0.0025, -0.0355, -0.1986, -0.0884, -0.0629, 0.0167, 0.158, 0.0184, -0.0555, -0.0796, -0.0038,
		-0.0295, -0.2475, 0.0715, -0.0442, 0.1374, 0.0356, 0.1579, -0.1039, -0.1069, -0.1438, 0.0783, 0.002, -0.0962,
		-0.1138, -0.0488, 0.2526, -0.0335, -0.0488, -0.0658, 0.0339, -0.0126, 0.0349, -0.0511, -0.1317, 0.0175, -0.0268,
		0.022, 0.0245, 0.0295, -0.053, 0.0478, -0.0735, 0.0363, -0.0527, 0.0445, 0.1332, 0.0319, -0.0162, 0.0089,
		0.0028, 0.0013, -0.0022, -0.0204, 0.0079, 0.0078, 0.0094, 0.0026, -0.0111, 0.0012, -0.0048, 0.001, -0.0033,
		0.0097, -0.0128, 0.0081, 0.0028, 0.2283, 0.0182, -0.0309, 0.0827, -0.2322, 0.1072, -0.0309, -0.0232, -0.0722,
		-0.0647, -0.0557, 0.1219, -0.1932, 0.0086, 0.0085, -0.0002, 0.1609, 0.1185, -0.0992, 0.0651, 0.1787, 0.1446,
		-0.2247, 0.1069, 0.1727, -0.0317, 0.02, -0.0607, -0.0673, 0.0284, -0.0421, 0.0374, -0.0168, 0.0368, -0.0169,
		-0.0143, -0.017, -0.0059, 0.027, -0.004, -0.0027, 0.0042, -0.0018, 0.007, 0.0173, -0.0717, -0.0002, 0.0229,
		-0.0152, -0.0204, 0.0002, 0.014, -0.0199, -0.0089, -0.0082, 0.0071, 0.0094, 0.0048, -0.0178, -0.0467, 0.0691,
		-0.1321, -0.0201, 0.142, 0.099, 0.032, 0.004, -0.0137, 0.0394, -0.0069, -0.0571, -0.0826, 0.1169, -0.0256,
		-0.0655, 0.0319, -0.1813, -0.2127, 0.0258, -0.0593, -0.2603, 0.1332, 0.2928, -0.0258, 0.0733, 0.0527, 0.0269,
		0.0028, -0.0034, -0.0075, -0.0121, -0.004, 0.0005, -0.0131, 0.0192, -0.0202, 0.0036, 0.0223, 0.0157, -0.08,
		-0.0227, 0.0843, 0.1751, 0.1147, 0.016, -0.0849, -0.0881, 0.0486, -0.0514, 0.0184, -0.0101, -0.0111, -0.0035,
		-0.0039, 0.0052, 0.0087, -0.0039, 0.006, -0.0134, 0.0292, -0.0034, 0.079, 0.2113, 0.1118, -0.0192, 0.2309,
		0.0612, -0.0927, 0.1136, 0.0831, -0.0798, -0.0529, -0.1737, -0.3879, -0.1331, -0.0663, -0.0128, -0.1499,
		-0.0043, 0.0186, -0.0067, 0.0157, 0.0511, -0.0248, -0.0227, 0.0543, -0.0194, 0.0425, 0.0108, -0.0411, -0.0131,
		0.0245, 0.0242, 0.0169, -0.0076, -0.0096, 0.0062, 0.0133, -0.0104, -0.0096, -0.0076, -0.0078, -0.0014, 0.0038,
		0.0078, 0.0062, -0.0102, 0.005, 0.0067, -0.0076, 0.0081, 0.0009, 0.0019, 0.0138, 0.0029, 0.0011, -0.0014,
		0.0252, -0.0042, 0.0072, -0.0006, 0.0021, 0.0027, 0.004, 0.0041, 0.0065, -0.0025, -0.011, -0.0086, -0.0047, 0,
		0.0037, -0.0047, 0.004, -0.0065, -0.0037, -0.0015, -0.0013, -0.008, -0.0026, 0.0031, -0.0076, -0.0043, 0.0111,
		0.0015, 0.0097, 0.0049, 0.0086, 0.0125, -0.018, -0.0057, -0.0011, -0.0096, 0.0182, -0.0067, -0.0073, 0.0111,
		0.0018, 0.0071, 0.0027, -0.0099, -0.006, -0.0071, -0.0021, 0.0012, -0.0031, 0.0072, -0.0112, 0.0099, -0.0029,
		0.0019, -0.0031, 0.0114, 0.0019, 0.0414, 0.1027, 0.0281, 0.1032, 0.1365, 0.2793, -0.4544, -0.2688, -0.0309,
		0.059, 0.1151, 0.1189, 0.0226, -0.0656, 0.004, 0.0422, 0.0017, 0.011, 0.0106, 0.1394, -0.1342, 0.0559, -0.0453,
		0.0284, 0.0282, -0.0193, -0.0849, 0.0739, 0.0075, 0.04, 0.0534, 0.0122, -0.0075, -0.0043, 0.0304, 0.0254,
		-0.0452, 0.0557, -0.0635, 0.0075, -0.0295, 0.0019, -0.0097, -0.0078, -0.0177, 0.0255, -0.0503, 0.0416, -0.0231,
		0.0026, 0.0148, -0.0226, -0.0278, 0.0171, 0.0173, -0.0115, 0.0187, 0.0003, -0.0004, 0.0168, -0.0175, 0.0015,
		0.0016, 0.0028, 0.0004, -0.0011, 0.0017, 0.0013, 0.0052, 0.0133, 0.0128, -0.003, -0.0156, 0.0102, 0.0043,
		0.0165, -0.0032, -0.0074, 0.016, 0.0053, 0.0029, -0.0049, 0.008, -0.0154, 0.0455, 0.0022, 0.0506, -0.0049,
		0.0142, -0.0028, -0.1717, 0.0259, -0.0203, 0.0312, 0.0844, 0.0271, 0.0063, 0, 0.0153, 0.0272, -0.0401, 0.0233,
		0.0191, -0.0076, 0.0133, 0.0083, 0.0014, 0.0149, 0.0067, 0.0036, 0.0061, 0.0043, 0.0025, 0.01, 0.0137, -0.0061,
		0.0791, -0.0185, 0.0145, 0.1834, 0.0385, -0.1183, 0.0931, 0.0859, 0.0213, -0.0326, -0.0462, -0.0796, -0.0872,
		-0.0686, -0.04, -0.0053, 0.0128, 0.038, 0.0193, 0.0067, -0.0015, 0.0462, -0.0907, 0.0236, 0.0471, 0.004,
		-0.0199, -0.0041, -0.0124, -0.0001, 0.0146, -0.0452, 0.0082, 0.0082, 0.0332, -0.1624, 0.0157, 0.0327, -0.033,
		0.0218, 0.0298, -0.0205, 0.0492, -0.0621, 0.0115, -0.0195, 0.008, -0.0095, -0.0167, 0.0517, -0.0473, 0.026,
		0.0138, 0.008, -0.0391, -0.0084, -0.0419, -0.0286, -0.055, 0.0678, 0.2342, -0.0481, -0.1902, -0.1405, 0.154,
		0.0896, -0.1439, -0.1079, -0.1582, -0.0411, 0.0899, -0.0634, -0.0258, 0.0053, -0.0058, 0.0045, 0.0017, -0.01,
		0.031, 0.0442, -0.034, -0.1092, -0.2224, -0.1308, -0.1572, 0.0917, -0.0136, -0.0459, -0.0311, -0.0022, 0.019,
		-0.0007, 0.0141, -0.0029, -0.039, -0.009, -0.0557, -0.0137, -0.0257, -0.0564, -0.2288, 0.1189, 0.0056, 0.0595,
		-0.0113, -0.0478, -0.0186, -0.0017, 0.0926, -0.0101, -0.0046, -0.1065, -0.0384, -0.019, 0.01, -0.0052, 0.0034,
		-0.0539, -0.0044, -0.0092, -0.0117, 0.0005, -0.0138, -0.0016, 0.0025, -0.0135, 0.0305, 0.0321, -0.0541, -0.2905,
		0.1391, 0.211, 0.1185, 0.0297, -0.2219, -0.1322, -0.0228, -0.0748, 0.2766, 0.0617, 0.0089, -0.0576, -0.1111,
		0.0095, 0.03, -0.0088, -0.0031, -0.0004, -0.0062, 0.0193, 0.0084, -0.0058, -0.0094, 0.0073, -0.0014, 0.018,
		-0.0745, -0.0009, 0.0258, 0.0059, -0.0997, -0.0071, -0.0369, 0.0032, 0.0284, 0.0523, 0.0472, 0.0223, 0.0495,
		0.0289, 0.0252, 0.0317, 0.0342, 0.0301, 0.0087, 0.0569, 0.0134, -0.0241, 0.0201, 0.0092, -0.0051, -0.0092,
		0.0071, 0.009, -0.01, 0.0263, -0.0114, -0.133, -0.0074, -0.0176, 0.1304, -0.1084, 0.069, -0.0971, 0.0959,
		0.0024, 0.137, 0.1937, -0.1052, 0.0967, 0.2168, -0.0771, -0.108, 0.0033, -0.0853, 0.1006, -0.0163, 0.0096,
		0.0085, 0.0153, -0.0038, 0.0102, -0.0021, 0.0114, -0.0029, -0.0103, 0.0045, 0.0019, -0.0007, 0.0007, -0.0077,
		-0.0059, 0.0023, 0.0008, 0.0007, -0.0025, 0.0009, 0.0035, 0.001, -0.0029, -0.0068, -0.0025, 0.0012, -0.0111,
		-0.0037, 0.0068, 0.0007, -0.005, -0.007, -0.0019, -0.0026, -0.0029, -0.0094, 0.0021, -0.0025, 0.0025, 0.007,
		0.0052, -0.0068, -0.005, 0.015, 0.0046, -0.0049, -0.023, -0.0017, -0.0118, 0.0008, -0.0076, 0.0055, 0.0023,
		-0.0037, -0.0077, -0.0069, -0.0026, -0.0051, 0.0104, -0.0055, 0.1405, 0.0232, -0.0694, 0.04, 0.118, 0.2646,
		-0.0471, 0.0392, -0.0933, -0.1361, 0.0395, -0.0024, 0.0169, 0.0468, -0.1186, 0.169, 0.2722, 0.1855, -0.1531,
		-0.0114, 0.0752, 0.1284, -0.1808, 0.0351, 0.0176, -0.0384, 0.0239, 0.0979, -0.0484, -0.0056, 0.0422, 0.0916,
		0.0767, 0.0663, 0.0169, 0.0561, 0.0699, 0.0194, -0.0415, -0.0762, -0.0794, -0.0265, -0.0134, -0.1102, 0.0641,
		0.0258, 0.1767, 0.0017, 0.0062, -0.1183, -0.2773, -0.1339, -0.0345, 0.1399, 0.3033, 0.1909, 0.0004, 0.121,
		-0.4406, 0.5221, 0.0186, 0.4295, -0.0906, -0.0176, -0.0931, 0.0021, 0.143, -0.0157, 0.0191, -0.01, -0.0056,
		0.0117, 0.0071, -0.0219, 0.0574, 0.049, -0.0231, -0.0094, 0.0633, 0.066, -0.1256, -0.311, 0.2678, -0.1146,
		-0.077, 0.0125, 0.0855, 0.1846, -0.0167, 0.1009, 0.1884, 0.2373, 0.0882, -0.0789, -0.0601, -0.0543, 0.0387,
		-0.1174, -0.1325, -0.2855, -0.1653, 0.0063, -0.0572, 0.2112, -0.0228, -0.0496, 0.1054, 0.0011, 0.0149, 0.0224,
		0.0139, 0.0147, -0.0552, 0.0346, 0.0969, -0.0849, 0.0237, -0.0097, 0.0143, 0.0239, -0.0097, 0.0232, -0.0291,
		0.0334, -0.0404, 0.0256, -0.0014, -0.0458, 0.0383, 0.0525, -0.1249, -0.1949, -0.0383, -0.0177, -0.018, -0.0338,
		0.0659, -0.1167, -0.0169, -0.0487, -0.0345, 0.1178, 0.2466, -0.0532, -0.0183, -0.0529, -0.0123, -0.1475, 0.0706,
		0.018, -0.0532, -0.1585, -0.0099, -0.0396, 0.011, -0.0666, 0.0248, 0.017, -0.0054, 0.0055, 0.005, 0.007, 0.0043,
		-0.0415, 0.069, 0.0456, -0.2396, -0.1015, 0.0228, 0.0113, 0.0042, -0.0403, 0.0069, 0.0086, 0.0082, 0.0089,
		-0.0087, -0.0256, 0.0436, -0.1372, -0.0392, -0.386, 0.0693, -0.0274, 0.067, -0.0723, 0.0121, 0.0906, -0.0885,
		0.0229, 0.1629, 0.0241, 0.0232, 0.03, -0.011, -0.1482, 0.0798, 0.0785, 0.0596, -0.0274, 0.0086, -0.0043, 0.0024,
		0.0088, 0.0026, 0.0021, 0.0365, -0.0276, -0.0647, 0.0583, 0.0197, -0.0202, 0.0145, -0.1762, -0.1917, -0.0254,
		0.1575, -0.0354, 0.0508, -0.029, 0.0151, 0.0005, 0.0089, -0.0087, 0.0113, -0.0099, -0.0063, 0.0331, 0.0893,
		-0.2328, 0.1428, 0.0556, 0.0109, -0.2112, 0.237, -0.0557, -0.1595, 0.0841, 0.0086, -0.0966, -0.0577, 0.0739,
		-0.0442, 0.0919, 0.0066, -0.0206, 0.0025, -0.0021, -0.0065, 0.0047, 0.0025, 0.0013, 0.0024, -0.0017, 0.0006,
		0.0047, -0.0013, 0.0088, -0.0022, -0.01, -0.0102, 0.0019, 0.0255, 0.021, 0.0027, -0.0056, -0.0319, -0.0016,
		-0.0292, 0.0254, 0.0672, 0.0054, 0.0687, -0.1144, -0.1248, 0.026, 0.1079, -0.108, 0.0855, -0.2073, 0.0567,
		0.0421, 0.1082, -0.0177, -0.0142, 0.0774, 0.0259, -0.0077, 0.1138, -0.0116, -0.0241, -0.1659, 0.0874, 0.0379,
		-0.0346, -0.0679, -0.1147, 0.0657, 0.1158, 0.0428, 0.0771, 0.0674, 0.1572, 0.0362, 0.0272, 0.0628, -0.0329,
		0.0369, -0.0015, -0.0255, 0.009, 0.0022, -0.0158, -0.0031, 0.0062, -0.0064, 0.0018, 0.0038, -0.0003, -0.0037,
		0.0056, 0.0006, -0.0015, -0.0004, -0.0066, -0.001, -0.0003, -0.0019, -0.0081, -0.003, 0.0055, -0.0016, -0.0052,
		-0.0052, 0.0058, 0.003, -0.0034, -0.0038, 0.0005, -0.0039, -0.0017, -0.0062, -0.0056, 0.0062, 0.0065, 0.0012,
		-0.0049, -0.0036, -0.002, -0.0088, 0.0004, 0.0137, 0.0035, -0.0016, 0.0024, -0.014, 0.0085, 0.0097, 0.0045,
		0.0032, -0.0016, 0.0013, -0.0055, 0.0115, -0.0037, -0.0035, 0.001, 0.002, -0.002, -0.0049, 0.0037, 0.0056,
		-0.0023, -0.0038, -0.0054, 0.0004, 0.0032, 0.0061, -0.0009, -0.0058, -0.0027, 0.0082, 0.0597, -0.0123, -0.0051,
		0.0337, -0.1646, 0.0008, -0.0569, 0.1398, 0.3127, 0.0915, 0.1277, 0.174, 0.1126, -0.102, 0.0228, 0.0152, 0.0026,
		0.0002, -0.004, -0.0064, 0.0045, -0.0121, -0.0004, 0.0078, 0.0871, -0.1512, -0.1139, 0.0969, 0.0084, 0.0073,
		-0.0001, -0.0036, 0.0046, -0.0026, 0.0082, 0.0018, -0.0033, 0.0103, -0.0011, 0.0174, -0.1709, -0.0054, 0.0805,
		0.1204, -0.1843, 0.1286, -0.0341, -0.0332, -0.0178, 0.1408, 0.0026, -0.0132, 0.0094, 0.0059, 0.0021, -0.0037,
		0.0018, -0.0041, 0.002, -0.0067, -0.0068, 0.0172, 0.0026, -0.002, 0.1272, -0.1735, -0.0336, -0.1718, 0.2822,
		-0.0722, 0.0062, -0.2955, -0.0951, 0.0187, -0.1829, -0.0486, 0.0024, -0.0396, -0.0358, -0.0346, -0.02, -0.0502,
		-0.0544, -0.0291, -0.0761, -0.1086, -0.1205, -0.1605, -0.07, 0.0029, -0.005, -0.0053, 0.0042, 0.0059, -0.0166,
		-0.0135, 0.0021, 0.0256, 0.0386, -0.0984, 0.1268, 0.1462, -0.254, 0.0938, 0.1569, 0.0898, -0.1479, -0.2446,
		0.0102, 0.1238, 0.1668, 0.1747, 0.1683, 0.1852, 0.1787, 0.0241, 0.0209, -0.2198, -0.0116, 0.2487, 0.0791,
		-0.1041, 0.1501, -0.1507, 0.1325, -0.0518, -0.1184, 0.0298, -0.053, 0.0134, 0.0118, -0.0098, -0.0086, -0.0018,
		0.009, 0.0025, -0.0068, 0.0003, -0.0001, 0.0066, 0.0085, -0.008, 0.0201, -0.0108, -0.0023, 0.0066, 0.0068,
		0.013, -0.009, 0.0063, 0.0046, 0.0686, 0.1952, -0.287, 0.2178, -0.1154, 0.1097, 0.0957, 0.1054, -0.178, 0.0895,
		-0.0008, 0.2691, -0.0717, 0.017, -0.0073, 0.0043, 0.0245, 0.0079, 0.0832, -0.0219, -0.2158, -0.1809, 0.0991,
		0.2988, 0.0008, -0.0976, -0.0605, 0.1943, -0.0196, -0.2528, -0.0213, 0.4192, -0.2172, -0.1349, 0.0138, -0.0648,
		-0.1462, -0.0935, 0.0688, 0.1502, 0.0662, -0.0179, -0.0269, 0.0382, 0.0609, -0.059, -0.0721, -0.1526, 0.0469,
		-0.0912, 0.2175, -0.1242, 0.1101, -0.0264, -0.0402, -0.0222, 0.0043, -0.0139, 0.0064, -0.0048, -0.0386, -0.0353,
		-0.0178, -0.0313, -0.0067, 0.0711, -0.1719, -0.0916, 0.0108, 0.112, -0.022, 0.2452, 0.0151, -0.1541, 0.059,
		0.0611, -0.0225, -0.1688, 0.1877, 0.1663, -0.0427, 0.0405, -0.2354, 0.0522, -0.0314, 0.1038, -0.11, 0.0684,
		-0.1311, 0.0712, -0.1196, -0.1526, 0.0381, -0.1675, 0.0693, -0.0133, 0.0158, 0.0479, -0.1218, -0.0144, 0.1003,
		0.0844, -0.018, -0.1301, 0.1793, -0.0238, -0.0552, -0.0775, 0.0042, 0.0605, -0.1141, -0.0875, -0.0148, 0.0066,
		-0.0086, -0.0091, 0.0256, -0.0671, 0.0715, -0.0092, 0.1837, -0.236, 0.0027, 0.0002, 0.0125, -0.0006, 0.0179,
		-0.0421, -0.0391, -0.0004, 0.0396, 0.1306, 0.0183, -0.0136, 0.0069, 0.0029, 0.03, 0.0265, -0.0084, 0.0379,
		-0.0047, 0.0688, -0.0177, -0.0771, 0.1492, -0.2564, 0.0721, -0.0288, 0.0203, -0.0005, 0.0034, 0.0225, -0.0187,
		-0.0038, -0.0489, -0.0015, -0.1572, -0.003, -0.0893, -0.0059, 0.0014, 0.0108, -0.0002, 0.0147, 0.0003, 0.0004,
		-0.0021, 0.0092, 0.0156, 0.0017, 0.0764, -0.0886, -0.0348, 0.1001, 0.1045, 0.0273, 0.0476, 0.0633, 0.0474,
		0.0544, 0.0574, 0.0324, -0.0172, 0.0169, 0.0101, 0.0352, 0.1398, 0.0913, -0.0961, -0.0263, 0.1315, -0.0834,
		-0.0213, 0.0074, 0.0806, 0.0014, 0.0733, -0.0463, -0.0049, -0.0027, 0.0153, -0.0365, 0.031, -0.1844, -0.0735,
		0.065, 0.1873, -0.0248, 0.0295, 0.1022, -0.0956, -0.0379, 0.0168, -0.0785, -0.2264, -0.0417, -0.0567, -0.0523,
		-0.157, 0.0152, 0.102, 0.0101, 0.0449, 0.0294, -0.0186, -0.0701, 0.0133, 0.0344, 0.0155, -0.0035, -0.0056,
		-0.0117, 0.029, 0.0538, 0.0026, -0.0167, 0.0886, -0.0891, 0.0094, 0.0256, 0.0125, -0.013, 0.0364, -0.0129,
		-0.025, -0.0555, 0.0319, 0.0629, 0.0183, -0.0213, 0.0668, 0.0398, -0.0292, -0.0012, -0.0061, 0.0526, -0.0402,
		0.0472, -0.0035, 0.0155, -0.0279, -0.0231, 0.0515, -0.0145, 0.0216, -0.0942, 0.0007, 0.0301, -0.0485, 0.0339,
		-0.0664, -0.031, 0.0511, -0.0369, 0.0313, 0.1003, 0.0133, -0.0118, 0.0774, -0.1059, 0.0207, -0.0028, -0.0213,
		0.0664, -0.0577, 0.0393, 0.0646, -0.0228, -0.0214, -0.0353, 0.0997, 0.0224, 0.0609, 0.036, 0.0081, -0.0195,
		-0.1918, 0.0533, 0.1052, -0.0382, -0.0058, -0.0035, -0.0098, 0.0045, 0.0135, -0.0447, 0.0167, 0.0535, -0.0219,
		0.0682, -0.0354, -0.1166, 0.2529, 0.1056, -0.2186, -0.0333, -0.0281, 0.0104, 0.2546, -0.0302, -0.0241, -0.0479,
		0.083, -0.0301, -0.0599, -0.0037, -0.0417, -0.0679, -0.0021, -0.01, 0.0797, 0.0038, 0.0698, -0.0856, -0.0118,
		0.0209, 0.0064, -0.1541, 0.0333, 0.0715, -0.1815, -0.0692, -0.0189, -0.0734, 0.2005, 0.1839, 0.1348, 0.0421,
		-0.0699, -0.1989, 0.0616, 0.3081, 0.0229, -0.0565, 0.0549, 0.0876, -0.1138, 0.0547, 0.0325, 0.0269, -0.1079,
		-0.0937, -0.1658, 0.0165, 0.0759, -0.0243, -0.0118, -0.0294, 0.0414, 0.048, 0.0818, -0.1953, 0.0269, 0.0136,
		0.089, -0.0323, -0.0422, -0.0232, 0.0062, -0.0027, 0.0044, 0.0087, 0.0025, 0.0039, 0.0137, 0.0025, -0.0005,
		-0.0011, -0.0009, -0.014, 0.0288, 0.0065, -0.0404, 0.0173, 0.0243, -0.0397, 0.0259, 0.0007, 0.0721, -0.1181,
		-0.0891, 0.0507, 0.1823, 0.2232, 0.1227, 0.0878, -0.0384, -0.1417, -0.2401, -0.0728, -0.008, -0.0593, -0.0535,
		-0.1337, 0.0207, 0.0986, 0.081, 0.0438, -0.2117, 0.1437, -0.1314, 0.0126, 0.018, 0.0213, -0.1724, 0.0424,
		-0.142, 0.0865, 0.0386, 0.0104, 0.0033, -0.0166, 0.0055, 0.0023, -0.0081, 0.0125, -0.0125, -0.11, -0.0346,
		0.1758, 0.0816, 0.1104, -0.1186, -0.0739, -0.0045, 0.0085, 0.0685, 0.0087, -0.0177, 0.0015, -0.004, 0.0169,
		0.0051, 0.0016, -0.0307, 0.0116, -0.0188, 0.0188, 0.0015, 0.0294, -0.0696, -0.0194, -0.1154, -0.0059, 0.2204,
		0.0745, -0.0396, 0.0448, -0.0107, 0.0415, -0.0051, 0.0207, 0.0112, -0.005, -0.0046, -0.0056, 0.0011, 0.0147,
		0.0297, 0.0313, 0.0147, -0.0637, -0.0082, -0.1044, -0.0106, -0.0384, 0.0083, -0.0273, -0.0025, 0.1555, 0.0233,
		0.0119, -0.0893, -0.1576, 0.2343, 0.0527, 0.0926, -0.0856, 0.3136, -0.197, 0.1093, 0.0003, 0.0056, 0.0207,
		-0.0004, -0.0141, 0.004, 0.0085, 0.0004, 0.0025, 0.0065, -0.0063, 0.0083, -0.0145, 0.0083, 0.0096, -0.0079,
		-0.0065, 0.0015, 0.0019, -0.0131, -0.002, -0.0069, 0.0066, -0.0043, -0.0028, -0.0019, 0.0081, 0.0004, 0.0026,
		0.0106, 0.0061, -0.0059, 0.0037, 0.0052, -0.0073, 0.0095, -0.0038, -0.0077, -0.0008, -0.0012, -0.0351, -0.0274,
		0.0073, 0.001, 0.0304, 0.0524, -0.0272, -0.064, -0.1229, -0.0219, 0.2546, 0.0479, -0.2314, 0.176, -0.2309,
		-0.0082, 0.0485, -0.1075, -0.0446, -0.085, 0.0245, -0.0043, 0.0022, 0.003, 0.0006, -0.0117, 0.0167, 0.0237,
		-0.1595, -0.065, -0.1847, -0.0059, 0.0394, 0.0074, 0.0258, 0.0194, 0.0042, -0.0527, 0.0355, 0.1069, 0.1378,
		-0.1375, 0.0016, -0.0323, -0.001, -0.0629, 0.0937, -0.0227, 0.0061, -0.054, 0.0158, 0.0087, -0.0095, 0.0079,
		-0.0034, 0.0076, 0.0019, -0.041, 0.0712, 0.1185, -0.1538, 0.0484, 0.3032, 0.0316, 0.2731, 0.0626, -0.0191,
		0.0506, -0.0662, -0.0193, 0.0388, -0.0543, 0.0547, 0.003, -0.0019, 0.0177, 0.0443, 0.0777, 0.0518, -0.0494,
		-0.1465, -0.0131, -0.0014, 0.1413, -0.3365, -0.2079, -0.0024, -0.1525, -0.2234, -0.0443, -0.2392, -0.0161,
		-0.1744, -0.068, -0.0476, -0.0032, -0.0122, 0.0293, 0.0098, 0.0359, 0.015, -0.0093, -0.0246, -0.0011, 0.0038,
		-0.0199, -0.0272, 0.0284, 0.0024, 0.0273, -0.0098, -0.0132, -0.0231, 0.0208, -0.0058, 0.0198, -0.007, -0.0466,
		0.0071, 0.0613, 0.0325, 0.0158, 0.0041, -0.0697, -0.0084, 0.0039, 0.0283, -0.0065, -0.1344, -0.0099, -0.0205,
		0.2188, 0.1623, 0.0389, -0.019, 0.0053, -0.0501, -0.0483, -0.118, -0.0649, 0.0846, 0.0051, 0.0243, -0.0172,
		0.2116, 0.0849, 0.1508, -0.01, 0.055, -0.0428, -0.0087, 0.0121, 0.0637, -0.0198, 0.1572, -0.0863, 0.0139,
		0.0037, 0.0148, 0.0041, -0.011, 0.0219, -0.0494, -0.0518, 0.0149, -0.0364, 0.0253, 0.0114, 0.0143, 0.021,
		-0.0003, 0.002, 0.0056, -0.0038, 0.01, -0.0103, -0.0074, 0.0018, -0.0178, 0.0357, 0.0619, -0.0493, -0.1301,
		-0.1237, 0.0058, 0.0196, 0.0483, -0.0567, -0.1258, -0.0489, -0.0329, 0.0199, -0.0396, -0.054, 0.0031, 0.0196,
		0.0101, 0.0041, -0.045, 0.057, 0.0211, 0.0241, 0.0861, -0.0652, -0.0247, -0.0122, -0.191, -0.1376, -0.5217,
		-0.1836, -0.0425, -0.0422, -0.018, -0.0059, -0.0504, -0.0008, 0.0604, 0.0458, -0.0159, 0.0137, 0.0064, 0.0344,
		0.0039, 0.0117, 0.1087, -0.2041, -0.1466, 0.1387, 0.0001, 0.0572, 0.0492, 0.06, 0.0425, 0.0208, 0.0032, 0.0014,
		0.0045, -0.0011, 0.0079, 0.0041, -0.0009, 0.0037, 0.0164, 0.0229, 0.0429, 0.0608, 0.2722, -0.1917, 0.1257,
		0.0091, -0.0489, -0.0779, 0.0218, 0.0365, -0.0061, 0.0203, 0.0405, 0.0809, -0.0085, 0.0248, 0.016, 0.0073,
		-0.0066, 0.0256, 0.0054, 0.0275, 0.0757, 0.0925, -0.0191, -0.0488, 0.0368, 0.02, 0.0211, 0.0042, 0.0004, 0.0033,
		0.0034, 0.0023, 0.0013, -0.0135, -0.0163, 0.0437, 0.0084, -0.3185, 0.137, -0.0404, -0.0227, -0.0674, 0.0416,
		-0.0576, -0.0554, 0.2463, -0.0172, 0.0869, -0.1967, 0.0341, -0.0226, 0.0016, 0.002, -0.046, 0.0586, -0.0328,
		0.1094, 0.2934, 0.1889, -0.0476, 0.0837, 0.0601, 0.0658, 0.0285, 0.2998, -0.0946, 0.0265, -0.0107, 0.0075,
		-0.0015, -0.0235, -0.0054, 0.0054, -0.008, 0.0079, 0.0081, 0.0125, -0.003, -0.0074, -0.108, -0.0181, 0.0673,
		-0.0498, 0.0452, 0.1216, -0.02, 0.121, 0.1958, -0.0429, 0.0532, 0.1147, -0.0006, -0.0084, -0.0019, -0.0014,
		-0.003, 0.0123, 0.001, -0.0063, 0.0092, -0.0081, 0.0008, -0.0058, -0.0165, 0.0131, -0.0126, 0.0087, 0.0033,
		-0.0037, -0.0076, -0.0106, -0.0001, 0.0054, -0.0004, -0.0006, 0.01, 0.0027, 0.0089, -0.007, -0.0007, 0.0034,
		0.0024, 0.0031, 0.0025, -0.0007, -0.002, -0.0046, 0.0075, 0.0079, -0.004, 0.0056, 0.0005, 0.0066, -0.0091,
		-0.012, 0.003, 0.0038, -0.0403, -0.0313, -0.1121, -0.1605, -0.0054, -0.0116, -0.0836, 0.1331, 0.0232, -0.0541,
		0.0759, 0.1324, -0.1822, 0.0106, 0.0268, -0.0968, -0.0623, 0.0134, 0.2492, 0.1094, -0.1126, -0.0344, 0.0708,
		0.0082, 0.0386, -0.0135, 0.0033, -0.0024, 0.0028, 0.0353, 0.0876, 0.0795, 0.081, -0.0408, -0.1661, 0.2231,
		-0.0515, 0.125, -0.0604, -0.0059, 0.0233, 0.0292, -0.1173, 0.0079, 0.0884, -0.0763, 0.1254, -0.0047, -0.0365,
		0.0526, -0.2569, 0.0778, 0.0369, -0.0518, -0.0164, 0.0504, 0.0425, 0.0538, 0.0195, -0.0299, 0.0102, -0.0013,
		-0.0056, -0.0044, 0.004, 0.0035, -0.0625, -0.1475, -0.2321, -0.1295, -0.0859, -0.0489, 0.0088, 0.025, 0.0073,
		0.0149, 0.0337, -0.1076, 0.0476, 0.0695, 0.0888, -0.0471, 0.052, 0.0216, 0.0279, 0.0195, -0.0026, 0.0082,
		0.0092, -0.0097, 0.0057, 0.0057, 0.0074, 0.0126, 0.0157, 0.0367, -0.0701, -0.1113, -0.0965, -0.0113, 0.1383,
		0.004, -0.0185, 0.0709, -0.1745, 0.1053, -0.0302, 0.0919, 0.1581, -0.0712, -0.0688, 0.0364, 0.0331, -0.0019,
		-0.0032, 0.0118, -0.0228, -0.0437, 0.0051, 0.0276, 0.0488, 0.0472, 0.0073, -0.0346, -0.0074, 0.0784, -0.0324,
		0.0071, 0.0044, 0.0701, -0.0552, 0.1009, 0.0414, 0.0065, 0.0115, -0.0096, 0.0048, -0.0007, -0.0037, -0.0098,
		0.0075, -0.0356, 0.0251, -0.1608, -0.0297, -0.0051, 0.0632, -0.0441, -0.0664, -0.0311, -0.0115, 0.0534, 0.0564,
		0.0285, -0.0047, 0.0109, -0.0039, 0.01, -0.0103, 0.0098, 0.0147, -0.0125, -0.0063, -0.0023, 0.0112, 0.0094,
		-0.0059, 0.0095, 0.0206, 0.0102, 0.0177, 0.0396, 0.0951, -0.0133, 0.0786, -0.0023, 0.0094, -0.0004, 0.0033,
		0.0041, -0.0106, -0.0085, 0.0139, 0.0322, 0.0072, 0.0135, -0.0651, -0.0138, 0.0268, -0.0356, 0.0094, 0.0108,
		0.0104, -0.0018, 0.0048, -0.0062, 0.0018, -0.0107, 0.0104, 0.0095, 0.0179, 0.0139, -0.1692, 0.1893, -0.0012,
		-0.0366, 0.0975, -0.0749, -0.0044, -0.0023, -0.0133, 0.131, 0.0026, -0.0271, -0.1448, -0.0433, 0.0072, 0.006,
		0.0263, -0.0388, -0.0867, 0.0454, 0.1404, -0.0543, 0.1933, -0.1786, -0.0198, -0.13, 0.2129, 0.0611, 0.0161,
		-0.034, 0.0256, -0.0435, 0.0593, -0.0477, 0.0056, -0.1014, -0.0488, 0.2229, 0.0697, -0.0616, 0.0543, -0.1109,
		-0.0382, -0.1988, 0.0347, -0.0761, 0.0446, 0.0525, 0.071, 0.1128, -0.0217, 0.0089, -0.0051, -0.0198, 0.0301,
		-0.0855, -0.013, -0.1449, 0.0238, 0.0363, 0.0336, -0.0116, 0.0099, -0.0121, 0.0041, -0.0137, -0.0079, -0.0224,
		0.0976, 0.0277, 0.0077, -0.0435, 0.0099, -0.026, -0.0062, 0.0228, -0.0326, 0.0138, -0.0529, 0.0792, -0.1158,
		-0.0543, -0.1249, -0.0258, 0.0187, 0.0526, -0.1835, 0.0235, -0.1525, 0.085, -0.0537, 0.0011, 0.0042, 0.0051,
		0.0089, 0.0094, -0.0055, 0.004, -0.0065, 0.0083, 0.0116, -0.002, -0.0066, -0.0036, -0.011, 0.0007, 0.0133,
		-0.0039, 0.0031, -0.0022, -0.0154, -0.0019, -0.001, -0.0046, 0.0009, 0.0027, 0.0001, 0.0047, 0.0009, 0.002,
		0.0048, -0.0088, 0.0074, 0.0033, -0.0113, -0.0045, -0.0021, 0.0053, 0.0016, 0.0118, 0.0052, 0.0002, -0.0092,
		-0.0317, 0.0256, 0.0102, -0.0094, -0.1268, -0.0746, 0.1092, 0.1667, -0.0228, -0.1132, -0.1591, 0.0788, 0.1858,
		-0.2229, -0.1718, -0.1504, -0.0603, 0.0925, -0.0725, 0.011, 0.0974, 0.0708, 0.0182, -0.055, -0.0611, -0.0519,
		-0.0145, 0.1092, 0.0426, -0.041, -0.1169, -0.1482, -0.0556, 0.0246, 0.0315, 0.0097, -0.1009, -0.1853, -0.1066,
		0.1454, 0.0988, -0.1237, -0.1341, -0.0208, -0.0682, -0.0058, 0.0608, 0.0548, 0.0273, -0.04, 0.0004, 0.024,
		0.0034, 0.0139, 0.0423, -0.0131, -0.0593, -0.0239, 0.2008, 0.1643, -0.1499, 0.0744, 0.0109, 0.1437, 0.0509,
		-0.0133, 0.046, 0.0103, -0.0047, -0.0357, 0.0348, 0.0103, 0.0128, -0.0567, 0.0224, 0.0143, -0.0274, -0.0028,
		0.0051, -0.0032, -0.0034, 0.007, -0.0001, 0.0045, -0.0049, 0.0118, -0.004, -0.0047, -0.0072, 0.0025, -0.0006,
		0.0004, 0.0009, 0.0046, 0.0093, 0.002, 0.0104, 0.0053, -0.0141, -0.004, 0.0002, -0.003, 0.0011, 0.0093, 0.0005,
		-0.0036, -0.0037, -0.0005, 0.0028, 0.0106, -0.0024, -0.0008, 0.0103, -0.0054, -0.0304, 0.0291, 0.0474, 0.0663,
		-0.2335, -0.0286, 0.1473, 0.0227, 0.0127, -0.0284, 0.0827, -0.003, 0.0251, 0.0567, -0.0799, 0.0251, 0.0338,
		0.0627, -0.0604, -0.0497, -0.1687, -0.2422, 0.3668, 0.0272, -0.0267, 0.001, -0.0988, -0.0455, 0.0157, 0.0194,
		-0.1364, -0.017, 0.0743, -0.05, 0.0226, -0.0282, 0.1564, -0.065, -0.07, -0.135, -0.1217, -0.1253, -0.0613,
		-0.037, 0.0647, -0.0236, -0.0294, -0.0089, 0.0553, -0.0575, 0.0743, -0.1011, 0.032, 0.021, 0.0072, -0.0651,
		0.0227, 0.0132, 0.0235, 0.0008, -0.0433, 0.0157, 0.0025, 0.0127, -0.0097, 0.0004, 0.0072, 0.0112, -0.008,
		0.0148, -0.0298, -0.0321, -0.0085, 0.0237, -0.0519, -0.0877, -0.0271, 0.0468, 0.1426, 0.0786, -0.2, 0.0919,
		0.0745, -0.1218, 0.0646, 0.0599, 0.1185, -0.0456, 0.0454, 0.0717, -0.0023, -0.0728, -0.0542, 0.1304, -0.0294,
		0.0459, -0.0626, -0.1667, -0.0394, 0.0035, -0.0001, 0.0012, 0.0135, -0.0052, 0.0065, -0.0052, 0.0032, -0.0308,
		-0.0252, 0.011, -0.0523, 0.1299, -0.099, 0.2875, 0.0037, 0.0091, 0.0516, -0.0485, -0.004, 0.0113, -0.0349,
		-0.0238, -0.1452, -0.0097, 0.0291, 0.0358, -0.0016, -0.016, 0.0026, -0.0045, 0.0362, -0.0162, 0.02, 0.009,
		0.0681, 0.0209, -0.0272, -0.0033, 0.0101, 0.0025, -0.0001, -0.0086, -0.0097, -0.0098, 0.2599, 0.2504, 0.3628,
		0.223, -0.2237, -0.1568, 0.0768, -0.1862, -0.0092, -0.1047, -0.2372, 0.2454, -0.1107, 0.0422, 0.0346, 0.0077,
		0.0221, 0.0134, 0.031, 0.0372, 0.0638, 0.0604, 0.0333, -0.0043, -0.0312, 0.0762, -0.0848, -0.0053, 0.0402,
		-0.0248, 0.0052, 0.0251, 0.0295, 0.0703, -0.0066, -0.0037, 0.0398, -0.0077, -0.0156, -0.001, 0.0044, -0.0019,
		-0.0021, 0.0083, -0.0063, 0.0021, 0.0007, -0.0097, -0.0002, 0.0188, 0.0531, -0.0266, 0.3379, 0.0345, 0.0577,
		-0.092, -0.2128, 0.1312, -0.1188, 0.0766, 0.0571, -0.0768, -0.0332, -0.0136, 0.0112, -0.0064, 0.0058, 0.0097,
		-0.0416, -0.1417, -0.0213, 0.0403, -0.0368, 0.0118, 0.0289, -0.0255, -0.0089, 0.0304, -0.0198, -0.1089, -0.0252,
		0.037, -0.0549, 0.0366, 0.012, -0.0233, -0.0026, 0.0598, 0.0677, 0.4148, -0.051, 0.2514, -0.1709, 0.1241,
		0.1267, -0.1873, 0.2492, -0.0767, -0.0722, 0.1164, -0.035, 0.011, 0.0082, -0.0002, 0.0025, 0.0095, 0.0001,
		-0.003, 0.0083, 0.0003, -0.1509, 0.0343, 0.029, -0.0662, -0.0029, 0.1013, 0.0557, 0.1124, 0.1468, -0.0015,
		0.0282, 0.1186, 0.0426, -0.0131, 0.0873, -0.0269, 0.0079, 0.0039, -0.0023, -0.0013, -0.0012, -0.0089, 0.0064,
		-0.0661, 0.0024, 0.0002, -0.0269, 0.0906, -0.0195, -0.0118, -0.0145, 0.0057, -0.0079, -0.0075, -0.0134, -0.003,
		0.0031, -0.0082, 0.0037, -0.0178, 0.0016, -0.0388, -0.0261, -0.0186, 0.0052, 0.002, 0.0041, -0.0075, 0.0076,
		0.0002, -0.0055, 0.0111, 0.0057, -0.024, -0.0609, 0.0292, 0.1422, 0.1838, 0.0197, 0.2454, -0.1703, -0.162,
		-0.0222, -0.014, 0.0087, -0.008, 0.0435, 0.0321, -0.0348, 0.0062, 0.0006, 0.0098, -0.0055, 0.0008, 0.0033,
		0.0033, -0.0005, 0.0012, 0.0066, -0.0106, 0.0093, -0.0023, 0.0235, 0.0149, -0.0877, 0.1528, -0.0751, -0.0468,
		0.085, -0.071, 0.0551, 0.003, -0.0024, 0.0007, -0.0067, -0.0081, -0.0093, -0.0158, 0.0143, -0.0143, 0.1323,
		0.0275, -0.1862, 0.2306, 0.038, -0.0269, -0.1005, -0.0257, 0.0437, 0.1227, -0.0603, 0.0085, 0.0461, 0.0628,
		-0.0326, 0.0041, -0.0282, 0.0063, 0.0066, 0.0072, 0.0115, 0.0024, 0.0015, -0.0022, 0.0039, -0.003, 0.0018,
		-0.0008, 0, -0.0105, -0.0049, 0.0008, -0.0078, -0.0024, -0.0036, -0.0017, 0.0059, 0.0066, -0.0057, 0.0029,
		-0.0073, 0.0017, -0.0027, -0.0088, 0.0053, -0.0063, 0.0131, -0.018, 0.0052, -0.014, 0.0162, -0.0039, 0.012,
		-0.0015, 0.0899, 0.0382, -0.1171, 0.0185, 0.0847, -0.1087, 0.1366, -0.114, 0.0316, -0.106, 0.0591, 0.0842,
		-0.0171, 0.0468, 0.0019, -0.0167, -0.009, 0.0115, -0.0415, -0.077, -0.0072, 0.0652, 0.0237, 0.0243, -0.0106,
		0.0526, -0.0127, 0.033, -0.0008, 0.0123, -0.0275, 0.0049, -0.0155, -0.0371, -0.0554, 0.007, 0.1164, -0.1089,
		0.0129, -0.0139, -0.0437, 0.0656, 0.3924, 0.1241, 0.0769, -0.1675, 0.0554, -0.0454, 0.0306, 0.0325, 0.0033,
		-0.0235, 0.0401, -0.0282, 0.2216, -0.0492, -0.0331, -0.054, -0.0434, 0.0306, -0.0482, -0.023, 0.023, 0.0037,
		0.0145, 0.0152, -0.0133, -0.0169, 0.0402, -0.0023, -0.0092, -0.0888, 0.1481, -0.1242, 0.0223, 0.3292, 0.0509,
		-0.0798, -0.0363, -0.0024, 0.0001, 0.0024, 0.0022, -0.0044, 0.0031, 0.0001, 0.0066, -0.0015, 0.0033, -0.0043,
		-0.0036, 0.0425, -0.0209, -0.0729, 0.0414, 0.0808, -0.0151, 0.0178, -0.017, 0.0691, -0.0767, -0.0063, 0.1169,
		0.0119, 0.0815, 0.0229, 0.0197, 0.0626, -0.0055, -0.0276, -0.1159, 0.0103, -0.0435, 0.0052, -0.031, -0.0225,
		0.019, 0.0234, 0.0067, -0.0316, 0.0119, 0.0157, 0.0038, 0.0032, -0.0196, 0.0091, -0.0098, -0.046, 0.0884,
		0.0004, -0.1778, -0.0304, 0.1035, 0.0169, -0.0406, -0.1334, -0.0057, 0.0335, 0.0114, 0.0257, 0.0649, 0.0179,
		-0.0174, 0.03, 0.0156, 0.0072, 0.0161, -0.0498, 0.0224, 0.0049, 0.0043, 0.0196, 0.0226, -0.0047, -0.008, 0.0017,
		0.0119, 0.0128, 0.0096, -0.0053, -0.0161, -0.0009, 0.0072, -0.003, 0.0212, 0.0101, 0.0085, 0.0056, -0.0216,
		0.0414, -0.0142, 0.0053, -0.0179, -0.0122, 0.0157, 0.0163, -0.1125, 0.0316, 0.0413, -0.0938, 0.0367, -0.0216,
		0.0008, 0.0056, 0.0072, -0.0018, 0.0078, 0.0058, -0.0097, -0.0038, -0.0012, 0.036, 0.169, -0.0866, -0.1315,
		-0.0968, -0.2198, 0.0519, -0.24, 0.014, 0.1737, 0.1399, -0.1176, -0.1814, 0.2924, -0.1365, 0.0754, -0.0307,
		-0.0515, 0.078, -0.0407, -0.048, -0.0773, 0.2592, -0.0967, 0.0652, -0.0803, -0.0092, 0.0019, 0.0227, -0.0292,
		-0.01, -0.0381, 0.0095, -0.0516, 0.0169, -0.1001, 0.0764, -0.0393, 0.0694, -0.0311, -0.1177, 0.0625, 0.0033,
		0.1163, 0.0336, -0.1491, 0.0186, -0.0106, 0.121, 0.0722, -0.0615, -0.0056, -0.0809, 0.1757, 0.049, -0.1082,
		-0.1835, -0.0083, 0.1166, -0.0549, 0.0192, 0.0413, 0.0044, 0.0164, 0.0852, -0.077, -0.1048, -0.0114, -0.0311,
		-0.0142, 0.045, 0.0415, -0.0834, 0.1752, 0.0146, -0.1308, 0.0736, -0.0368, 0.1418, -0.0509, 0.0304, 0.1495,
		0.0883, 0.2181, 0.0962, 0.0932, 0.028, -0.0192, -0.0707, -0.067, -0.0288, 0.0019, -0.0138, 0.0011, -0.006,
		0.0014, -0.0037, 0.008, -0.0026, 0.0121, 0.1457, -0.043, 0.0274, 0.0367, 0.0093, -0.1006, -0.003, -0.0003,
		0.0145, 0.003, 0.0002, -0.0696, -0.1883, -0.1174, -0.0141, -0.0924, -0.0836, 0.0704, 0.0376, 0.0009, -0.0172,
		-0.0055, 0.0215, 0.0173, 0.0056, -0.0032, -0.003, -0.0291, 0.0427, -0.0891, 0.0763, 0.0034, 0.0129, 0.0182,
		-0.0029, 0.0229, 0.0014, -0.0016, 0.0018, 0.0086, -0.0097, 0.0136, 0.0123, -0.0112, -0.0008, -0.0044, 0.0057,
		-0.0041, 0.0057, -0.003, -0.0173, -0.0068, 0.0121, -0.0091, -0.0075, -0.009, 0.0225, 0.0102, -0.0246, 0.0149,
		-0.0034, 0.0308, -0.0083, -0.0152, -0.0126, -0.0013, 0.0034, -0.0069, -0.0104, 0.0031, 0.0201, -0.0041, 0.0077,
		0.0145, -0.0002, -0.0041, 0.0061, -0.0319, -0.0803, 0.135, 0.0444, 0.1162, -0.0473, -0.01, -0.0032, -0.0045,
		-0.0078, 0.0127, -0.0624, 0.0191, -0.0311, 0.0295, -0.0678, 0.1369, -0.0579, 0.0205, 0.0116, 0.006, 0.009,
		0.0225, 0.0041, 0.0037, -0.0194, 0.1595, 0.1296, -0.0437, -0.1899, 0.0254, 0.0677, 0.0522, 0.0322, 0.0443,
		0.0168, -0.0051, 0.0145, 0, -0.0087, 0.002, -0.0017, -0.0043, -0.0199, -0.0639, 0.1421, -0.1202, 0.1577,
		-0.1286, 0.1511, -0.1156, 0.071, 0.0228, 0.016, 0.0281, -0.0122, 0.1329, -0.0307, 0.0752, 0.0317, 0.0073,
		-0.0225, 0.0046, 0.0008, -0.0123, 0.0062, 0.0069, 0.006, -0.002, -0.0166, 0.0059, -0.0007, -0.0064, -0.0194,
		0.027, -0.0758, -0.0935, -0.0619, 0.0196, 0.0582, -0.0107, 0.0474, -0.0032, 0.0095, -0.0064, 0.0091, 0.0114,
		-0.0012, 0.0068, 0.0025, -0.0048, -0.0051, 0.0069, 0.0308, -0.0128, -0.026, 0.0104, -0.055, 0.0864, -0.0771,
		-0.1031, 0.1099, -0.0533, 0.08, -0.0554, -0.0415, 0.0636, 0.0367, -0.0433, -0.0047, 0.0704, 0.0995, -0.0528,
		-0.1379, -0.0431, 0.2319, 0.1735, -0.2271, 0.0182, -0.2019, -0.0764, 0.0174, 0.124, 0.0444, 0.0079, -0.0354,
		-0.0268, 0.1356, -0.1028, -0.0458, 0.0826, -0.049, -0.0255, 0.0322, 0.0125, -0.0028, -0.0004, 0.0442, -0.0084,
		0.0955, -0.0613, 0.1238, 0.0212, -0.066, 0, 0.0062, 0.0098, 0.0034, 0.0027, -0.0038, -0.0013, 0.0087, 0.0037,
		0.0098, -0.0717, 0.0034, 0.0004, 0.0113, -0.0047, -0.0009, 0.02, 0.0024, 0.0571, 0.0376, -0.0481, -0.0069,
		-0.0785, -0.1383, 0.2363, 0.0542, -0.1182, -0.0977, -0.1044, -0.0034, -0.0805, 0.0027, -0.0046, 0.0053, -0.006,
		-0.0034, 0.0059, -0.0024, 0.005, -0.0082, 0.0118, -0.0156, 0.0082, -0.0042, 0.0072, 0.0525, 0.0824, 0.0576,
		0.0763, 0.0387, -0.1312, -0.0208, 0.0019, 0.0463, -0.0131, 0.011, 0.0088, 0.0004, 0.0274, -0.0746, 0.0204,
		-0.0359, 0.0188, -0.0984, -0.0359, 0.0136, -0.0058, 0.0032, -0.0659, -0.1695, -0.0094, 0.0841, -0.2054, -0.0675,
		-0.0821, -0.0578, 0.0324, 0.045, 0.0076, 0.0241, -0.0445, 0.021, -0.0888, -0.0869, -0.041, -0.0472, 0.1658,
		-0.1153, 0.0192, -0.0521, -0.0454, -0.2387, -0.1649, -0.1091, -0.1871, -0.1809, -0.0089, -0.0072, -0.004,
		-0.005, -0.003, 0.0026, -0.0263, -0.066, 0.0001, 0.0061, 0.079, 0.0241, 0.0117, -0.0158, 0.0364, 0.0052, 0.0353,
		-0.001, -0.0303, 0.0001, 0.0243, 0.009, 0.0042, -0.0362, 0.0046, 0.0114, -0.0083, 0.0033, -0.0035, -0.0016,
		-0.0009, -0.0006, -0.0017, 0.0006, -0.0068, 0.0027, 0.0021, -0.0085, -0.0069, 0.0016, -0.0011, 0.0036, -0.0027,
		0.0051, 0.0039, 0, 0.0063, -0.0016, -0.0028, 0.0038, -0.0054, 0.0001, 0.0016, -0.0029, -0.0045, -0.0088,
		-0.0041, -0.0141, -0.0068, 0.0021, -0.0064, 0.0008, -0.0003, -0.0066, 0.0108, -0.0009, 0.0006, 0.0026, 0.004,
		0.0044, -0.0015, -0.0066, -0.0202, 0.0731, -0.1266, -0.0816, 0.0147, -0.049, -0.0276, 0.0208, -0.0007, -0.007,
		0.0189, 0.0419, 0.0874, -0.0654, -0.1916, 0.075, 0.0071, 0.0237, 0.0179, 0.0202, 0.0343, -0.0476, -0.1676,
		-0.0308, 0.0014, 0.1097, -0.0953, 0.0214, -0.114, -0.0979, -0.0318, -0.066, -0.0942, -0.1138, -0.1213, -0.1579,
		-0.1333, -0.0097, -0.0207, -0.1786, -0.0624, 0.0251, 0.0163, -0.0331, 0.055, -0.0411, 0.0271, -0.0074, -0.0358,
		-0.033, 0.0386, -0.0122, 0.0331, 0.0376, 0.0156, -0.0602, -0.1125, -0.1589, -0.151, -0.1477, -0.1557, -0.1113,
		-0.1227, -0.1222, -0.1519, -0.0737, 0.0136, -0.0082, -0.0056, 0.0251, 0.0172, -0.0068, 0.0166, -0.0603, 0.1708,
		-0.0803, 0.0637, -0.0988, 0.0788, 0.5165, -0.036, 0.0281, -0.0422, -0.0635, 0.0084, 0.0031, 0.0035, 0.0009,
		0.0029, -0.0117, -0.0434, 0.0282, 0.0117, -0.0119, -0.0042, 0.0051, 0.0101, 0.0018, 0.0017, 0.0049, -0.0007,
		-0.0221, -0.0114, 0.0101, -0.0548, -0.1074, -0.1544, -0.0328, 0.0907, 0.3061, -0.0123, 0.0876, -0.0016, 0.1192,
		0.0434, -0.1027, 0.0594, -0.0424, -0.01, -0.1013, -0.01, 0.1592, -0.1383, -0.0198, 0.0672, -0.0276, 0.031,
		-0.0208, -0.0123, 0.0036, 0.0112, 0.0076, -0.0075, -0.0039, -0.0174, 0.0024, -0.0023, 0.0019, -0.0014, 0.0053,
		-0.0009, 0.0163, 0.0005, -0.0098, -0.0041, -0.0066, -0.0046, 0.0065, 0.0017, -0.0092, -0.0028, 0.0105, 0.0036,
		-0.0018, -0.0157, -0.0097, -0.0009, -0.01, -0.0129, -0.001, -0.0051, 0.0022, -0.0105, 0.0013, -0.0018, 0.0019,
		-0.0019, -0.0094, -0.0086, -0.0007, 0.0058, 0.0097, 0.0034, -0.0017, 0.0041, 0.0005, 0.0021, 0.0038, -0.0129,
		-0.0792, -0.0171, 0.1152, 0.1548, 0.291, 0.3501, 0.2892, 0.0746, 0.2419, 0.1278, -0.1863, -0.2095, -0.1172,
		-0.1709, -0.0856, -0.0206, -0.1029, -0.0891, -0.1071, -0.1042, 0.0529, -0.1068, 0.0035, -0.0489, -0.0099,
		0.0142, -0.0061, 0.0015, 0.0077, -0.0044, 0.0247, 0.024, -0.0522, -0.165, 0.1592, 0.0356, 0.0607, -0.0336,
		0.0003, -0.0054, 0.007, 0.0019, 0.001, -0.0125, -0.0006, -0.0095, 0.0278, 0.1669, -0.0877, 0.2267, -0.084,
		0.1107, -0.0123, 0.0219, 0.0144, 0.0154, 0, -0.0011, 0.0117, 0.0021, 0.0434, -0.1409, 0.0102, 0.1134, -0.0119,
		-0.0238, 0.0044, -0.0025, 0.0015, -0.0073, 0, 0.0025, -0.0046, 0.0043, 0.014, -0.0137, 0.0159, -0.0321, 0.0383,
		-0.0309, -0.0981, -0.049, -0.0348, -0.0517, -0.0076, 0.0173, -0.005, 0.0018, -0.0048, 0.0074, 0.0045, 0.0043,
		0.0125, -0.0126, 0.0218, 0.073, -0.0411, 0.0612, 0.0033, 0.3622, -0.0603, -0.066, -0.181, -0.033, 0.1003,
		-0.0266, 0.297, 0.004, -0.0654, -0.0459, 0.1065, -0.0289, 0.0096, -0.0522, -0.0115, -0.0325, 0.0019, -0.0185,
		0.1734, -0.042, 0.2708, 0.0702, -0.0742, -0.0902, -0.1335, -0.1371, -0.1077, -0.0773, 0.0277, 0.0077, -0.0487,
		-0.0611, -0.0756, 0.0777, -0.0256, -0.0217, 0.0204, 0.0249, 0.0066, 0.0085, -0.0119, 0.0098, -0.0253, -0.0019,
		0.0015, 0.0103, 0.0016, -0.0084, -0.0002, 0.0076, -0.0071, -0.0041, 0.009, 0.0107, 0.0181, -0.0239, 0.0521,
		0.0178, 0.0229, -0.0693, -0.051, 0.0073, 0.0959, 0.0723, -0.0072, -0.0552, -0.0313, -0.0386, 0.0733, -0.1289,
		0.133, -0.1024, 0.0239, 0.0596, 0.0327, 0.0084, -0.0037, -0.0026, -0.0057, 0.0021, 0.0029, 0.0058, -0.0017,
		0.0039, 0.0292, 0.0164, -0.0043, 0.0491, -0.0979, 0.061, 0.0358, -0.0722, 0.0279, 0.1892, -0.2434, 0.0249,
		-0.0417, 0.0306, -0.0924, 0.0565, -0.0376, -0.0118, 0.0537, -0.0694, 0.0448, -0.0115, -0.0027, 0.0009, 0.0015,
		-0.0044, 0.0058, 0, 0.0009, 0.0116, 0.0065, 0.0046, -0.0181, 0.0106, 0.0521, -0.0656, 0.0494, -0.0355, -0.0327,
		-0.3115, -0.0388, -0.1305, 0.0879, 0.0672, 0.1256, 0.0775, -0.068, 0.0845, -0.0007, 0.0021, 0.0218, 0.0209,
		0.0048, 0.0071, 0.1762, 0.0756, -0.2149, -0.1127, -0.0124, 0.0615, 0.0045, -0.0178, 0.0835, 0.0348, -0.151,
		0.0762, 0.0031, 0.0953, -0.1293, -0.0944, 0.2035, 0.1254, -0.0647, 0.0063, -0.1098, -0.0784, 0.0899, -0.0167,
		-0.0052, 0.0178, 0.006, -0.0014, 0.0055, 0.0033, 0.011, -0.0042, -0.0041, -0.0068, -0.0089, 0.0041, -0.0034,
		0.011, 0.0378, 0.0222, -0.1214, 0.0135, 0.013, -0.0607, -0.0258, 0.0281, -0.0082, -0.0252, 0.0309, 0.0632,
		0.0996, -0.0095, -0.0436, -0.0143, 0.0287, -0.1195, 0.0532, -0.0375, 0.0078, -0.0903, 0.0277, 0.0476, -0.0512,
		0.032, -0.0031, 0.0025, 0.024, 0.0246, 0.0212, -0.0062, 0.0159, -0.0528, 0.0103, -0.0035, -0.0335, 0.0361,
		-0.0127, 0.1874, -0.0862, 0.1008, 0.0674, -0.0238, -0.1323, 0.0356, -0.0507, -0.1804, -0.0162, 0.0053, -0.0647,
		0.0209, 0.0078, -0.0077, 0.0016, -0.0469, 0.0259, 0.0182, 0.031, -0.0076, 0.0102, -0.0212, 0.0183, 0.0265,
		0.0022, -0.006, -0.0024, -0.0012, 0.0095, 0.0133, -0.0057, -0.0016, -0.0122, 0.0001, 0.0003, 0.0144, -0.026,
		0.0911, -0.1006, 0.1186, -0.1262, 0.0737, -0.221, 0.0153, 0.1112, -0.1014, 0.0104, -0.0038, -0.009, 0.0002,
		0.0013, -0.0029, 0.0013, -0.0073, 0.003, -0.0114, 0.0012, -0.0003, -0.0006, -0.0042, 0, -0.0035, 0.0008,
		-0.0045, 0.0074, 0.0018, 0.0017, -0.0066, -0.002, 0.0019, -0.0016, -0.0026, -0.0055, 0.0014, 0.012, -0.005,
		0.0029, 0.0064, -0.0055, 0.0045, 0.0098, -0.0086, -0.0043, 0.0017, 0.0092, 0.0019, 0.0041, 0.0016, -0.0031,
		0.0025, -0.0056, 0.0066, 0.043, -0.071, -0.0228, -0.0073, 0.0227, -0.1316, -0.0145, -0.1752, -0.0166, 0.0026,
		-0.0097, 0.0322, 0.0547, -0.0265, -0.0347, -0.007, 0.0056, 0.0006, -0.0047, -0.0021, 0.0079, 0.0016, -0.0015,
		-0.009, -0.0886, 0.0673, 0.0712, 0.1196, 0.0944, 0.2137, 0.0425, -0.1125, -0.0571, -0.1547, -0.0424, 0.1692,
		-0.0171, -0.0001, -0.0014, 0.0064, 0.0002, 0.0018, -0.0115, 0.002, 0.0004, -0.0079, 0.0157, 0.0332, 0.2247,
		0.0051, 0.0047, 0.24, 0.0538, 0.231, -0.2046, -0.0423, -0.0147, -0.144, -0.0489, 0.034, -0.0186, 0.0591,
		-0.0328, 0.0619, -0.0494, 0.0122, -0.0218, -0.0114, 0.0079, -0.0215, -0.0052, -0.026, -0.0206, -0.0231, 0.0451,
		0.0886, 0.0717, 0.0277, 0.0783, 0.0461, 0.0064, 0.0074, -0.002, 0.0009, 0.0013, -0.0002, -0.0014, -0.012,
		-0.0022, 0.0105, 0.0078, 0.0059, -0.0013, -0.0088, -0.0028, 0.0013, 0.0093, -0.0063, 0.0009, 0.0007, 0.002,
		0.0058, 0.0212, -0.0284, 0.0021, 0.0456, -0.0042, 0.1145, -0.0233, -0.0123, -0.1658, 0.2841, -0.0399, -0.0666,
		-0.0436, -0.0292, 0.0051, 0.0159, -0.0032, 0.087, -0.0346, -0.0328, -0.0486, -0.1753, 0.1215, 0.046, -0.0938,
		0.1181, 0.0116, 0.0006, 0.0056, -0.0091, -0.0086, -0.0026, 0.0013, 0.0028, -0.0068, 0.003, 0.0067, 0.0222,
		0.0182, -0.0399, -0.0451, -0.0322, 0.0164, -0.0183, 0.0778, -0.0063, -0.0081, 0.0027, 0.0047, -0.0294, -0.1285,
		-0.0649, 0.0298, -0.199, 0.0096, 0.0513, -0.1012, 0.0754, 0.03, -0.0139, -0.0127, 0.0262, 0.0074, 0.01, 0.0192,
		0.0048, -0.0047, 0.0067, -0.0028, -0.0317, -0.0222, -0.0348, -0.008, 0.0278, 0.0253, -0.0263, 0.0126, -0.0148,
		-0.0121, -0.0367, -0.027, 0.0157, 0.0137, -0.0118, -0.0274, -0.0094, -0.0354, -0.0106, 0.0429, 0.0498, 0.0919,
		0.0141, -0.0592, 0.058, -0.0067, -0.0058, -0.0284, 0.0262, 0.0023, 0.0171, -0.011, 0.0112, 0.0064, 0.005,
		-0.0048, 0.0053, -0.0003, -0.0033, -0.0011, 0.0088, 0.0064, 0.0019, 0.0027, 0.0077, -0.0329, 0.0029, 0.0575,
		-0.1586, 0.0075, 0.0774, -0.0226, -0.0275, -0.0653, -0.0517, -0.0106, 0.0603, 0.0157, 0.0884, 0.0005, -0.0749,
		-0.0037, 0.0389, 0.0264, -0.0405, 0.0738, 0.0698, 0.0024, -0.014, -0.1249, -0.184, -0.1061, -0.0669, -0.0831,
		-0.0964, -0.0478, 0.1176, -0.052, 0.0523, 0.0105, 0.0043, 0.0113, -0.0008, -0.0027, 0.0009, -0.0028, 0.0027,
		0.0092, -0.0505, 0.0041, 0.0013, 0.0019, -0.0037, -0.0026, -0.001, -0.0414, -0.0498, 0.0008, 0.012, 0.0493,
		-0.0038, -0.0219, -0.0188, 0.0796, 0.0776, -0.1116, -0.0469, 0.018, 0.0233, -0.0232, 0.002, 0.0275, 0.0003,
		0.0793, -0.0487, -0.002, 0.0084, 0, -0.0066, 0.0072, 0.001, 0.007, -0.0041, 0.0289, 0.0826, -0.0549, 0.047,
		0.0554, -0.1151, 0.0535, 0.0146, -0.0065, -0.0263, 0.0105, 0.0211, 0.0139, 0.0236, -0.0012, -0.009, 0.0006,
		0.0117, 0.0098, -0.0066, 0.0087, -0.0006, 0.0059, 0.0012, -0.0104, -0.0031, 0.0015, 0.0011, 0.0042, 0.0088,
		0.0015, 0.0023, 0.0079, -0.0019, 0.001, -0.0061, -0.0061, -0.0053, -0.0202, 0.0065, 0.0024, 0.0132, 0.0034,
		0.0686, -0.0039, 0.0524, 0.0137, 0.1007, -0.0042, 0.0578, -0.0424, 0.0189, -0.0022, -0.0078, 0.0207, -0.0145,
		0.0059, -0.0034, -0.0008, 0.0075, -0.0058, -0.0151, -0.0074, -0.0061, 0.0165, 0.0133, 0.0072, 0.0117, 0.0362,
		-0.1035, 0.0139, 0.1029, -0.0309, 0.0215, -0.0343, 0.0733, -0.0611, -0.0262, -0.0109, -0.0063, -0.0006, -0.0005,
		0.0027, -0.0057, -0.01, 0.0098, -0.0027, 0.0102, -0.0038, -0.0061, -0.002, -0.0083, 0.0295, -0.1088, -0.001,
		-0.0259, -0.046, 0.044, -0.0062, -0.0878, 0.0721, 0.0335, -0.0433, 0.0456, -0.0155, -0.0165, 0.0283, 0.0073,
		0.0241, -0.0742, -0.0717, 0.0794, 0.0108, -0.0531, 0.0063, -0.0149, 0.0035, -0.007, 0.0036, 0.0016, -0.0057,
		-0.0065, -0.0282, 0.0412, 0.0025, 0.1041, 0.0301, 0.0627, 0.0163, 0.0023, -0.0179, -0.0409, -0.0426, -0.0179,
		-0.0216, 0.0141, 0.0042, -0.0092, 0.0096, -0.029, -0.0036, -0.0057, -0.0057, 0.0039, 0.0059, 0.0209, 0.0236,
		0.0167, 0.0009, -0.0017, -0.0012, 0.0051, 0.0043, 0.0051, 0.0009, -0.0101, 0.0027, 0.0448, -0.0132, 0.0358,
		0.0122, -0.016, -0.1418, 0.0435, 0.0136, 0.0473, 0.0033, 0.0022, 0.0032, 0.004, 0.0155, 0.0039, 0.0063, 0.0166,
		-0.0678, -0.0192, 0.0648, 0.0276, -0.006, 0.0188, -0.0196, 0.0017, -0.0005, -0.0088, 0.0146, 0.0021, -0.0015,
		-0.0445, -0.0097, 0.04, 0.0018, -0.0072, -0.0246, 0.0605, -0.0545, 0.1104, -0.1245, -0.0019, 0.0247, -0.0225,
		-0.0851, 0.007, 0.0222, 0.053, -0.0679, -0.0392, -0.0209, 0.035, -0.0035, -0.0677, 0.0274, -0.0376, -0.0236,
		0.0217, 0.0061, 0.0047, -0.0056, 0.0049, 0.0098, -0.0203, 0.0076, 0.0014, 0.0054, -0.0168, 0.0108, -0.0092,
		-0.0424, -0.008, 0.1147, -0.0301, -0.0999, 0.0288, -0.0155, -0.0052, 0.0017, -0.0008, 0.0011, -0.0097, -0.0001,
		0.0469, -0.0344, 0.0934, 0.0875, 0.062, 0.0587, 0.0073, -0.0078, -0.0059, -0.0091, 0.0036, 0.0073, 0.0062,
		0.0044, -0.0073, -0.0119, -0.009, -0.0623, 0.0077, 0.0512, 0.0051, -0.0071, -0.0108, 0.004, 0.0059, -0.0005,
		-0.0025, 0.0024, -0.0187, -0.0652, -0.0364, -0.0084, 0.0815, -0.0293, -0.0094, -0.002, 0, -0.0029, -0.0038,
		0.0019, 0.0009, 0.0345, 0.0559, -0.0448, 0.0383, -0.0005, 0.0006, 0.0032, 0.009, -0.001, 0.0108, -0.0077,
		0.0839, 0.0425, 0.0065, -0.084, 0.0778, 0.0962, -0.0027, 0.1059, 0.0132, -0.0495, -0.0905, -0.0103, -0.0062,
		-0.006, 0, -0.0172, 0.0035, 0.0026, -0.0136, 0.0022, 0.003, 0.0173, 0.15, -0.104, -0.2105, -0.1534, 0.0768,
		-0.0068, -0.0578, 0.0209, 0.0887, -0.0994, -0.1035, 0.048, 0.1178, 0.0748, 0.0371, 0.0416, 0.0633, 0.0625,
		-0.001, -0.0038, 0.0035, -0.0255, 0.0658, 0.0054, 0.0096, -0.087, 0.211, -0.0076, 0.1497, -0.0042, -0.1803,
		-0.0137, 0.0397, 0.0438, 0.0059, 0.0055, 0.0047, 0.0178, -0.001, -0.0044, 0.0036, -0.0093, -0.0221, 0.0471,
		-0.0155, 0.0401, 0.0251, 0.0053, 0.0083, 0.0012, -0.0101, 0.0104, 0.0072, 0.0228, -0.06, -0.0731, 0.0222,
		-0.0375, 0.0321, -0.0075, -0.0023, -0.0018, 0.0058, -0.0022, -0.0014, -0.0035, 0.0035, -0.003, -0.0019, -0.0167,
		0.0358, -0.1182, -0.1009, -0.0652, -0.0034, 0.0164, 0.0261, 0.0176, 0.0082, 0.0006, 0.0221, -0.0044, 0.0067,
		0.0001, 0.0028, -0.0465, -0.0411, -0.0057, 0.0118, 0.034, 0.0045, 0.0096, -0.0241, -0.0132, 0.0025, 0.0011,
		0.0059, 0, -0.0074, 0.0029, 0.001, 0.0428, -0.0348, -0.071, 0.0359, -0.1039, -0.0393, 0.0447, 0.0175, 0.0178,
		0.0116, -0.0584, -0.0198, -0.0007, 0.0257, 0.0448, -0.0264, -0.029, 0.0164, -0.0322, 0.0072, -0.0258, -0.083,
		0.0896, -0.0103, 0.0228, -0.067, -0.1061, 0.0442, -0.0209, 0.0363, -0.0073, -0.0307, 0.0139, -0.0332, -0.0238,
		0.0148, 0.0024, -0.0189, -0.0014, 0.0087, -0.0145, 0.0132, -0.024, 0.0138, 0.0378, -0.011, -0.0105, -0.0419,
		-0.0007, -0.0195, 0.0117, -0.0167, -0.0089, 0.0117, 0.0043, -0.0054, 0.0021, -0.0008, 0.0036, -0.0209, 0.065,
		-0.1215, -0.0571, -0.0335, -0.0121, -0.0244, 0.008, 0.0045, 0.0759, -0.0794, 0.0777, -0.0125, -0.014, -0.0124,
		0.0077, -0.0089, -0.0019, 0.001, 0.0113, 0.01, 0.0004, -0.0009, -0.0043, 0.0082, 0.0302, 0.0314, -0.0686,
		-0.0416, 0.0049, -0.0029, 0.0085, 0.0256, -0.0029, -0.0205, -0.011, 0.0072, 0.0024, -0.0044, 0.0017, 0.0091,
		0.0082, 0.0121, 0.0013, 0.0004, -0.0046, -0.0089, -0.0717, -0.0018, 0.0044, 0.017, 0.0238, 0.0361, 0.0736,
		0.077, 0.0545, 0.0382, 0.0873, -0.0133, 0.0286, -0.0261, 0.0004, 0.0045, -0.0258, 0.0313, 0.0229, -0.0413,
		0.0098, 0.0144, -0.0192, -0.0006, -0.0015, -0.0062, -0.0015, -0.0092, -0.0016, 0.0005, -0.0076, -0.0025,
		-0.0041, 0.0004, 0.0041, -0.0028, -0.002, -0.0046, 0.0013, -0.0038, 0.0066, 0.0019, 0.0013, 0.0098, -0.0042,
		0.0023, 0.0035, -0.0062, 0.003, -0.0082, -0.0046, -0.0029, -0.0044, -0.0013, 0.0004, -0.0043, 0.0084, 0.0015,
		0.0091, -0.0109, -0.0207, 0.0072, -0.0271, -0.0443, -0.0689, 0.0285, 0.0043, -0.0082, 0.0025, 0.0068, -0.0047,
		-0.0047, -0.0143, -0.0542, -0.0358, 0.0011, -0.0062, -0.0035, -0.0022, 0.0043, -0.0018, -0.0019, -0.0049,
		-0.0019, 0.0114, 0.0008, 0.0004, -0.0037, -0.0053, 0.0017, 0.0032, -0.0039, 0.0038, 0.0047, -0.002, -0.0078,
		0.0019, 0.0025, 0.0058, 0.0064, -0.1026, -0.1421, -0.0998, 0.1528, -0.0488, -0.0277, -0.0169, -0.002, -0.0022,
		-0.0042, -0.004, 0.0003, 0.004, -0.0018, 0.0061, 0.0048, 0.0014, 0.0031, 0.0016, -0.009, -0.0683, 0.0779,
		-0.059, -0.0152, -0.0101, 0.0156, 0.0186, 0.0295, 0.0116, 0.0051, -0.007, -0.0086, 0.0097, 0.0114, -0.0209,
		0.0015, -0.0148, 0.0174, 0.0141, 0.0026, -0.0036, -0.0024, 0.0097, -0.0036, -0.01, -0.0114, -0.0057, -0.001,
		-0.0156, -0.0069, -0.0032, -0.0105, -0.0122, -0.0028, 0.0064, -0.0143, -0.0201, 0.0038, 0.0166, 0.0214, 0.0096,
		0.0672, -0.0521, -0.0314, 0.009, 0.0339, 0.016, 0.0088, -0.0082, -0.0251, -0.0097, 0.0067, 0.001, 0.0019, -0.01,
		-0.0063, 0.0014, -0.0015, 0.0025, 0.0029, -0.0068, 0.0077, 0.0068, -0.0096, 0.0042, -0.0003, -0.0104, -0.0033,
		-0.0071, 0.0061, -0.0111, 0.0038, 0.0056, 0.007, -0.0161, -0.0255, 0.0202, 0.0377, -0.0115, 0.0001, -0.0353,
		0.0649, 0.0498, 0.0108, 0.0105, -0.0128, -0.0007, 0.0015, 0.0132, -0.0086, -0.0045, -0.0085, 0.0172, 0.0203,
		-0.0037, -0.0011, 0.0119, 0.0068, -0.0304, -0.0286, -0.0239, -0.0655, 0.0116, -0.0562, 0.0526, -0.0615, 0.0269,
		-0.0522, -0.0009, 0.0324, 0.0177, 0.0204, 0.0408, -0.0321, -0.0038, 0.0093, 0.0005, -0.0052, 0.0061, -0.0008,
		0.0089, 0.0054, 0.0007, 0.0021, -0.0876, -0.0306, 0.0064, -0.1031, 0.0119, -0.0638, -0.0095, -0.0258, -0.0295,
		0.0451, 0.0577, 0.0165, -0.0491, -0.0342, 0.0102, -0.0282, 0.0032, 0.0185, -0.013, -0.0081, 0.0315, -0.0023,
		-0.0075, 0.0007, -0.006, -0.0149, 0.0368, 0.0062, -0.1165, -0.0627, 0.0822, -0.0157, 0.1055, -0.0501, 0.0032,
		0.0086, 0.0112, 0.0322, 0.0217, 0.0188, 0.066, -0.026, -0.0089, -0.0387, -0.1033, -0.0687, -0.0082, -0.0112,
		0.0112, 0.0412, 0.0542, 0.0791, 0.0558, 0.1373, 0.0493, -0.0289, 0.0762, 0.1094, 0.1704, -0.1252, 0.0787,
		0.0425, -0.0817, 0.0174, 0.0005, -0.006, 0.0144, -0.008, 0.0064, 0.0005, -0.0031, -0.0162, 0.0099, -0.0029,
		-0.0106, -0.0032, 0.0203, -0.0147, -0.016, -0.0323, 0.0626, 0.0774, 0.0049, 0.009, 0.0015, 0.0341, -0.0819,
		-0.0266, 0.0093, -0.0714, -0.0215, -0.0567, -0.0725, 0.062, 0.0407, -0.0116, 0.0075, -0.015, 0.0061, -0.0113,
		0.0038, 0.0013, 0.0106, 0.0146, 0.0148, 0.0072, 0.0115, 0.0432, 0.0322, -0.0014, 0.04, -0.0085, -0.0001,
		-0.0226, 0.0034, -0.0095, -0.0118, -0.0177, -0.0214, 0.0001, 0.0413, -0.025, 0.0152, -0.0306, 0.0342, 0.0342,
		0.0156, 0.0023, 0.026, -0.0302, -0.0102, 0.0005, 0.0022, 0.0079, -0.0299, 0.0001, -0.0143, -0.0271, -0.0013,
		-0.0084, -0.0096, 0.0126, -0.0093, -0.0154, 0.0291, 0.0203, -0.0017, 0.0179, 0.0054, 0.0044, -0.0023, -0.0153,
		0.0034, -0.0104, 0.054, 0.0335, -0.0743, 0.0805, -0.1971, 0.0853, -0.136, -0.0211, 0.0321, 0.0141, 0.0459,
		-0.1673, 0.0283, 0.1875, 0.0581, -0.0279, 0, 0.0419, 0.0226, -0.0456, -0.0123, -0.0591, -0.0417, 0.0032,
		-0.0187, -0.0177, 0.0248, -0.0087, -0.0049, -0.0017, 0.0089, 0.0223, 0.0037, 0.0082, -0.0014, 0.0005, 0.0036,
		-0.0257, 0.0046, -0.01, -0.0028, 0.0087, -0.0007, -0.0131, 0.0004, -0.0008, 0.0041, -0.0056, 0.0047, -0.0121,
		0.0004, -0.0165, 0.0016, 0.0007, 0.0122, -0.0051, -0.0186, 0.0247, -0.0036, 0.0101, 0.0038, 0.0137, -0.0031,
		-0.0254, 0.0186, 0.0096, -0.004, -0.0474, -0.0157, 0.0457, -0.0695, 0.0449, -0.1649, 0.0077, 0.0386, 0.0575,
		-0.0067, -0.0258, -0.0532, 0.0053, 0.0001, 0.0025, 0.039, 0.0468, -0.0455, 0.0321, -0.0191, 0.0097, -0.0002,
		0.0013, 0.0088, 0.0112, -0.0535, 0.0549, 0.0393, 0.1491, -0.0066, -0.0247, -0.0036, -0.0443, 0.0357, 0.0545,
		0.0129, -0.0008, -0.0035, 0.0148, 0.0127, -0.0041, 0.0105, -0.0075, 0.009, -0.0075, 0.0058, 0.0056, -0.0037,
		-0.0068, 0.0138, 0.0136, -0.0459, 0.054, 0.0569, 0.1132, -0.0341, -0.0509, -0.1539, 0.003, 0.0728, 0.1136,
		0.0221, -0.0569, 0.0019, -0.0032, 0.0085, 0.0022, -0.0092, -0.0099, -0.015, -0.0039, -0.0003, -0.008, -0.0159,
		-0.0194, -0.0277, -0.0101, -0.0198, -0.0202, -0.0069, -0.0027, -0.0076, -0.0001, -0.004, -0.0041, -0.0018,
		0.0074, 0.0033, 0.0023, -0.0012, -0.0067, -0.0082, -0.0042, -0.0039, -0.0065, 0.0225, 0.0184, -0.0026, -0.0141,
		-0.0325, 0.0053, 0.118, -0.1986, 0.0472, -0.019, -0.0328, -0.0862, -0.2987, 0.0093, -0.0714, 0.0009, -0.0617,
		-0.0535, -0.059, -0.0137, 0.0061, -0.002, 0.0033, 0.0018, -0.0026, -0.0027, 0.038, 0.0152, 0.0013, 0.0026,
		-0.0577, -0.0064, 0.0486, -0.2731, 0.0181, 0.0715, 0.0455, 0.0371, -0.0482, 0.036, 0.0255, -0.0848, 0.0469,
		0.0063, 0.0266, 0.0355, 0.0221, 0, -0.0221, -0.0114, 0.0079, 0.0138, 0.0094, -0.0094, 0.0848, 0.1268, -0.0514,
		-0.0226, -0.0373, -0.1236, 0.1742, -0.1282, 0.1292, -0.0953, 0.0881, -0.0605, 0.0906, -0.0668, 0.0129, 0.0157,
		0.0241, 0.0155, 0.0293, -0.0589, 0.0321, 0.0097, -0.0514, 0.0005, -0.0054, 0.026, -0.0112, 0.0736, -0.0262,
		-0.0096, -0.0617, 0.1143, -0.1046, 0.0434, 0.0339, -0.0253, -0.0944, -0.0126, 0.0146, -0.0647, -0.0595, -0.0299,
		-0.0199, -0.0148, -0.0256, -0.018, 0.0083, 0.0222, 0.0045, -0.0121, -0.0074, 0.0072, -0.0141, -0.0454, 0.0361,
		0.0037, -0.0235, -0.0895, -0.1068, 0.0636, 0.0044, 0.0317, 0, 0.0039, 0.0055, 0.0113, -0.0017, -0.004, -0.0383,
		-0.0025, 0.0086, -0.0437, -0.0092, 0.0651, -0.0465, -0.038, -0.0587, 0.0241, -0.0287, -0.0276, 0.058, -0.0901,
		-0.0747, 0.1988, 0.0298, 0.1893, 0.0274, -0.0527, 0.0028, 0.0351, -0.0461, 0.0068, 0.0084, -0.0576, 0.049,
		-0.0214, 0.0096, 0.0136, 0.0257, -0.1094, -0.0054, 0.1021, 0.1943, -0.1145, -0.1373, 0.1826, 0.0452, -0.031,
		-0.0683, 0.0233, 0.0132, -0.0043, 0.0114, 0.0031, 0.018, -0.0119, 0.0005, 0.0274, -0.058, -0.159, 0.0339,
		-0.1859, -0.0232, -0.0119, 0.0453, 0.0451, 0.083, 0.1612, -0.0328, -0.1913, 0.2528, -0.0658, 0.1149, 0.2733,
		-0.0064, 0.0217, -0.0396, 0.0277, -0.0252, -0.0185, 0.0095, 0.0395, -0.1024, -0.1152, -0.0067, 0.0753, 0.079,
		-0.1485, -0.0046, 0.0916, -0.0595, -0.0045, 0.0082, 0.0007, -0.0014, -0.0038, 0.0036, -0.0018, 0.0079, 0.0053,
		0.0272, -0.0264, 0.0086, -0.1289, 0.2031, 0.1283, -0.1515, -0.0336, 0.0008, 0.0034, -0.0004, -0.0054, -0.0027,
		0.0052, -0.0036, -0.0078, -0.0089, -0.0045, 0.0021, -0.0012, 0.0057, 0.0014, 0.0091, 0.0003, 0.0036, -0.0001,
		0.0008, -0.0041, -0.0014, 0.0025, 0.007, 0.0031, 0.0036, -0.0068, 0.0023, -0.0066, 0.001, -0.0069, 0.0111,
		-0.0052, 0.0045, -0.0013, 0.0044, 0, 0.0042, -0.003, 0.0122, 0.0057, 0.0132, 0.0084, -0.0123, -0.0453, 0.1685,
		-0.0316, -0.1007, 0.3134, -0.0061, 0.0171, 0.198, -0.1606, 0.0422, -0.0104, 0.0242, -0.0451, -0.0091, 0.0035,
		-0.0007, -0.0331, 0.0186, 0.0063, 0.0032, 0.0018, 0.0081, -0.027, 0.0321, -0.032, 0.0174, 0.0553, 0.0337,
		0.1921, -0.0583, -0.1204, 0.0575, 0.247, 0.1272, 0.192, 0.1643, 0.1663, 0.0949, 0.075, 0.0563, 0.1063, 0.1864,
		-0.0088, 0.0113, 0.0167, -0.0167, 0.0208, -0.0265, 0.0132, -0.0078, 0.0079, -0.0811, -0.0495, 0.1807, -0.0743,
		-0.1093, -0.1177, 0.0797, -0.0426, 0.062, 0.1302, 0.0822, 0.0482, 0.0548, -0.0251, -0.067, -0.1473, -0.0898,
		-0.0516, 0.1215, 0.0051, -0.0213, 0.0096, 0.0044, -0.0261, -0.0068, 0.0555, -0.1247, 0.0234, -0.0267, 0.137,
		0.0147, 0.0066, 0.0044, 0.007, 0.1685, -0.0447, -0.0814, -0.0668, 0.0244, -0.0803, -0.1855, -0.2063, -0.1752,
		-0.1786, -0.1117, -0.0276, 0.0054, -0.0077, 0.0012, 0.0058, 0.0004, -0.0002, -0.0109, 0.0082, -0.0017, -0.0099,
		-0.0206, 0.0028, -0.1103, -0.1217, 0.0109, -0.1237, 0.0202, -0.0704, 0.2667, 0.2304, -0.1405, 0.0274, 0.0218,
		0.0281, -0.0302, 0.0011, 0.0243, -0.0386, -0.1053, -0.0145, -0.051, -0.021, 0.1211, -0.0091, 0.1593, 0.051,
		0.0502, 0.0798, -0.0002, 0.0021, -0.0126, -0.0034, -0.0046, 0.0068, -0.0013, -0.001, -0.0011, -0.0168, -0.0024,
		0.0629, -0.0967, -0.1743, -0.0756, -0.1006, 0.0161, 0.0535, -0.0409, -0.0013, -0.1539, -0.0034, -0.0333, 0.0169,
		0.0013, -0.0399, 0.0737, -0.0761, 0.0561, 0.1435, 0.0598, -0.0401, 0.3826, -0.014, -0.1612, -0.216, -0.0386,
		0.0591, -0.075, 0.0182, -0.0948, 0.0297, -0.0238, 0.1121, -0.0082, -0.0604, 0.0083, 0.0485, 0.0195, -0.0139,
		-0.0742, -0.0394, 0.1224, -0.0553, 0.1066, 0.1274, -0.0493, 0.0406, -0.0095, -0.042, 0.0057, 0.0796, -0.0849,
		-0.183, -0.063, -0.242, -0.038, -0.0792, 0.1733, 0.0035, -0.0784, -0.0779, 0.0457, -0.0574, -0.0469, -0.0807,
		-0.0989, 0.0854, -0.1456, 0.011, 0.0308, -0.0464, 0.0362, 0.0797, -0.1066, 0.0219, -0.0067, 0.0205, -0.0056,
		0.028, -0.1402, -0.0233, -0.0074, 0.1142, 0.0137, 0.0041, -0.0038, -0.02, -0.0759, 0.1897, -0.1337, -0.123,
		0.0548, 0.3589, 0.3227, 0.1519, 0.0476, -0.0758, -0.0655, -0.0904, 0.0057, 0.0466, -0.0191, 0.0146, 0.0024,
		0.0007, -0.0068, 0.0025, -0.0011, 0.0105, 0.0038, 0.0193, 0.0224, -0.0324, -0.0132, 0.0057, 0.0064, -0.0072,
		0.0012, -0.0026, -0.0161, -0.066, -0.0448, 0.0318, -0.0625, 0.0783, 0.1315, -0.0928, 0.3146, 0.001, -0.0905,
		0.267, 0.0012, 0.0166, 0.0004, 0.001, -0.0067, -0.0008, -0.0012, -0.0119, -0.0819, 0.0332, 0.2267, -0.033,
		-0.0871, -0.0243, 0.0008, -0.088, -0.1093, -0.206, 0.1578, 0.0248, 0.0767, 0.0559, 0.0026, 0.0758, -0.0178,
		0.1583, -0.0296, 0.1305, -0.0108, -0.0369, -0.0281, -0.0344, -0.0462, 0.0368, -0.0003, 0.0022, -0.0105, -0.0037,
		0.0208, -0.0503, -0.0017, 0.3034, 0.0029, -0.0785, 0.0024, 0.076, 0.0612, 0.007, 0.0302, -0.0931, -0.0355,
		-0.0495, -0.0383, 0.0311, -0.024, -0.0245, -0.0375, -0.0013, 0.0225, 0.0355, 0.0012, -0.0217, 0.0151, -0.0004,
		-0.0104, 0.0055, 0.0134, 0.0005, -0.003, 0.0063, 0.0019, -0.0034, -0.0103, 0.0056, 0.0077, -0.0033, -0.0043,
		0.0039, 0.0008, 0.0118, 0.0009, 0.0028, -0.0092, 0.0042, 0.0041, 0.0025, 0.0002, 0.0017, -0.0041, 0.0034,
		0.0052, -0.0056, 0.0057, -0.0072, 0.0034, -0.0085, 0.0034, 0.0121, -0.0091, 0.0186, -0.0196, 0.0079, 0.003,
		-0.0003, 0.1276, -0.0785, 0.2028, 0.1055, -0.2081, -0.2105, -0.0504, 0.0845, -0.2758, 0.1757, -0.0922, 0.004,
		0.0044, 0.0093, 0.0239, 0.031, 0.014, 0.032, -0.0326, 0.0171, -0.0511, -0.1185, -0.003, 0.0708, 0.0686, -0.0188,
		0.1203, -0.1664, 0.0385, -0.0176, 0.0283, -0.0041, 0.0047, -0.0005, -0.0129, 0.0289, 0.0406, -0.0045, -0.1,
		-0.1229, -0.1912, -0.3573, 0.1861, 0.0883, 0.052, 0.1456, -0.0595, 0.1525, -0.0237, 0.052, 0.1452, 0.0679,
		0.2619, 0.0452, 0.1508, 0.0228, 0.186, -0.0807, -0.071, -0.163, -0.0495, -0.2093, -0.0027, 0.1025, 0.0735,
		0.0468, -0.0572, -0.0072, -0.0265, 0.0891, 0.0774, -0.0005, 0.0046, -0.0275, -0.1073, 0.0637, -0.1475, 0.0849,
		0.0549, 0.0361, -0.0102, 0.0415, 0.0839, -0.1711, 0.0331, -0.1001, 0.0033, 0.0035, -0.0291, -0.0419, -0.0499,
		0.0272, 0.0422, 0.0258, -0.0934, 0.0286, -0.1981, 0.0258, 0.0186, -0.0105, 0.0231, 0.0278, 0.0189, 0.0098,
		0.0078, -0.0591, 0.0917, -0.0167, 0.1514, -0.0691, 0.1795, -0.0287, 0.0156, 0.0164, -0.0222, -0.0406, -0.0008,
		0.0128, 0.0047, 0.0135, 0.0035, 0.0021, 0.0603, -0.0781, -0.0011, -0.0865, 0.0739, -0.0556, 0.1317, -0.031,
		-0.0874, 0.0069, 0.0173, -0.0226, 0.0082, -0.0474, 0.0003, 0.0118, 0.0102, 0.0071, -0.0163, 0.0037, 0.0031,
		0.0073, -0.0413, -0.0478, 0.0903, -0.0402, -0.0211, 0.0436, -0.008, 0.0257, -0.0673, -0.0605, 0.0415, -0.0269,
		0.0255, -0.2002, 0.0589, -0.0634, 0.0879, 0.0137, -0.0924, 0.0085, -0.0224, 0.0113, 0.0036, 0.011, -0.03,
		0.0158, 0.0083, -0.003, -0.0461, -0.0489, 0.0321, -0.0202, -0.1198, -0.0293, -0.0509, 0.0601, -0.0881, -0.008,
		0.0256, 0.0254, 0.0984, -0.027, 0.1071, -0.0828, -0.067, 0.023, 0.0213, -0.0625, 0.1443, 0.0713, -0.1098,
		-0.1034, 0.0616, 0.1353, 0.1342, -0.0039, -0.0291, -0.0036, -0.0091, 0.0127, -0.001, 0.0195, 0.0032, 0.0077,
		-0.012, 0.0234, 0.0645, 0.025, 0.0145, -0.0022, -0.0029, -0.0146, 0.0614, -0.0198, -0.0762, 0.1625, 0.0223,
		-0.1599, -0.0599, 0.114, -0.0287, 0.0181, 0.0377, 0.0148, 0.04, 0.0508, -0.1566, -0.0607, 0.0594, -0.0587,
		-0.01, 0.0325, -0.0296, 0.0264, 0.0093, 0.0254, 0.1598, 0.2564, 0.1936, 0.1343, -0.06, 0.2708, -0.1886, 0.0675,
		0.0075, -0.012, 0.054, 0.0077, 0.0039, 0.0196, -0.0571, -0.0162, 0.0101, 0.0363, 0.0824, -0.0545, 0.0185,
		0.0182, 0.0178, -0.0018, 0.0023, 0.0126, 0.0111, -0.0029, 0.0036, 0.0055, 0.0543, -0.0373, 0.0994, -0.0306,
		-0.0117, 0.023, 0.0552, -0.0206, -0.0674, -0.0534, -0.1541, 0.0086, 0.0907, 0.0285, 0.0825, -0.0398, -0.0084,
		-0.0228, 0.006, -0.0079, 0.0108, 0.0154, 0.0179, -0.0121, 0.0012, 0.0061, 0.0048, -0.0039, -0.0294, -0.0059,
		0.0047, 0.0179, 0.0027, -0.0087, 0.0003, 0.0079, 0.0098, -0.0201, -0.0007, -0.0075, 0.0096, -0.0067, -0.0924,
		0.0387, -0.0344, 0.1115, 0.0459, 0.0202, 0.1794, -0.0464, 0.1398, -0.0337, 0.0448, -0.0388, -0.0433, -0.0592,
		-0.13, -0.0651, -0.1927, -0.0706, -0.1657, -0.0629, -0.095, -0.0826, -0.1045, -0.0598, -0.0563, -0.0448, 0.0395,
		0.0551, -0.0255, 0.0851, 0.1388, -0.216, 0.1281, -0.1095, -0.0414, 0.0027, -0.0024, 0.0112, -0.0019, -0.0027,
		0.0049, 0.0084, -0.0016, 0.0058, -0.0048, -0.0041, 0.0226, 0.0062, -0.0146, 0.0151, 0.0043, 0.0127, 0.0135,
		-0.0151, 0.0119, 0.0041, 0.0014, -0.0095, 0.0144, 0, 0.0037, -0.001, 0.0004, -0.0121, 0.0076, -0.0407, -0.0087,
		-0.0326, 0.0111, -0.0043, 0.0042, -0.0039, 0.0044, -0.0005, -0.0075, 0.0006, 0.004, -0.0096, -0.0098, -0.0064,
		0.0005, -0.0014, 0.0036, 0.0008, -0.0116, 0.0003, 0.0041, 0.0073, 0.0084, 0.0073, -0.0019, 0.0046, 0.0118,
		0.0038, -0.0075, 0.0021, -0.0141, -0.0072, 0.0075, 0.0094, -0.002, 0.0026, 0.0079, -0.0095, 0.0046, 0.0067,
		-0.0023, 0.0159, -0.0039, -0.0123, -0.0038, 0.009, -0.0111, -0.0008, -0.0117, 0.0187, 0.0083, -0.0019, -0.004,
		0.0145, -0.0178, -0.0262, 0.0034, 0.0021, 0.0051, 0.0113, 0.0075, -0.0092, 0.0248, -0.0113, 0.0077, -0.0087,
		-0.0089, 0.0164, -0.0095, -0.0022, -0.0148, -0.0091, -0.0118, -0.0009, -0.0052, 0.0014, -0.0069, -0.0075,
		0.0023, 0.0097, 0.0042, -0.007, -0.0097, 0.0101, 0.0012, 0.0033, 0.0176, 0.0073, 0.0126, -0.0015, 0.0095,
		-0.0083, -0.0038, -0.0004, -0.0053, 0.009, -0.0008, 0.0009, 0.0061, 0.0009, -0.0053, 0.0081, -0.0128, 0.0074,
		0.0028, -0.0066, -0.0033, 0.0039, 0.0028, 0.0013, -0.0052, 0.0153, -0.1046, 0.2047, -0.0891, 0.0155, -0.0484,
		-0.0615, -0.1178, -0.3107, 0.2657, -0.2771, -0.0772, -0.0108, -0.0042, -0.0435, 0.0252, 0.0177, 0.0433, 0.0108,
		-0.0162, -0.0253, 0.0173, 0.0007, 0.0172, -0.0114, -0.0062, -0.0081, 0.0205, -0.028, -0.0084, -0.0179, 0.0084,
		-0.0513, 0.0084, 0.0347, 0.0006, -0.0034, 0.0479, 0.0105, 0.0274, -0.044, 0.0739, -0.0685, 0.0681, 0.0836,
		-0.1393, -0.0036, 0.122, 0.0081, 0.0533, 0.0003, 0.0344, 0.0007, 0.0143, -0.026, 0.0091, 0.0192, -0.0088,
		-0.0117, -0.0103, 0.0099, 0.0141, -0.0245, 0.004, 0.0161, -0.0317, -0.0133, 0.012, -0.0641, -0.1371, 0.186,
		0.119, -0.0153, -0.0911, -0.1506, 0.1284, -0.05, 0.1138, -0.1256, -0.0689, -0.1089, -0.1247, 0.2638, -0.1078,
		0.3092, -0.043, 0.326, -0.1202, -0.0902, -0.0099, -0.1377, 0.1048, -0.0859, 0.016, 0.0037, -0.0022, 0.004,
		0.0066, 0.0003, 0.0035, 0.0003, -0.0054, -0.0091, 0.0288, 0.0021, -0.0801, -0.0017, 0.0187, 0.1065, 0.1718,
		0.0628, -0.1465, 0.0208, -0.1849, 0.0675, 0.1117, 0.0475, -0.1376, 0.1916, 0.0156, -0.0009, -0.0057, 0.0228,
		-0.0092, -0.004, -0.0035, -0.0015, -0.011, -0.0027, 0.0069, 0.085, 0.0981, -0.07, 0.1563, -0.0422, -0.0496,
		0.0136, 0.1722, 0.0241, 0.0352, -0.0321, -0.0788, 0.011, 0.0078, 0.0045, -0.0219, 0.0092, 0.0137, -0.0257,
		0.0267, 0.0587, 0.02, 0.053, 0.0046, -0.0377, 0.0098, 0.0038, -0.003, -0.0078, 0.0001, 0.0079, -0.0127, 0.0007,
		-0.0114, 0.0623, -0.0922, -0.0167, 0.1088, 0.1783, 0.254, 0.0818, -0.0272, -0.0106, -0.0584, -0.1327, -0.0982,
		0.098, 0.0914, 0.0283, 0.056, -0.0017, 0.0086, 0.0757, 0.0326, 0.1002, 0.0271, 0.02, -0.0614, -0.0729, 0.0105,
		0.0002, 0.0001, -0.0007, 0.0165, -0.0018, 0.0253, 0.1422, 0.0306, 0.1623, -0.0312, 0.1533, 0.0078, -0.1002,
		-0.0189, 0.0508, 0.0254, 0.0028, 0.0014, -0.0185, -0.0856, 0.0203, -0.0452, -0.0771, -0.0856, 0.1108, -0.1007,
		-0.0269, -0.1968, -0.182, -0.2047, -0.0959, -0.1018, 0.016, 0.0115, -0.0491, 0.0314, -0.0081, -0.0836, 0.0408,
		0.191, -0.0949, 0.0333, 0.097, -0.0417, -0.0171, 0.0623, 0.0063, -0.004, 0.218, 0.0311, -0.1665, 0.0598, 0.0122,
		0.0152, -0.0723, -0.2561, -0.123, 0.0272, 0.0169, -0.0013, 0.0066, 0.0077, -0.0053, -0.0002, 0.0022, -0.001,
		0.0062, 0.0252, -0.0218, -0.0115, -0.0061, 0.0262, -0.1166, 0.0698, -0.1053, 0.0044, -0.0002, 0.0207, -0.058,
		0.0227, 0.0088, 0.0095, -0.0211, 0.0171, 0.0494, -0.041, -0.0544, 0.0097, 0.0251, -0.0976, 0.0279, 0.0197,
		-0.1594, -0.0491, 0.0503, -0.1438, 0.0677, 0.0291, -0.0996, 0.0471, 0.0755, 0.073, 0.1119, 0.0078, 0.0011,
		0.0095, -0.0233, 0.0073, -0.0262, 0.0205, -0.0009, -0.0109, 0.0138, 0.0073, 0.0331, 0.0598, -0.0198, -0.018,
		-0.0305, -0.0518, -0.1023, 0.0137, 0.0444, 0.0251, 0.2468, -0.0243, -0.0886, -0.0943, -0.126, 0.1333, -0.0797,
		0.048, 0.0085, 0.0076, 0.0066, 0.0068, 0.005, -0.0003, -0.0044, -0.0016, -0.003, 0.0047, -0.0104, 0.0121,
		-0.0419, -0.0637, -0.0018, -0.0674, 0.0973, -0.016, -0.0243, -0.0044, 0.0146, -0.0344, -0.021, 0.053, 0.0005,
		-0.0221, -0.0159, -0.0055, 0.0114, -0.0071, 0.0063, 0.0052, -0.0044, -0.0004, -0.0115, -0.0067, 0.0095, -0.0034,
		0.0118, 0.0069, -0.0052, 0.0016, -0.0067, -0.0006, 0.0004, 0.002, 0.0012, 0.0038, -0.0051, -0.0092, -0.0054,
		0.0019, 0.0011, 0.0007, 0.0047, 0.0014, -0.0011, -0.0166, 0.0063, 0.0068, -0.0505, -0.0621, 0.0116, -0.0506,
		0.0081, -0.0312, -0.0371, 0.165, 0.0047, 0.0396, 0.0182, 0.0085, 0.0049, -0.0436, 0.0187, -0.0253, -0.0084,
		0.0173, 0.0025, 0.0103, 0.03, 0.0345, 0.0432, 0.0274, 0.0473, 0.0152, -0.1149, 0.0093, 0.072, 0.102, -0.0452,
		0.0309, 0.0279, 0.0354, -0.0318, 0.0973, -0.0229, -0.0053, -0.0029, -0.0714, 0.0732, 0.0213, 0.0084, -0.0238,
		-0.0003, -0.0495, 0.0161, 0.0007, -0.032, 0.0494, 0.043, 0.0075, -0.0739, 0.1337, -0.022, -0.1897, 0.0189,
		0.0293, -0.0834, -0.0068, 0.0238, 0.0245, 0.0209, -0.137, 0.0698, 0.0434, 0.1048, 0.0485, -0.0374, 0.1297,
		-0.0609, 0.1047, 0.0155, -0.0434, 0.0387, 0.0436, 0.0219, -0.0387, 0.019, 0.014, 0.0024, 0.0129, 0.0083,
		-0.0046, 0.0234, -0.0089, -0.022, -0.0084, -0.0024, -0.0119, -0.0133, -0.0423, 0.0442, -0.0057, -0.0693, 0.0363,
		0.0086, 0.203, 0.1101, -0.1471, -0.2209, -0.0434, 0.0353, 0.1078, -0.0463, -0.0847, -0.0878, 0.09, 0.0133,
		0.0735, -0.0397, -0.0324, 0.0003, -0.0095, 0.0019, 0.0033, 0.0111, 0.0061, 0.0132, -0.0121, -0.0077, 0.0067,
		0.0006, 0.0063, 0.0303, -0.0088, 0.0146, -0.0199, 0.0341, -0.0401, 0.0064, -0.0212, 0.0194, -0.0145, 0.026,
		-0.0309, -0.019, -0.0018, -0.0207, 0.0013, 0.0127, 0, -0.0034, 0.0503, 0.0293, 0.0408, 0.0306, -0.0409, -0.0251,
		0.066, -0.0691, 0.0395, 0.0055, -0.0288, 0.0357, 0.0349, -0.0489, 0.0184, 0.0314, -0.1216, 0.0468, 0.0098,
		-0.0112, 0.1546, -0.0653, -0.0365, -0.1514, -0.1025, -0.0536, -0.0576, -0.1759, -0.2373, -0.117, -0.0307,
		0.3533, 0.0608, -0.0506, -0.0799, 0.0732, -0.0416, 0.0275, -0.0573, 0.0498, 0.0618, -0.0226, 0.1302, -0.0857,
		0.0296, -0.0053, -0.1137, -0.0911, -0.047, -0.0078, -0.0172, -0.0836, 0.068, -0.0495, 0.075, -0.0073, 0.0002,
		-0.0087, 0.0085, -0.0199, -0.0101, 0.0796, -0.1133, -0.0271, 0.0126, 0.0629, 0.0659, -0.0682, -0.1075, -0.0684,
		0.0301, 0.0326, 0.0069, 0.0131, -0.0066, -0.0062, -0.0074, -0.0843, -0.0245, 0.2109, 0.1404, -0.0062, 0.1687,
		-0.0739, -0.0445, -0.0339, 0.0427, 0.0002, -0.0154, -0.0056, 0.0004, -0.0022, 0.0027, -0.0101, 0.0453, 0.0673,
		-0.0508, 0.0726, 0.1153, -0.0511, -0.0027, 0.0693, -0.0164, 0.0273, -0.0572, 0.0143, 0.0001, -0.0052, 0.006,
		-0.0015, 0.003, -0.0082, 0.014, -0.0033, 0.1012, -0.0039, -0.0817, 0.08, -0.1365, 0.0045, 0.0066, 0.0305,
		0.0169, -0.0206, -0.0467, -0.0122, -0.0476, 0.0416, -0.0915, 0.0979, -0.175, 0.0971, 0.0441, -0.0528, -0.1281,
		0.0016, 0.204, 0.0894, 0.0447, 0.058, -0.0285, -0.0155, 0.0919, -0.0167, 0.0279, -0.0034, -0.0545, -0.0431,
		0.0017, -0.0595, -0.0212, 0.0217, 0.035, 0.0646, 0.0201, 0.095, 0.4292, -0.217, 0.052, 0.1321, 0.109, -0.1232,
		-0.0721, 0.0239, -0.1266, 0.0265, -0.0618, 0.1775, -0.1482, -0.1143, 0.0272, 0.0911, 0.0705, 0.1961, -0.0203,
		0.0179, -0.23, -0.254, -0.2259, 0.0541, -0.3147, 0.0568, 0.0302, 0.0441, 0.0902, -0.1914, 0.0639, 0.0129,
		-0.1376, 0.0859, -0.0938, 0.0711, 0.0882, -0.0258, 0.0086, 0.009, -0.0632, 0.1581, -0.181, 0.2094, 0.2882,
		-0.0656, -0.2046, -0.074, 0.3265, 0.2342, -0.0057, -0.1522, -0.0593, 0.0412, -0.1832, -0.066, 0.3126, -0.1429,
		0.0969, 0.2075, 0.16, 0.1564, -0.0877, 0.4104, -0.0236, -0.2056, 0.1591, 0.093, -0.0057, 0.0361, -0.1617,
		0.0298, 0.2251, 0.1866, 0.1562, -0.1657, -0.0981, 0.1255, 0.35, 0.1562, 0.0937, 0.1236, 0.111, 0.0395, 0.0097,
		-0.0521, 0.0737, -0.0772, 0.0548, 0.0209, -0.1543, -0.1469, -0.0056, -0.0809, -0.0478, 0.0334, 0.228, -0.154,
		-0.0066, -0.0693, -0.047, 0.1658, -0.0712, -0.395, 0.01, 0.0966, -0.0389, 0.1309, -0.1647, 0.0365, -0.0166,
		0.0421, 0.0355, 0.0575, -0.0564, 0.0712, -0.0256, 0.0026, -0.0037, 0.0116, 0.0092, -0.0013, -0.0047, 0.0099,
		-0.0275, -0.0819, 0.0453, -0.0391, 0.0608, 0.0109, 0.0478, -0.0764, -0.0735, 0.0033, -0.0167, -0.1795, -0.0117,
		-0.1162, 0.0702, -0.0581, -0.0662, -0.0719, -0.0694, 0.0104, 0.0048, 0.0109, 0.0596, 0.0041, 0.0038, 0.1217,
		0.1529, -0.119, 0.0776, 0.0208, -0.033, -0.2488, -0.201, -0.2946, -0.1693, -0.0166, -0.0511, 0.015, -0.0054,
		-0.0261, -0.0033, -0.078, 0.1155, -0.095, 0.0602, -0.1379, 0.1123, -0.0506, 0.2832, -0.0376, -0.2518, -0.1779,
		0.1128, 0.3472, -0.6546, -0.5196, -0.0889, 0.3878, -0.2053, 0.2061, -0.1716, 0.1966, -0.169, 0.2803, -0.1454,
		0.0772, -0.0337, -0.0565, -0.0323, -0.1928, 0.0481, -0.094, -0.1187, 0.0429, -0.1774, 0.0102, -0.0217, -0.0062,
		0.0042, -0.0014
	]
]
