import { FC } from 'react'
import { ColumnView as ColumnViewType } from '~/types'
import { Heading, Paragraph } from '../FontStyles'
import { DefaultImage } from '../Image'
import { ExternalVideo } from '../blocks'

export const ColumnView: FC<ColumnViewType> = (props) => {
	return (
		// applying padding top because of margin collapse
		<div id={props.id} className="mx-2 my-6 flex flex-col items-center pt-6 text-center md:my-12 md:pt-12">
			<Heading type="displayMd" className="!text-7 md:!text-8 mb-4">
				{props.title}
			</Heading>
			<Paragraph type="textXl" className="mb-8 md:mb-[4.5rem]">
				{props.description}
			</Paragraph>
			<div className="mb-8 grid max-w-5xl grid-cols-2 gap-8 md:mb-[4.5rem] md:grid-cols-3 md:gap-12">
				{props.items?.map((item) => (
					<div className="text-left" key={item._key}>
						<div className="md:flex md:gap-6">
							{item.image && (
								<div className="mb-4 !w-8 md:!w-12 md:min-w-[3rem]">
									<DefaultImage image={item.image} alt={item.text || ''} />
								</div>
							)}
							<Paragraph type="textMd" className="md:text-4">
								{item.text}
							</Paragraph>
						</div>
					</div>
				))}
			</div>
			{props.video && (
				<div className="w-full max-w-5xl">
					<ExternalVideo {...props.video} />
				</div>
			)}
		</div>
	)
}
