import { FC } from 'react'
import { Showcase as ShowcaseType } from '~/types'
import { Heading } from '../FontStyles'
import { ResponsiveImage } from '../Image'

export const Showcase: FC<ShowcaseType> = (props) => {
	return (
		// applying padding top because of margin collapse
		<div
			id={props.id}
			className="mx-2 my-6 flex flex-col items-center rounded-xl bg-[#FFFEFD]
p-6 text-center md:my-12 md:ml-[calc((100vw-100%)*-0.5+3rem)] md:w-[calc(100vw-6rem)] md:p-12 md:pt-12"
		>
			<Heading type="displayXs" className="md:!text-7 mb-8 md:mb-[4.5rem]">
				{props.title}
			</Heading>
			<div className="grid max-w-[100rem] grid-cols-2 gap-4 md:mx-12 md:grid-cols-4 md:gap-12">
				{props.images?.map((image) => {
					const ImageComponent = image.image ? (
						<ResponsiveImage image={image.image} key={image._key} layout="fill" className="rounded-lg" />
					) : null

					return image.url ? (
						<a href={image.url} target="_blank" rel="noreferrer">
							{ImageComponent}
						</a>
					) : (
						ImageComponent
					)
				})}
			</div>
		</div>
	)
}
