import { colors, screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { Pause } from '~/components/gfx'
import type { ImageObject, VideoAsset } from '~/types'
import { isResolved, loadHLS } from '~/utils'
import { Breakout } from './Containers'

interface WrapperProps {
	background?: string
	noVideo?: boolean
}

const Wrapper = styled.div<WrapperProps>`
	width: 100vw;
	height: 1051px;
	max-height: calc(100vh + 1rem);
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;

	${(props) =>
		props.noVideo
			? css`
					background: linear-gradient(
							179.97deg,
							rgba(255, 255, 255, 0.09) 36.31%,
							rgba(255, 255, 255, 0) 99.97%
						),
						linear-gradient(
							14.98deg,
							rgba(1, 6, 48, 0.2) 13.83%,
							rgba(98, 71, 128, 0.2) 71.07%,
							rgba(98, 71, 128, 0) 96.46%
						),
						linear-gradient(0deg, rgba(240, 240, 245, 0.1), rgba(240, 240, 245, 0.1)),
						url(${props.background}) no-repeat center center;
			  `
			: css`
					background: url(${props.background}) no-repeat center center;
			  `};

	background-size: cover;
`

const BreakoutWrapper = styled(Breakout)`
	height: 100%;
`

interface CtaWrapperProps {
	noVideo?: boolean
}

const CtaWrapper = styled.div<CtaWrapperProps>`
	position: absolute;
	bottom: 2rem;
	left: 0;
	right: 0;
	padding: 21px;
	color: ${(props) => (props.noVideo ? colors.white : colors.black)};

	div {
		padding: 0 14px 1rem 14px;

		h1 {
			font-size: 48px;
			line-height: 56px;
			font-weight: 400;
			margin: 0 0 1rem 0;
			@media (max-width: 355px) {
				font-size: 35px;
				line-height: 42px;
			}
		}
		p {
			margin: 0;
		}
	}

	@media (min-width: ${screenSizes.small}) {
		height: 100%;
		width: 100%;
		bottom: 0;
		padding-top: 60vh;
		display: flex;
		flex-direction: column;

		div {
			backdrop-filter: none;
			background: none;
			margin: 0 auto;
			max-width: 1088px;
			text-align: center;

			p {
				max-width: 606px;
				margin: 0 auto;
			}
		}
	}
`

const PlayBtn = styled.button`
	padding: 12px 20px;
	border-radius: 0.5rem;
	border: none;
	font-weight: 500;
	width: 100%;
	margin-top: 1rem;
	cursor: pointer;

	@media (min-width: ${screenSizes.small}) {
		width: fit-content;
		margin: 2rem auto 0 auto;
		padding: 16px 28px;
	}
`

interface VideoWrapperProps {
	isPlaying?: boolean
}

const VideoWrapper = styled.div<VideoWrapperProps>`
	width: 100vw;
	height: 100%;
	position: fixed;
	z-index: ${(props) => (props.isPlaying ? '10' : -1)};
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;

	figure {
		margin: 0;
		position: relative;
	}
	.controls {
		position: absolute;
		bottom: 5rem;
		left: 0;
		right: 0;
		margin: auto;
		width: 90%;
		display: flex;
		align-items: center;
		button {
			color: #fff;
			background: none;
			border: none;
			cursor: pointer;
			display: flex;
			align-items: center;
		}
	}
	progress {
		height: 0.5rem;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 0.5rem;
		width: 100%;

		::-webkit-progress-value {
			background-image: linear-gradient(to right, #ff8a00, #e52e71);
			transition: 1s linear;
			border-radius: 0.5rem;
		}

		/* Firefox styles */
		::-moz-progress-bar {
			background: #fff;
			border-radius: 0.5rem;
			transition: 1s linear;
		}
	}
	progress[value] {
		appearance: none; /* Needed for Safari */
		border: none; /* Needed for Firefox */
		color: #fff; /* Fallback to a solid color */
		border-radius: 0.5rem;
	}
	video {
		width: 100%;
		height: 1051px;
		max-height: calc(100vh + 1rem);
		object-fit: cover;
	}
`

interface VideoHeaderComponentProps {
	title?: string
	ingres?: string | JSX.Element
	video?: VideoAsset
	playing?: boolean
	setPlaying?: (play: boolean) => void
	videoOverlay?: ImageObject
	noVideo?: boolean
}

export const VideoHeader: FunctionComponent<VideoHeaderComponentProps> = ({
	title,
	ingres,
	video,
	videoOverlay,
	noVideo
}) => {
	const videoRef = useRef<HTMLVideoElement>(null)
	const contentRef = useRef<HTMLDivElement>(null)
	const progressRef = useRef<HTMLProgressElement>(null)
	const [isPlaying, setPlaying] = useState(false)
	const videoId = video?.asset?.data?.playback_ids?.[0]?.id
	// const playing = useRef(false)
	const src = `https://stream.mux.com/${videoId}.m3u8`

	// const timeUpdate = (htmlVideo: HTMLVideoElement) => {
	// 	if (htmlVideo.currentTime > 4 && !playing.current) {
	// 		htmlVideo.currentTime = 0
	// 	}
	// }

	useEffect(() => {
		if (!videoId) return
		let hls: ReturnType<typeof loadHLS>
		if (videoRef.current) {
			const videoElem = videoRef.current

			if (videoElem.canPlayType('application/vnd.apple.mpegurl')) {
				videoElem.src = src
			} else {
				loadHLS(src, videoElem)
			}
		}

		return () => {
			hls?.then((v) => v?.destroy())
		}
	}, [videoRef, src, videoId])

	const playVideo = () => {
		setPlaying(true)
		if (videoRef?.current && progressRef?.current) {
			videoRef.current.play()
			setInterval(() => {
				if (progressRef.current && videoRef.current) {
					progressRef.current.value = Math.round(
						(videoRef.current.currentTime / videoRef.current.duration) * 100
					)
				}
			})
			videoRef?.current.addEventListener('ended', () => {
				setPlaying(false)
			})
		}
	}

	const pauseVideo = () => {
		if (videoRef?.current && progressRef?.current) {
			setPlaying(false)
			videoRef.current.pause()
			videoRef?.current.removeEventListener('ended', () => {
				setPlaying(false)
			})
		}
	}

	return (
		<>
			<VideoWrapper isPlaying={isPlaying} aria-hidden={!isPlaying} aria-expanded={isPlaying}>
				<figure>
					<video playsInline height="100%" ref={videoRef} />
					<div className="controls">
						<button onClick={() => pauseVideo()} aria-label="Pause and hide video">
							<Pause />
						</button>
						<progress ref={progressRef} role="progressbar" id="progress" max="100" value="0">
							Progress
						</progress>
					</div>
				</figure>
			</VideoWrapper>
			<Wrapper
				background={isResolved(videoOverlay?.asset) ? videoOverlay?.asset.url : undefined}
				noVideo={noVideo}
			>
				<BreakoutWrapper>
					<CtaWrapper ref={contentRef} noVideo={noVideo}>
						<div>
							<h1>{title}</h1>
							<p>{ingres}</p>
						</div>
						{!noVideo && (
							<PlayBtn onClick={() => playVideo()} aria-label="Play video">
								See how
							</PlayBtn>
						)}
					</CtaWrapper>
				</BreakoutWrapper>
			</Wrapper>
		</>
	)
}

export default VideoHeader
