import { screenSizes } from '@nomonosound/gravity'
import type { FunctionComponent } from 'react'
import styled from 'styled-components'
import type { ImageObject, VideoAsset } from '~/types'
import { DefaultIngres, PageHeading } from '../GeneralStyles'
import { VideoHeader } from './VideoHeader'

const PageHeader = styled.div`
	margin: 5rem auto;

	@media (max-width: ${screenSizes.small}) {
		margin-top: 2.5rem;
		margin-bottom: 4rem;
	}

	&[data-layout='center'] {
		text-align: center;
	}
`

interface PageHeaderComponentProps {
	title?: string
	ingres?: string | JSX.Element
	headerLayout?: 'left' | 'center'
	noVideo?: boolean
	video?: VideoAsset
	videoOverlay?: ImageObject
}

export const PageHeaderComponent: FunctionComponent<PageHeaderComponentProps> = ({
	title,
	ingres,
	headerLayout,
	noVideo,
	video,
	videoOverlay
}) => {
	return (
		<>
			{videoOverlay || video ? (
				<VideoHeader
					title={title ?? ''}
					ingres={ingres}
					video={video}
					videoOverlay={videoOverlay}
					noVideo={noVideo}
				/>
			) : (
				<PageHeader data-layout={headerLayout ?? 'center'}>
					<PageHeading>{title}</PageHeading>
					<DefaultIngres data-layout={headerLayout}>{ingres}</DefaultIngres>
				</PageHeader>
			)}
		</>
	)
}
