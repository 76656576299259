import { DefaultLayout } from './layout/DefaultLayout'
import { PageHeaderComponent } from './layout/PageHeader'

const Custom404 = () => {
	return (
		<DefaultLayout>
			<PageHeaderComponent
				title="404"
				ingres="The page you are looking for can’t be found"
				headerLayout="center"
			/>
		</DefaultLayout>
	)
}

export default Custom404
