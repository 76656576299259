import { captureMessage } from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import type { FunctionComponent } from 'react'
import { isPresent } from 'ts-extras'
import type { IterableElement } from 'type-fest'
import Faq from '~/components/Faq'
import {
	Carousel,
	Centerfold,
	DefaultSegment,
	EmbeddedVideoSection,
	FeatureSection,
	LogoSection,
	NewsletterSignup,
	PortableText,
	ProductContent,
	Salepoints,
	SimpleSection,
	Spacer,
	TextAndImage
} from '~/components/blocks'
import type { ContentBlocks as ContentBlocksType } from '~/types'
import { resolve, useSettings } from '~/utils'
import AmazonButtons from '../AmazonButtons'
import { VideoGallery } from '../VideoGallery'
import { ColumnView } from '../_new/ColumnView'
import { Companies } from '../_new/Companies'
import { HeroSection as HeroSectionV2 } from '../_new/HeroSection'
import { Showcase } from '../_new/Showcase'
import { AudioPlayer } from './AudioPlayer/AudioPlayer'
import { AudioPlayerV2 } from './AudioPlayer/AudioPlayerV2'
import { AudioPlayerV3 } from './AudioPlayer/AudioPlayerV3'
import { IdContext } from './IdContext'
import { ScrollyTell } from './ScrollyTell'

const OrderConfirmation = dynamic(
	async () => (await import('~/components/blocks/OrderConfirmation/OrderConfirmation')).OrderConfirmation,
	{ ssr: false }
)
const ProductBlock = dynamic(async () => (await import('~/components/blocks/ProductBlock/ProductBlock')).ProductBlock, {
	ssr: true
})

type Block = IterableElement<ContentBlocksType>

interface BlockProps {
	contentBlocks: ContentBlocksType
}

export const ContentBlocks: FunctionComponent<BlockProps> = ({ contentBlocks }) => (
	<>
		{contentBlocks?.map((block, i) => {
			const id = (block as { _id?: string })._id ?? block._key ?? i.toString()
			return (
				<IdContext.Provider key={i} value={id}>
					<ContentBlock {...block} isFirstBlock={i === 0} />
				</IdContext.Provider>
			)
		})}
	</>
)

export const ContentBlock: FunctionComponent<(Block & { isFirstBlock: boolean }) | { _type: 'unknown' }> = (block) => {
	const { siteSettings } = useSettings()
	switch (block?._type) {
		case 'audioPlayerV2':
			return <AudioPlayerV2 {...block} />

		case 'audioPlayerV3':
			return <AudioPlayerV3 {...block} />

		case 'audioSection':
			return <AudioPlayer {...block} />

		case 'centerfold':
			return <Centerfold {...block} />

		case 'customSection':
			switch (block.component) {
				case 'newsletterSignup':
					return <NewsletterSignup form={siteSettings?.hubspotNewsletterForm} {...block} />
				case 'allPlans':
				// throw new Error('allPlans not implemented yet')
				case 'faq': {
					const questions = resolve(block.faq)?.questions?.map(resolve).filter(isPresent)
					return questions ? <Faq faq={questions} /> : null
				}
			}

		case 'featureSection':
			return <FeatureSection {...block} />

		case 'logoSection':
			return <LogoSection {...block} />

		case 'orderConfirmationSection':
			return <OrderConfirmation {...block} />

		case 'salepoints':
			return <Salepoints {...block} />

		case 'scrollyTell':
			return block.settings?.enabled ? <ScrollyTell {...block} /> : null

		case 'segment':
			return (
				<DefaultSegment {...block}>
					<PortableText blocks={block.description} />
				</DefaultSegment>
			)

		case 'simpleSection':
			return <SimpleSection {...block} />

		case 'spacer':
			return <Spacer {...block} />

		case 'textAndImage':
			return <TextAndImage {...block} />

		case 'testamonialCarousel':
			return <Carousel {...block} />

		case 'embeddedVideo':
			return <EmbeddedVideoSection {...block} />

		case 'videoGallery':
			return <VideoGallery {...block} />

		case 'productBlock':
			return <ProductBlock {...block} />

		case 'productContent':
			return <ProductContent {...block} />
		case 'amazonButtons':
			return <AmazonButtons {...block} />

		case 'heroSectionV2':
			return block.enabled ? <HeroSectionV2 {...block} /> : null

		case 'columnView':
			return block.enabled ? <ColumnView {...block} /> : null

		case 'showcase':
			return block.enabled ? <Showcase {...block} /> : null

		case 'companies':
			return block.enabled ? <Companies {...block} /> : null
	}
	console.warn(`Unknown block type: ${block?._type}`, 'warning')
	captureMessage(`Unknown block type: ${block?._type}`, 'warning')
	return null
}
