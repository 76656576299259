import { chunk } from 'lodash'
import { FC } from 'react'
import styled from 'styled-components'
import { Companies as CompaniesType } from '~/types'
import { useWindowSize } from '~/utils'
import { Heading } from '../FontStyles'
import { ResponsiveImage } from '../Image'

const LinkStyled = styled.a`
	span {
		display: block !important;
	}
`

export const Companies: FC<CompaniesType> = (props) => {
	const { isMobile } = useWindowSize()

	return (
		// applying padding top because of margin collapse
		<div
			id={props.id}
			className="mx-2 my-6 flex flex-col items-center rounded-xl bg-[#FFFEFD]
p-6 text-center md:my-12 md:ml-[calc((100vw-100%)*-0.5+3rem)] md:w-[calc(100vw-6rem)] md:p-12 md:pt-12 md:pb-0"
		>
			{props.title && (
				<Heading type="displaySm" className="md:!text-7 mb-8 md:mb-[4.5rem]">
					{props.title}
				</Heading>
			)}
			{isMobile ? (
				<div className="grid max-w-[100rem] grid-cols-2 gap-8   ">
					{props.images?.map((image) => {
						const ImageComponent = image.image ? (
							<ResponsiveImage image={image.image} key={image._key} layout="auto" alt="" />
						) : null

						return (
							<div key={image._key} className="flex h-12 w-full items-center justify-center">
								{image.url ? (
									<LinkStyled href={image.url} target="_blank" rel="noreferrer">
										{ImageComponent}
									</LinkStyled>
								) : (
									ImageComponent
								)}
							</div>
						)
					})}
				</div>
			) : (
				chunk(props.images, 5).map((images, index) => {
					return (
						<div className="mb-12 flex justify-center gap-12" key={index}>
							{images.map((image) => {
								const ImageComponent = image.image ? (
									<ResponsiveImage image={image.image} key={image._key} layout="auto" alt="" />
								) : null

								return (
									<div key={image._key} className="flex h-12 items-center justify-center">
										{image.url ? (
											<LinkStyled href={image.url} target="_blank" rel="noreferrer">
												{ImageComponent}
											</LinkStyled>
										) : (
											ImageComponent
										)}
									</div>
								)
							})}
						</div>
					)
				})
			)}
		</div>
	)
}
