import { V2 } from '@nomonosound/gravity'
import { startCase } from 'lodash'
import type { FunctionComponent } from 'react'
import type { Except } from 'type-fest'
import type { InputOption, InputProps, RadioInputProps } from '~/types'
import { cn } from '~/utils'

export interface RadioProps extends Except<InputProps, 'label'> {
	option: InputOption
}

const RadioButton: FunctionComponent<RadioProps> = ({
	option,
	children,
	radioClassName,
	radioSelectedClassName,
	...props
}) => {
	const { value, label, icon } =
		typeof option === 'string'
			? { value: option, label: startCase(option), icon: undefined }
			: { ...option, label: option.label ?? startCase(option.value) }
	return (
		<label
			className={cn(
				'z-10 !mb-0 block flex-1 rounded-lg p-2 text-center text-black hover:z-0',
				props.value == value
					? radioSelectedClassName ?? 'bg-brand-200 hover:opacity-80'
					: radioClassName ?? 'bg-transparenthover:bg-blue-100'
			)}
		>
			<input className="hidden" {...props} type="radio" value={value} />
			<div className="xs:flex-col xs:space-y-2 flex items-center justify-center md:flex-row md:space-y-0">
				{icon && <V2.Icon size="sm" style={{ height: '1.25em' }} icon={icon} className="pr-1.5" />}
				<span>{label || value || props.value || children}</span>
			</div>
		</label>
	)
}

export const RadioButtonGroup: FunctionComponent<RadioInputProps> = ({ className, name, options, label, ...props }) => (
	<fieldset className={cn(className)}>
		<legend>{label}</legend>
		<div className="border-brand-200 flex justify-center -space-x-1 overflow-hidden rounded-xl border-2">
			{options.map((option, i) => (
				<RadioButton key={i} name={name} option={option} {...props} />
			))}
		</div>
	</fieldset>
)
